<template>
   <div>
      <v-row>
         <!-- List headers -->
         <v-col cols="6">
            <v-card>
               <v-card-title>
                  {{ translateKey("listItemsTab.listHeaders", translations) }}
                  <v-spacer></v-spacer>
                  <v-btn elevation="2" @click="onAddNewListHeaderClick">
                     <v-icon color="success">mdi-plus</v-icon>
                     <span>Add</span>
                  </v-btn>
               </v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col xs="12" md="5" class="pt-0 pb-0" align-self="center">
                        <v-text-field
                           ref="nameField"
                           v-model="newListHeader.name"
                           label="Header"
                           @blur="onNameBlur"
                           @keyup.enter="onNameConfirmed"
                        />
                     </v-col>
                     <v-col xs="12" md="5" class="pt-0 pb-0" align-self="center">
                        <v-text-field
                           ref="identifierField"
                           v-model="newListHeader.identifier"
                           label="Identifier"
                           append-icon="mdi-refresh"
                           @click:append="regenerateIdentifier"
                           @keyup.enter="onAddNewListHeaderClick"
                        />
                     </v-col>
                  </v-row>
                  <v-row v-for="(listHeader, index) in listModel.headers" :key="index">
                     <v-col xs="12" md="5" class="pt-0 pb-0" align-self="center">
                        <v-text-field v-model="listHeader.name" label="Header" readonly />
                     </v-col>
                     <v-col xs="12" md="5" class="pt-0 pb-0" align-self="center">
                        <v-text-field v-model="listHeader.identifier" label="Identifier" readonly />
                     </v-col>
                     <v-col xs="12" md="2" class="pt-0 pb-0" align-self="center">
                        <v-btn color="error" icon @click="onRemoveListHeaderClick(listHeader)">
                           <v-icon>mdi-delete</v-icon>
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>

         <!-- List items -->
         <v-col cols="6">
            <v-card>
               <v-card-title>
                  {{ translateKey("listItemsTab.listItemsLabel", translations) }}
                  <v-spacer></v-spacer>
                  <v-btn :disabled="tableHeaders.length == 1" @click="onAddNewListItemClick">
                     <v-icon color="success">mdi-plus</v-icon>
                     {{ translateKey("button.add", translations) }}
                  </v-btn>
               </v-card-title>
               <v-card-text>
                  <v-row>
                     <v-col>
                        <v-data-table :headers="tableHeaders" :items="listItems">
                           <template v-for="tableHeader in tableHeaders" #[`item.${tableHeader.value}`]="{ item }">
                              <v-text-field
                                 :key="tableHeader.value"
                                 :value="item.columns?.get(tableHeader.value)"
                                 @input="updateKeyValue(item, tableHeader.value, $event)"
                              ></v-text-field>
                           </template>
                           <template #[`item.actions`]="{ item }">
                              <v-btn icon small color="error" @click="onRemoveListItemClick(item)">
                                 <v-icon>mdi-delete</v-icon>
                              </v-btn>
                           </template>
                        </v-data-table>
                     </v-col>
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { List, ListHeader, ListItem, TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import Sortable from "sortablejs";
import DataModelUtils from "@utils/DataModelUtils";

@Component({
   name: "ListDetailTabMulticolumnItems",
   directives: {
      sortableDataTable: {
         bind(el, binding, vnode) {
            const options = {
               animation: 150,
               onUpdate: function (event) {
                  vnode.child.$emit("sorted", event);
               },
               filter: ".drag-filter",
            };
            Sortable.create(el.getElementsByTagName("tbody")[0], options);
         },
      },
   },
})
export default class ListDetailTabMulticolumnItems extends ComponentBase {
   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ required: true })
   listModel: List;

   @Prop({ default: true })
   readonly: boolean;

   newListHeader: ListHeader = new ListHeader();

   tableHeaders: any[] = [{ text: "Actions", value: "actions" }];

   listItems: ListItem[] = [];

   mounted() {
      if (!this.listModel?.headers) {
         this.listModel.headers = [];
      }

      this.updateTableHeaders();

      if (this.listModel.items) {
         this.listModel.items.forEach((item) => {
            let newListItem = new ListItem();
            newListItem.columns = new Map<string, string>();
            this.listModel.headers.forEach((header) => {
               newListItem.columns.set(header.identifier, item.columns[header.identifier]);
            });
            this.listItems.push(newListItem);
         });
      }
   }

   onNameBlur() {
      if (!this.newListHeader.name) return;

      if (!this.newListHeader.identifier) {
         this.regenerateIdentifier();
      }
   }

   onNameConfirmed() {
      this.$refs.identifierField?.focus();
   }

   onAddNewListHeaderClick() {
      if (this.listModel.headers?.filter((x) => x.identifier == this.newListHeader.identifier).length > 0) {
         this.notifyError(this.translateKey("listItemsTab.identifierAlreadyUsed", this.translations));
         return;
      }
      this.addNewColumnHeader(this.newListHeader);
      this.newListHeader = new ListHeader();
      this.updateTableHeaders();
      this.$refs.nameField?.focus();
   }

   regenerateIdentifier() {
      this.newListHeader.identifier = DataModelUtils.generateNodeIdentifier(this.newListHeader.name) ?? "";
      this.$forceUpdate();
   }

   onRemoveListHeaderClick(header: any) {
      this.listModel.headers!.splice(this.listModel.headers!.indexOf(header), 1);
      this.updateTableHeaders();
   }

   addNewColumnHeader(header: any) {
      this.listModel.headers!.push(header);
   }

   updateTableHeaders() {
      this.tableHeaders = [];
      this.listModel.headers!.forEach((header) => {
         this.tableHeaders.push({ text: header.name, value: header.identifier });
      });
      this.tableHeaders.push({ text: "Actions", value: "actions" });
   }

   onAddNewListItemClick() {
      let newListItem = new ListItem();
      newListItem.columns = new Map<string, string>();

      this.listModel?.headers?.forEach((header) => {
         newListItem.columns.set(header.identifier, "");
      });

      this.listItems.push(newListItem);
      this.emitListItems();
   }

   onRemoveListItemClick(item: ListItem) {
      this.listItems.splice(this.listItems.indexOf(item), 1);
      this.emitListItems();
   }

   updateKeyValue(item: ListItem, key: string, value: string) {
      item.columns.set(key, value);
      this.emitListItems();
   }

   emitListItems() {
      this.$emit("list-items-updated", this.listItems);
   }

   clearItems() {
      this.listItems = [];
      this.emitListItems();
      console.warn("clearItems", this.listItems);
   }
}
</script>

<style scoped></style>
