<template>
   <v-dialog :value="showDialog" width="75%" persistent scrollable>
      <v-card>
         <v-card-title>
            <span>{{ fieldName }} - {{ translateKey("taskDetail.fieldManualResult", translations) }}</span>
            <v-spacer />
            <v-btn
               v-shortkey.once="['esc']"
               class="red--text"
               icon
               @shortkey="hideEditorDialog"
               @click="hideEditorDialog"
            >
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-text-field
               v-model="resultNoteInternal"
               :readonly="readonly"
               :clearable="!readonly"
               autofocus
               :label="translateKey('taskDetail.fieldManualResultNote', translations)"
            ></v-text-field>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :class="closeBtnClass" :outlined="!readonly" @click="hideEditorDialog">
               {{ translateKey(readonly ? "button.close" : "button.cancel", translations) }}
            </v-btn>
            <v-btn
               v-if="!readonly"
               v-shortkey.once="['ctrl', 's']"
               class="error mx-4"
               @shortkey="acceptChanges"
               @click="acceptChanges"
            >
               {{ translateKey("button.ok", translations) }}
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { TranslationPublicModel } from "@backend/api/pmToolApi";

@Component({
   components: {},
})
export default class FieldManualResultNoteDialog extends ComponentBase {
   @Prop({ required: true })
   value: string | null;

   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   fieldName: string | null;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   resultNoteInternal: string | null = null;

   @Watch("showDialog")
   onShowChanged() {
      if (this.showDialog) {
         this.resultNoteInternal = this.value;
      } else {
         this.resultNoteInternal = null;
      }
   }

   get closeBtnClass() {
      return this.readonly ? "error" : "red--text";
   }

   mounted() {
      this.resultNoteInternal = this.value;
   }

   hideEditorDialog() {
      this.$emit("update:showDialog", false);
   }

   acceptChanges() {
      if (this.readonly) return;
      this.$emit("input", this.resultNoteInternal);
      this.hideEditorDialog();
   }
}
</script>
