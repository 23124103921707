import moment from "moment";
import { TagTypeEnum } from "../../models/filter/TagTypeEnum";
import { EntityStatus, EntityType, IItemReference, ItemReference } from "../api/pmToolApi";

const FilterStoreKeys = {
   FILTERCONFIGURATION: "filterConfiguration",
};

export enum FilterEntityType {
   DataModelNodeSelection,
}

export interface VisitedItem {
   id: string;
   createdDate: Date;
}

export interface CustomFilter {
   value: any;
   type: CustomFilterType;
}

export enum CustomFilterType {
   ShowTasks = 1,
   Group = 2,
   DomainDdm = 3,
   DomainGdm = 4,
   ShowSubstitute = 5,
   DomainDdmInstance = 6,
   OnlyOpenProjects = 7,
   IncludeTests = 8, // formerly `IncludeTestProjects`, now used for any test document
   PbbType = 9,
   ProjectStatuses = 10,
   ProjectPriorityFilter = 11,
}

export interface FilterConfiguration {
   entityType: EntityType | FilterEntityType;
   lastVisitedItems: VisitedItem[];
   isLastVisitedActive: boolean;
   tagClouds: TagCloudSelected[];
   onlyActive: boolean;
   onlyUserCreated: boolean;
   showFilter: boolean;
   customFilter: CustomFilter[];
   includeFinishedTasks: boolean;
   showUnread: boolean;
   showPostpone: boolean;
   showRepair: boolean;
   showOnlySystemRoles: boolean;
   showOnlyDomainRoles: boolean;
   showClosedTasks: boolean;
   entityStatuses: EntityStatus[];
   groupBy: string;
}

export interface TagCloudSelected {
   selected: IItemReference[];
   type: TagTypeEnum;
}

export class FilterStore {
   public filterConfigurations: FilterConfiguration[] = [];

   constructor() {
      this.loadDataFromLocalStorage();
   }

   private loadDataFromLocalStorage() {
      const localstorageItem = localStorage.getItem(FilterStoreKeys.FILTERCONFIGURATION);
      if (localstorageItem) {
         this.filterConfigurations = JSON.parse(localstorageItem);
      }
   }

   private initFilterConfiguration(entityType: EntityType | FilterEntityType) {
      this.loadDataFromLocalStorage();
      let filterConfiguration = this.getFilterConfigurationByEntityType(entityType);
      if (!filterConfiguration) {
         filterConfiguration = {
            entityType: entityType,
            isLastVisitedActive: false,
            lastVisitedItems: [],
            tagClouds: [],
            onlyActive: false,
            showFilter: false,
            onlyUserCreated: false,
            customFilter: [],
            includeFinishedTasks: false,
            showUnread: false,
            showPostpone: false,
            showRepair: false,
            showOnlySystemRoles: false,
            showOnlyDomainRoles: false,
            showClosedTasks: false,
            entityStatuses: [EntityStatus.Draft, EntityStatus.Active],
            groupBy: "",
         };
         this.setFilterConfiguration(filterConfiguration);
      }
      this.removeExpiredLastVisited();

      return filterConfiguration;
   }
   private getFilterConfigurationByEntityType(entityType: EntityType | FilterEntityType) {
      return this.filterConfigurations.find((x) => x.entityType == entityType);
   }

   private setFilterConfiguration(filterConfiguration: FilterConfiguration) {
      const confIndex = this.filterConfigurations.findIndex((x) => x.entityType == filterConfiguration.entityType);
      if (confIndex != -1) {
         this.filterConfigurations.splice(confIndex, 1);
      }
      this.filterConfigurations.push(filterConfiguration);
      localStorage.setItem(FilterStoreKeys.FILTERCONFIGURATION, JSON.stringify(this.filterConfigurations));
   }

   private removeExpiredLastVisited() {
      this.filterConfigurations.forEach((conf) => {
         conf.lastVisitedItems = conf.lastVisitedItems.filter((x) =>
            moment(x.createdDate).isAfter(moment().add(-30, "days"))
         );

         this.setFilterConfiguration(conf);
      });
   }

   public addVisitedItem(id: string, entityType: EntityType | FilterEntityType) {
      const regexExp = new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$", "i");
      if (entityType && id && id.match(regexExp) !== null) {
         const configuration = this.initFilterConfiguration(entityType);

         const matchIndex = configuration.lastVisitedItems.findIndex((item) => item.id == id);
         if (matchIndex != -1) {
            configuration.lastVisitedItems.splice(matchIndex, 1);
         }
         configuration.lastVisitedItems.push({
            id: id,
            createdDate: new Date(),
         });
         this.setFilterConfiguration(configuration);
      }
   }

   public getLastVisited(entityType: EntityType | FilterEntityType): VisitedItem[] {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         return conf.lastVisitedItems;
      }
      return [];
   }

   public setIsLastVisitedActive(value: boolean, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.isLastVisitedActive = value;
      this.setFilterConfiguration(conf);
   }

   public getIsLastVisitedActive(entityType: EntityType | FilterEntityType): boolean {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.isLastVisitedActive;
         }
      }
      return false;
   }

   public setSelectedTagsAndSearchIdentifiersValues(
      entityType: EntityType | FilterEntityType,
      values: ItemReference[] | undefined,
      tagType: TagTypeEnum
   ) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         const matchIndex = configuration.tagClouds.findIndex((x) => x.type == tagType);
         if (matchIndex != -1) {
            configuration.tagClouds.splice(matchIndex, 1);
         }
         if (values) {
            configuration.tagClouds.push({
               selected: values,
               type: tagType,
            });
         }
         this.setFilterConfiguration(configuration);
      }
   }

   public getSelectedTagCloud(tagType: TagTypeEnum, entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         const cloud = configuration.tagClouds.find((x) => x.type == tagType);
         if (cloud) {
            return cloud.selected.map((x) => new ItemReference(x));
         }
      }
      return [];
   }

   public getOnlyActive(entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         return configuration.onlyActive;
      }
      return false;
   }

   public setOnlyActive(value: boolean, entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         configuration.onlyActive = value;
         this.setFilterConfiguration(configuration);
      }
   }

   public getEntityStatuses(entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         return configuration.entityStatuses ?? [EntityStatus.Draft, EntityStatus.Active];
      }
      return [];
   }

   public setEntityStatuses(value: EntityStatus[], entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         configuration.entityStatuses = value;
         this.setFilterConfiguration(configuration);
      }
   }

   public getOnlyUserCreated(entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         return configuration.onlyUserCreated;
      }
      return false;
   }

   public setOnlyUserCreated(value: boolean, entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         configuration.onlyUserCreated = value;
         this.setFilterConfiguration(configuration);
      }
   }

   public getShowFilter(entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         return configuration.showFilter;
      }
   }

   public setShowFilter(value: boolean, entityType: EntityType | FilterEntityType) {
      if (entityType) {
         const configuration = this.initFilterConfiguration(entityType);
         configuration.showFilter = value;
         this.setFilterConfiguration(configuration);
      }
   }

   public getCustomFilter(entityType: EntityType | FilterEntityType, customFilterType: CustomFilterType) {
      if (entityType && customFilterType) {
         const configuration = this.initFilterConfiguration(entityType);
         if (configuration.customFilter) {
            return configuration.customFilter.find((x) => x.type == customFilterType)?.value;
         } else {
            return null;
         }
      }
   }

   public setCustomFilter(value: any, entityType: EntityType | FilterEntityType, customFilterType: CustomFilterType) {
      if (entityType && customFilterType) {
         const configuration = this.initFilterConfiguration(entityType);
         if (!configuration.customFilter) {
            configuration.customFilter = [];
         }
         const matchedCustomFilter = configuration.customFilter.find((x) => x.type == customFilterType);
         if (matchedCustomFilter) {
            matchedCustomFilter.value = value;
         } else {
            configuration.customFilter.push({
               type: customFilterType,
               value: value,
            } as CustomFilter);
         }
         this.setFilterConfiguration(configuration);
      }
   }

   public setIsIncludeFinishedTasksActive(value: boolean, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.includeFinishedTasks = value;
      this.setFilterConfiguration(conf);
   }

   public getIsIncludeFinishedTasksActive(entityType: EntityType | FilterEntityType): boolean {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.includeFinishedTasks;
         }
      }
      return false;
   }

   public setIsUnreadActive(value: boolean, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.showUnread = value;
      this.setFilterConfiguration(conf);
   }

   public getIsUnreadActive(entityType: EntityType | FilterEntityType): boolean {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.showUnread;
         }
      }
      return false;
   }

   public setIsPostponeActive(value: boolean, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.showPostpone = value;
      this.setFilterConfiguration(conf);
   }

   public getIsPostponeActive(entityType: EntityType | FilterEntityType): boolean {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.showPostpone;
         }
      }
      return false;
   }

   public setIsRepairActive(value: boolean, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.showRepair = value;
      this.setFilterConfiguration(conf);
   }

   public getIsRepairActive(entityType: EntityType | FilterEntityType): boolean {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.showRepair;
         }
      }
      return false;
   }

   public setIsShowOnlySystemRolesActive(value: boolean, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.showOnlySystemRoles = value;
      this.setFilterConfiguration(conf);
   }

   public getIsShowOnlySystemRolesActive(entityType: EntityType | FilterEntityType): boolean {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.showOnlySystemRoles;
         }
      }
      return false;
   }

   public setIsShowOnlyDomainRolesActive(value: boolean, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.showOnlyDomainRoles = value;
      this.setFilterConfiguration(conf);
   }

   public getIsShowOnlyDomainRolesActive(entityType: EntityType | FilterEntityType): boolean {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.showOnlyDomainRoles;
         }
      }
      return false;
   }

   public setIsShowClosedTasksActive(value: boolean, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.showClosedTasks = value;
      this.setFilterConfiguration(conf);
   }

   public getIsShowClosedTasksActive(entityType: EntityType | FilterEntityType): boolean {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.showClosedTasks;
         }
      }
      return false;
   }

   public setGroupBy(value: string, entityType: EntityType | FilterEntityType) {
      const conf = this.initFilterConfiguration(entityType);
      conf.groupBy = value;
      this.setFilterConfiguration(conf);
   }

   public getGroupBy(entityType: EntityType | FilterEntityType): string {
      if (entityType) {
         const conf = this.initFilterConfiguration(entityType);
         if (conf) {
            return conf.groupBy;
         }
      }
      return "";
   }
}

const store = new FilterStore();

export default store;
