<template>
   <div>
      <h5>{{ translateKey("dglExportPdf.tagsLabel", translations) }}</h5>
      <div>
         <span v-for="tag in contentBrick.tags" :key="tag.id" class="tag">
            {{ tag.displayText }}
         </span>
      </div>
   </div>
</template>

<script lang="ts">
import { IContentBrickDefinition, TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
   name: "ExportPdfDesignGuidelineTags",
})
export default class ExportPdfDesignGuidelineTags extends ComponentBase {
   @Prop({ required: true })
   contentBrick: IContentBrickDefinition;

   @Prop({ required: true })
   translations: TranslationPublicModel[];
}
</script>

<style scoped></style>
