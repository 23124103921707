<template>
   <div>
      <v-row justify="space-between" align="end">
         <v-col v-if="label" cols="auto" class="pt-0 pb-1 code-editor-title">
            <h6>
               {{ label }}
               <slot name="append-title"></slot>
            </h6>
         </v-col>
         <v-col cols="auto" class="pt-0 pb-1">
            <v-tooltip top>
               <template #activator="{ on, attrs }">
                  <v-btn
                     v-if="showExpandButton"
                     v-bind="attrs"
                     icon
                     elevation="2"
                     @click="onExpandToggleButtonClick"
                     v-on="on"
                  >
                     <v-icon v-if="isEditorExpanded" color="error">mdi-chevron-up</v-icon>
                     <v-icon v-else color="error">mdi-chevron-down</v-icon>
                  </v-btn>
               </template>
               <span>{{ expandToggleButtonText }}</span>
            </v-tooltip>
         </v-col>
      </v-row>
      <v-row>
         <v-col class="pt-0 pb-0">
            <div class="d-flex flex-row">
               <prism-editor
                  :id="id"
                  v-model="internalValue"
                  class="code-editor grey lighten-5"
                  :class="dynamicClasses"
                  :highlight="highlighter"
                  :line-numbers="!hideLineNumbers"
                  :readonly="readonly"
                  @input="updateValue"
               ></prism-editor>
               <slot name="icon"></slot>
            </div>
         </v-col>
      </v-row>
   </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-csharp";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-json";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-regex";
import "prismjs/themes/prism.css"; // import syntax highlighting styles
import { Guid } from "guid-typescript";

@Component({
   name: "CodeEditor",
   components: {
      PrismEditor,
   },
})
export default class CodeEditor extends Vue {
   @Prop({ required: true })
   value: string;

   @Prop({ default: "Script" })
   label: string;

   @Prop({ default: "cs" })
   language: string;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: false })
   scrollable: boolean;

   @Prop({ default: false })
   disableHighlight: boolean;

   @Prop({ default: false })
   showExpandButton: boolean;

   @Prop({ default: false })
   hideLineNumbers: boolean;

   @Prop({ default: false })
   autofocus: boolean;

   // ID is needed to select correct prism-editor
   id: string = this.generateId();

   //---------------- value handling ------------------
   get internalValue(): string {
      return this.value;
   }

   set internalValue(value: string) {
      this.updateValue(value);
   }

   updateValue(value: string) {
      this.$emit("input", value);
   }

   //--------------- code highlighting ----------------
   highlighter(code: string): string {
      if (this.disableHighlight) return code;

      var lang;
      switch (this.language.toLowerCase()) {
         case "cs":
            lang = languages.cs;
            break;
         case "sql":
            lang = languages.sql;
            break;
         case "json":
            lang = languages.json;
            break;
         case "javascript":
            lang = languages.javascript;
            break;
         case "regex":
            lang = languages.regex;
            break;
         default:
            console.error(`Unsupported code highlighting language '${this.language}', using 'cs' as default`);
            lang = languages.cs;
      }
      return highlight(code, lang); // languages.<insert language> to return html with markup
   }

   //--------------- Scrollable handling ---------------
   isScrollable: boolean = false;

   onExpandToggleButtonClick() {
      this.isScrollable = !this.isScrollable;
   }

   get dynamicClasses(): string {
      return this.isScrollable ? "code-editor-scrollable" : "";
   }

   get isEditorExpanded(): boolean {
      return !this.isScrollable;
   }

   get expandToggleButtonText(): string {
      var prefix = this.isEditorExpanded ? "Collapse" : "Expand";
      return `${prefix} editor`;
   }

   generateId() {
      // Id should start with letter
      return "query-" + Guid.create().toString().substring(0, 5);
   }

   focusEditor() {
      const e = document.querySelector("#" + this.id + " .prism-editor__textarea");
      e.focus();
   }

   mounted() {
      this.isScrollable = this.scrollable;

      if (this.autofocus) {
         // Nexttick does not work
         setTimeout(() => {
            this.focusEditor();
         }, 0);
      }
   }
}
</script>
<style lang="scss">
/* required class */
.code-editor {
   /* you must provide font-family font-size line-height. Example: */
   font-family:
      Fira code,
      Fira Mono,
      Consolas,
      Menlo,
      Courier,
      monospace;
   line-height: 1.5rem;
   min-height: 150px;
   padding: 5px;
   height: auto;

   /* optional class for removing the outline */
   & .prism-editor__textarea:focus {
      outline: none;
   }

   &-scrollable {
      max-height: 200px;
   }
}
</style>
