import {
   ItemReference,
   EntityStatus,
   TagApi,
   DomainApi,
   QueryOptionsOfDomainFilterOptions,
   Tag,
} from "@backend/api/pmToolApi";

class TagUtils {
   // Gets ApiCall that creates new Tag definition with active status by 'name' and 'type'.
   // All domains are automatically assigned to the Tag definition by default.
   public async getCreateTagApiCall(text: string, type: string): Promise<ItemReference> {
      const result = await TagApi.createTag(
         new Tag({
            text: text,
            entityStatus: EntityStatus.Active,
            tagType: type,
            domains: (await DomainApi.getDomainsReferences(new QueryOptionsOfDomainFilterOptions())).documents,
         })
      );
      return new ItemReference({
         id: result.id,
         entityStatus: result.entityStatus,
         displayText: result.text,
         code: result.code,
         lastModified: result.lastModified,
         created: result.created,
      });
   }
}
export default new TagUtils();
