<template>
   <div id="tree">
      <v-app id="inspire">
         <tree-data-table :headers="headers" :items="items"></tree-data-table>
      </v-app>
   </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import TreeDataTable from "../TreeView/tree-data-table.vue";

@Component({
   name: "treeView",
   components: {
      TreeDataTable,
   },
})
export default class TreeView extends Vue {
   pagination: object = {
      rowsPerPage: 10,
      totalItems: 100,
   };

   loading: boolean = false;
   headers = [
      {
         text: "Line No.",
         value: "lineNumber",
         class: "card-header-text",
         width: "10%",
      },
      {
         text: "PBB Code",
         value: "pbbCode",
         class: "card-header-text",
         width: "100%",
      },
      {
         text: "Layer",
         value: "depth",
         class: "card-header-text",
         width: "10%",
      },
      {
         text: "Processing",
         value: "processing",
         class: "card-header-text",
         width: "10%",
      },
      {
         text: "Title",
         value: "name",
         class: "card-header-text",
         width: "10%",
      },
      {
         text: "Responsible",
         value: "responsible",
         class: "card-header-text",
         width: "10%",
      },
      {
         text: "Constrains",
         value: "constrains",
         class: "card-header-text",
         width: "10%",
      },
      {
         text: "Condition Check Result",
         value: "conditionCheckResult",
         class: "card-header-text",
         width: "10%",
      },
      {
         text: "PG PBB Type Code",
         value: "pgPBBTypeCode",
         class: "card-header-text",
         width: "10%",
      },
      { text: "Detail", value: "action", sortable: false },
   ];

   items = [
      {
         id: 1,
         name: "Project Tree Structure",
         lineNumber: "100",
         pbbCode: "PM00008726",
         depth: 1,
         processing: "0",
         responsible: "PROJECT-OWNER-EAG",
         constrains: "No",
         conditionCheckResult: "",
         pgPBBTypeCode: "",
         children: [
            {
               id: 2,
               name: "Workflow",
               lineNumber: "101",
               pbbCode: "PM00008727",
               depth: 2,
               processing: "0",
               responsible: "PROJECT-OWNER-EAG",
               constrains: "No",
               conditionCheckResult: "",
               pgPBBTypeCode: "",
               children: [
                  {
                     id: 3,
                     name: "MS: Video Project: Initialization",
                     lineNumber: "102",
                     pbbCode: "PM00009305",
                     depth: 3,
                     processing: "0",
                     responsible: "MA-EAG-VE",
                     constrains: "No",
                     conditionCheckResult: "",
                     pgPBBTypeCode: "MILESTONE",
                     children: [
                        {
                           id: 4,
                           name: "Briefing Document Verification",
                           lineNumber: "103",
                           pbbCode: "PM00009288",
                           depth: 4,
                           processing: "0",
                           responsible: "MA-EAG-VE",
                           constrains: "No",
                           conditionCheckResult: "",
                           pgPBBTypeCode: "",
                           children: [
                              {
                                 id: 5,
                                 name: "Fill out video briefing document in Special Card",
                                 lineNumber: "104",
                                 pbbCode: "PM00009188",
                                 depth: 5,
                                 processing: "0",
                                 responsible: "CM-EAG-CM",
                                 constrains: "No",
                                 conditionCheckResult: "",
                                 pgPBBTypeCode: "",
                              },
                              {
                                 id: 6,
                                 name: "Verify data in briefing document and effort level in PM",
                                 lineNumber: "105",
                                 pbbCode: "PM00009272",
                                 depth: 5,
                                 processing: "1",
                                 responsible: "MA-EAG-VE",
                                 constrains: "No",
                                 conditionCheckResult: "",
                                 pgPBBTypeCode: "",
                              },
                           ],
                        },
                     ],
                  },
               ],
            },
         ],
      },
   ];
}
</script>
