<template>
   <div>
      <h5>
         {{ translateKey("dglExportPdf.metadataLabel", translations) }}
      </h5>
      <table class="table">
         <tr>
            <th v-for="header in headers" :key="header.value" :style="header.style">
               {{ header.text }}
            </th>
         </tr>
         <tr v-for="item in contentBrick.designGuidelineMetadataFields" :key="item.id">
            <td>
               <span>
                  {{ item.name }}
               </span>
            </td>
            <td>
               <span>{{ getItemType(item) }}</span>
            </td>
            <td>
               <template v-if="isList(item)">
                  <span
                     v-for="listItem in item.list.items.filter((x) => !x.disabled)"
                     :key="listItem.identifier"
                     class="tag"
                  >
                     {{ listItem.text }}
                  </span>
               </template>
               <template v-else>{{ item.unit?.displayText }}</template>
            </td>
            <td>
               <template v-if="isList(item)">
                  <span>{{ getListValue(item) }}</span>
               </template>
               <template v-else>
                  <span>{{ item.value }}</span>
               </template>
            </td>
         </tr>
      </table>
   </div>
</template>

<script lang="ts">
import {
   ContentBrickFieldType,
   DesignGuidelineValueFieldDefinition,
   IContentBrickDefinition,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { ContentBrickFieldTypeDecorator } from "@models/shared/ContentBrickFieldTypeDecorator";
import { Component, Prop } from "vue-property-decorator";

@Component({
   name: "ExportPdfDesignGuidelineMetadata",
   components: {},
})
export default class ExportPdfDesignGuidelineMetadata extends ComponentBase {
   @Prop({ required: true })
   contentBrick: IContentBrickDefinition;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   headers: any[] = [
      { text: "Name", value: "name", translationKey: "dglExportPdf.nameHeader", style: "width: 35%" },
      { text: "Data type", value: "type", translationKey: "dglExportPdf.dataTypeHeader", style: "width: 15%" },
      { text: "Unit", value: "unit", translationKey: "dglExportPdf.unitHeader", style: "width: 15%" },
      { text: "Value", value: "value", translationKey: "dglExportPdf.valueHeader", style: "width: 35%" },
   ];

   isList(item: DesignGuidelineValueFieldDefinition): boolean {
      return (
         item.type == ContentBrickFieldType.List ||
         item.type == ContentBrickFieldType.MultiSelectList ||
         item.type == ContentBrickFieldType.ComboBox
      );
   }

   getItemType(item: DesignGuidelineValueFieldDefinition): string | undefined {
      let decorator = new ContentBrickFieldTypeDecorator(item.type);
      return this.translateKey(decorator.translationKey, this.translations);
   }

   getListValue(item: DesignGuidelineValueFieldDefinition): string | undefined {
      if (!item.value || !item.list) return undefined;

      let arrayValue = Array.isArray(item.value) ? item.value : [item.value];
      let selectedItems = item.list.items?.filter((x) => arrayValue.includes(x.identifier));
      if (!selectedItems || selectedItems.length === 0) return undefined;

      return selectedItems.map((x) => x.text).join(", ");
   }
}
</script>

<style scoped></style>
