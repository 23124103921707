import { ContentBrickExpressionType, ContentBrickPrimaryExpression } from "@backend/api/pmToolApi";
import IProjectContentBrickExpression from "@models/project/IProjectContentBrickExpression";

/**
 * NOTE: Expression originating from Project JSON-like structure must use null values instead of undefined
 * in order to not trigger change watcher. In that case special class is used here to transform from query
 * of the component inner model.
 */
export default class ProjectContentBrickExpression implements IProjectContentBrickExpression {
   expressionType!: ContentBrickExpressionType;
   operator!: string | null;
   operands!: ProjectContentBrickExpression[] | null;
   left!: ContentBrickPrimaryExpression | null;
   right!: ContentBrickPrimaryExpression | null;

   constructor(data?: IProjectContentBrickExpression) {
      if (data) {
         for (const property in data) {
            if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
         }
      }
   }

   toJSON(data?: any) {
      data = typeof data === "object" ? data : {};
      data["expressionType"] = this.expressionType;
      data["operator"] = this.operator;
      if (Array.isArray(this.operands)) {
         data["operands"] = [];
         for (const item of this.operands) data["operands"].push(item.toJSON());
      } else {
         data["operands"] = this.operands;
      }
      data["left"] = this.left ? this.left.toJSON() : <any>null;
      data["right"] = this.right ? this.right.toJSON() : <any>null;
      return data;
   }
}
