<template>
   <v-dialog v-model="showDialog" width="1200" persistent scrollable>
      <v-card>
         <add-reference-dialog-header
            :title="title"
            :newItemRoute="newItemRoute"
            :clickReload="clickReload"
            :clickClose="clickClose"
         ></add-reference-dialog-header>
         <v-card-text>
            <v-row v-if="showDomainSelect" justify="end">
               <v-col cols="3" class="pt-0 pb-0">
                  <v-autocomplete
                     v-model="selectedDomain"
                     label="Domains"
                     :items="domains"
                     item-value="id"
                     item-text="displayText"
                     :color="selectedColor"
                     :loading="loadingDomains"
                     :hide-details="true"
                     @change="onSelectedDomainChanged"
                  />
               </v-col>
            </v-row>
            <v-row>
               <v-col>
                  <slot name="filter"></slot>
                  <v-text-field
                     v-model="searchQueryServerSide"
                     append-ico
                     n="mdi-magnify"
                     label="Search"
                     color="red darken-2"
                     single-line
                     hide-details
                     autofocus
                     @input="serverSideSearchDebounced"
                  />
               </v-col>
            </v-row>
            <v-data-table
               v-model="selectedReferences"
               :mobile-breakpoint="0"
               dense
               show-select
               :headers="referenceHeaders"
               :items="unassignedReferences"
               :loading="loading"
               :single-select="maxReferences < 2"
               :options.sync="dataTableOptions"
               :custom-filter="customSearch"
               :server-items-length="pagingTotalDocuments"
               class="max-width"
               :footer-props="footerProps"
               :sort-by.sync="sortByInternal"
               :sort-desc.sync="sortDescInternal"
               :show-expand="showExpand"
               :expanded.sync="expanded"
               @click:row="onRowClicked"
            >
               <template #header.data-table-select="{ props, on }">
                  <v-simple-checkbox v-bind="props" :disabled="disabled" v-on="on" />
               </template>
               <template #item.data-table-select="{ isSelected, select, item }">
                  <v-tooltip left :disabled="readOnlyItemReason?.(item) === undefined">
                     <template #activator="{ on, attrs }">
                        <v-simple-checkbox
                           v-bind="attrs"
                           :value="isSelected"
                           :disabled="disabled || item.isSelectable === false"
                           ui-test-data="list-item-checkbox"
                           v-on="on"
                           @input="disabled || select($event)"
                        />
                     </template>
                     <span>{{ readOnlyItemReason?.(item) }}</span>
                  </v-tooltip>
               </template>
               <template v-if="newItemRoute" #item.code="{ item }">
                  <span class="gates-list-pbb-code d-inline">
                     {{ item.code }}
                  </span>
               </template>
               <template v-if="newItemRoute" #item.displayText="{ item }">
                  <router-link :to="itemDetailTarget(item)" ui-test-data="item-name">
                     <v-icon>mdi-link</v-icon>
                  </router-link>
                  <span class="gates-list-pbb-code d-inline">
                     {{ item.displayText }}
                  </span>
               </template>
               <template #item.firstName="{ item }">
                  <router-link :to="itemDetailTarget(item)" class="gates-list-pbb-code d-inline black--text">
                     {{ item.firstName }}
                  </router-link>
               </template>
               <template #item.lastName="{ item }">
                  <router-link :to="itemDetailTarget(item)" class="gates-list-pbb-code d-inline black--text">
                     {{ item.lastName }}
                  </router-link>
               </template>
               <template
                  v-for="header in newItemRoute ? htmlPropertyHeaders : []"
                  #[getPropertySlotName(header)]="{ item }"
               >
                  <router-link
                     :key="getPropertySlotName(header)"
                     :to="itemDetailTarget(item)"
                     class="gates-list-pbb-code d-inline black--text"
                  >
                     {{ renderHtmlProperty(item, header.value) }}
                  </router-link>
               </template>
               <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                  <router-link
                     v-if="slot !== 'expanded-item'"
                     :key="slot"
                     :to="itemDetailTarget(scope.item)"
                     class="gates-list-pbb-code d-inline black--text"
                  >
                     <slot :name="slot" v-bind="scope" />
                  </router-link>
                  <slot v-else :name="slot" v-bind="scope" />
               </template>
            </v-data-table>
            <slot name="bottom"></slot>
         </v-card-text>
         <v-card-actions>
            <slot name="actions"></slot>
            <v-spacer></v-spacer>
            <v-btn
               class="error"
               text
               :disabled="
                  disabled ||
                  !(minReferences <= selectedReferences.length && selectedReferences.length <= maxReferences)
               "
               ui-test-data="update-btn"
               @click="onUpdateReferencesClick"
            >
               {{ updateButtonText }}
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import AddReferenceDialogHeader from "@components/Shared/add-reference-dialog-header.vue";
import { IItemReference, ItemReference, FilterOptions } from "@backend/api/pmToolApi";
import ViewItem from "@models/view/ViewItem";
import RenderFieldUtils from "@utils/RenderFieldUtils";
import GlobalStore from "@backend/store/globalStore";
import EventBus from "@backend/EventBus";
import OverviewBase from "@components/Shared/Base/overview-base.vue";
import Events from "@models/shared/Events";
import pluralize from "pluralize";
import { cloneDeep, debounce, isEqual } from "lodash";
import globalStore from "@backend/store/globalStore";
import DomainModel from "@models/domain/domainModel";
import { DataOptions } from "vuetify";
import { RoutePathWithoutParams } from "@root/routes";
import { PagingStore, QueryOptions } from "@utils/Paging";

enum DialogFilterMode {
   Select = 0,
   Filter = 1,
}

@Component({
   name: "AddReferenceSelectionDialog",
   components: {
      AddReferenceDialogHeader,
   },
})
export default class AddReferenceSelectionDialog extends OverviewBase {
   loading: boolean = false;
   searchQueryServerSide: string = "";
   isFilterDirty: boolean = false;

   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: () => [] })
   filter: ItemReference[] | ItemReference | null;

   @Prop({ default: DialogFilterMode.Select })
   filterMode: DialogFilterMode;

   @Prop({ default: "Reference" })
   entity: string;

   @Prop({ default: null })
   newItemRoute: RoutePathWithoutParams;

   @Prop({ default: null })
   itemDetailRoute: RoutePathWithoutParams;

   @Prop({ default: false })
   showExpand: boolean;

   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   apiEndpoint: (pagingStore: PagingStore, domain?: number, search?: string) => Promise<ItemReference[]>;

   @Prop({ default: 1 })
   minReferences: number;

   @Prop({ default: Infinity })
   maxReferences: number;

   @Prop({ default: null })
   serverSideSearchFilter: FilterOptions | null;

   @Prop()
   headers?: ViewItem[];

   @Prop({ default: false })
   disabled: boolean;

   /**
    * Should return a reason why the item is readonly or `undefined` if it is not.
    */
   @Prop({ default: undefined })
   readOnlyItemReason?: (x: ItemReference) => string | undefined;

   protected defaultDataTableOptions: Partial<DataOptions> | undefined = {
      sortBy: this.sortBy !== undefined ? (Array.isArray(this.sortBy) ? this.sortBy : [this.sortBy]) : ["code"],
      sortDesc: this.sortDesc !== undefined ? (Array.isArray(this.sortDesc) ? this.sortDesc : [this.sortDesc]) : [true],
   };

   @Watch("dataTableOptions", { deep: true })
   dataTableOptionsChanged(newVal, oldVal): void {
      // vue may internally change to value-equal instance and trigger this watch
      if (!isEqual(newVal, oldVal)) {
         this.loadUnassignedReferences();
      }
   }

   itemDetailTarget(entityReference: IItemReference) {
      return { path: `${this.itemDetailRoute}/${entityReference.id}` };
   }

   /**
    * Whether or not to show domain selection.
    * When set to true, "apiEndpoint" is expected to have a "domain" parameter, in order to load references tied to the given domain
    */
   @Prop({ default: false })
   showDomainSelect: boolean;

   /**
    * Whether or not the dialog is to Add or Update references.
    * When set to true, labels on the dialog are "Add", "Update" otherwise
    */
   @Prop({ default: true })
   isAdd: boolean;

   @Prop({ default: undefined })
   sortBy?: string | [];

   @Prop({ default: undefined })
   sortDesc?: boolean | [];

   @Prop({ default: false })
   hideSelected: boolean;

   @Watch("serverSideSearchFilter", { deep: true })
   filterChanged() {
      if (this.showDialog) {
         this.loadUnassignedReferences();
      } else {
         this.isFilterDirty = true;
      }
   }

   @Watch("showDialog", { deep: true })
   async showDialogChanged() {
      if (this.showDialog) {
         this.selectedReferences = [];
         this.expanded = [];
         this.searchQueryServerSide = "";
         if (this.showDomainSelect) {
            await this.loadAndSelectDomain();
         }

         if (this.serverSideSearchFilter) {
            this.serverSideSearch();
         } else {
            this.loadUnassignedReferences();
         }
      }
   }

   //---------- Domain ----------------
   loadingDomains: boolean = false;
   selectedDomain: number;
   domains: DomainModel[] | undefined = [];
   selectedColor: string = "red darken-3";
   async onSelectedDomainChanged() {
      await this.clickReload();
   }

   async onGlobalDomainChanged() {
      await this.loadAndSelectDomain();
   }

   async loadAndSelectDomain() {
      if (!this.domains) {
         await this.loadDomains();
      }
      this.setSelectedDomain();
   }

   setSelectedDomain() {
      this.selectedDomain = GlobalStore.getDomain();
   }

   // -------- Add dialog -------------
   unassignedReferences: ItemReference[] = [];
   selectedReferences: ItemReference[] = [];
   expanded: ItemReference[] = [];

   onRowClicked(item: ItemReference) {
      const index = this.selectedReferences.indexOf(item);
      if (index === -1) {
         this.selectedReferences.push(item);
      } else {
         this.selectedReferences.splice(index, 1);
      }
   }

   get title(): string {
      return this.maxReferences < 2
         ? `${this.translateKey("addReferenceSelectionDialog.selectOneLabel")} ${this.entity}`
         : `${this.translateKey("addReferenceSelectionDialog.selectOneOrMore")} ${this.entityPlural}`;
   }

   get entityPlural(): string {
      return pluralize(this.entity);
   }

   get updateButtonText(): string {
      var prefix = this.isAdd
         ? this.translateKey("addReferenceSelectionDialog.addLabel")
         : this.translateKey("addReferenceSelectionDialog.updateLabel");

      return `${prefix} ${this.maxReferences === 1 ? this.entity : this.entityPlural}`;
   }

   get hasChanges(): boolean {
      return this.selectedReferences.length > 0 || this.minReferences === 0; // any items added
   }

   hideDialog() {
      this.$emit("hideDialog");
   }

   clickClose(): void {
      this.hideDialog();
   }

   async clickReload(): Promise<void> {
      this.loading = true;
      await this.loadUnassignedReferences();
      this.loading = false;
   }

   onUpdateReferencesClick(): void {
      if (this.hasChanges) {
         this.$emit("addReferences", this.selectedReferences);
      } else {
         this.hideDialog();
      }
   }

   /**
    * Default reference table headers
    */
   defaultReferenceHeaders = [
      {
         text: "Code",
         value: "code",
         class: "pmtool-table-header-fixed-sm",
         translationKey: "addReferenceSelectionDialog.codeHeader",
      },
      { text: "Name", value: "displayText", translationKey: "addReferenceSelectionDialog.nameHeader" },
   ];

   /**
    * Dynamic reference table headers
    * @returns User provided headers via props, default headers otherwise
    */
   get referenceHeaders(): ViewItem[] {
      return this.headers ?? this.defaultReferenceHeaders;
   }

   /**
    * Gets dynamic slot name from the given header for the template override
    * @param header Header to get the property name from
    * @returns Full slot name, for example: "item.description"
    */
   getPropertySlotName(header: ViewItem): string {
      return "item." + (header.value as string | number);
   }

   /**
    * Headers which are marked with "isHtml" flag, meaning that correspondent object properties contain HTML tags
    * @returns Headers of which object properties contain HTML tags
    */
   get htmlPropertyHeaders(): ViewItem[] {
      return this.referenceHeaders.filter((h) => h.isHtml === true);
   }

   /**
    * Renders value of the item property which contains HTML tags
    * @param item Object from which to get the property
    * @param propertyName Name of the property to render, containing HTML tags
    * @returns Property text value, without HTML tags
    */
   renderHtmlProperty(item: ItemReference, propertyName: string): string {
      const property: string = item[propertyName];
      return property ? RenderFieldUtils.removeHtml(property) : "";
   }

   //--------- ServerSideSearch---------
   private emitedSearchQueryServerSide: string | null = null;

   private serverSideSearchDebounced = debounce(() => this.serverSideSearch(), 200);

   serverSideSearch() {
      let newFilter = cloneDeep(this.serverSideSearchFilter ?? {}) as FilterOptions;
      if (this.searchQueryServerSide.length >= 3) {
         newFilter.searchQuery = this.searchQueryServerSide;
      } else if (this.serverSideSearchFilter?.searchQuery && !this.searchQueryServerSide) {
         newFilter.searchQuery = this.searchQueryServerSide;
      }
      newFilter.searchQuery ??= this.searchQueryServerSide;
      this.loadUnassignedReferences(newFilter);
   }

   // -------- Sort ------------
   get sortByInternal(): string | [] {
      // sortBy "code" by default when not specified explicitly
      return this.sortBy !== undefined ? this.sortBy : "code";
   }

   set sortByInternal(value: string | []) {
      this.$emit("update:sortBy", value);
   }

   get sortDescInternal(): boolean | [] {
      // sortDesc "true" by default when not specified explicitly, and sortBy is being defaulted
      return this.sortDesc !== undefined ? this.sortDesc : this.sortBy === undefined ? true : [];
   }

   set sortDescInternal(value: boolean | []) {
      this.$emit("update:sortDesc", value);
   }

   // -------- API -------------
   async loadUnassignedReferences(filterOptions?: FilterOptions): Promise<void> {
      this.loading = true;
      try {
         // Call the API
         let pagingApiCalls = 0;
         let unassignedReferences = await this.apiEndpoint(
            {
               OnBeforePagedQuery: (queryOptions: QueryOptions) => {
                  pagingApiCalls++;
                  queryOptions.filter = new FilterOptions({
                     ...(queryOptions.filter ?? ({} as FilterOptions)),
                     ...filterOptions,
                  });
                  this.OnBeforePagedQuery(queryOptions);
               },
               OnAfterPagedQuery: (token: string, total: number) => {
                  pagingApiCalls++;
                  this.OnAfterPagedQuery(token, total);
               },
            },
            this.showDomainSelect ? this.selectedDomain : undefined,
            this.searchQueryServerSide
         );

         // If the caller of this component does not properly implement server side, we filter client side here, but don't abuse this - in new cases always implement server side
         if (pagingApiCalls < 2 && this.searchQueryServerSide) {
            unassignedReferences = unassignedReferences.filter((x) =>
               x.displayText?.toLowerCase().includes(this.searchQueryServerSide.toLowerCase())
            );
         }

         if (this.filter) {
            var filterValues: ItemReference[];
            if (this.filter instanceof ItemReference) {
               filterValues = [this.filter];
            } else if (Array.isArray(this.filter)) {
               filterValues = this.filter;
            } else {
               filterValues = [];
            }

            if (this.filterMode === DialogFilterMode.Select) {
               //remove all assigned qualifications from the list
               this.selectedReferences = unassignedReferences.filter((aq) => filterValues.some((q) => q.id === aq.id));
               if (this.hideSelected) {
                  unassignedReferences = unassignedReferences.filter((obj) => !this.selectedReferences.includes(obj));
               }
            } else if (this.filterMode === DialogFilterMode.Filter) {
               unassignedReferences = unassignedReferences.filter((aq) => !filterValues.some((q) => q.id === aq.id));
            }
         }
         // Process/Save data etc.
         this.unassignedReferences = unassignedReferences;

         if (this.readOnlyItemReason !== undefined) {
            for (const item of unassignedReferences) {
               item.isSelectable = this.readOnlyItemReason(item) === undefined;
            }
         }

         // #36179: sort by column and pagination was not working without this when caller of this component does not implement server side
         if (pagingApiCalls < 2) {
            // preserve pagination parameters
            this.OnAfterPagedQuery(unassignedReferences.continuationToken, unassignedReferences.totalDocumentsCount);
         }
      } catch (error) {
         this.notifyError(error, "load", `${this.entity}`);
      }
      this.loading = false;
   }

   async loadDomains(): Promise<void> {
      this.loadingDomains = true;
      try {
         this.domains = await globalStore.getDomains();
      } catch (error) {
         this.notifyError(error, "load", "Domains");
      }
      this.loadingDomains = false;
   }

   async loadTranslations() {
      await this.loadRouteTranslations("add-reference-selection-dialog");
      this.translateHeaders(this.defaultReferenceHeaders);
   }

   mounted() {
      this.loadTranslations();
      EventBus.$on(Events.LanguageChanged, this.loadTranslations);
      if (this.showDomainSelect) {
         this.loadAndSelectDomain();

         EventBus.$on(Events.DomainChanged, this.onGlobalDomainChanged);
      }

      //if loaded with showDialog set already
      if (this.showDialog) {
         this.showDialogChanged();
      }
   }

   beforeDestroy() {
      if (this.showDomainSelect) {
         EventBus.$off(Events.DomainChanged, this.onGlobalDomainChanged);
      }
   }
}

export { AddReferenceSelectionDialog, DialogFilterMode };
</script>
<style lang="scss" scoped>
.theme--light.v-data-table .v-data-footer {
   padding-right: 0px;
}
.pointer {
   cursor: pointer;
}
</style>
