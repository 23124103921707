<template>
   <v-tooltip top>
      <template #activator="{ on, attrs }">
         <v-badge :color="badgeColor" overlap :value="ConditionUtils.isDefined(value)">
            <template #badge>
               <v-icon @click="$emit('click')">mdi-code-braces</v-icon>
            </template>
            <!--tooltip cannot be styled from outside, bind it manually on the button -->
            <v-btn
               v-bind="{ attrs: attrs, class: $vnode.data.staticClass }"
               class="round-none"
               icon
               elevation="2"
               v-on="on"
               @click="$emit('click')"
            >
               <v-icon :color="`${color} lighten-1`">{{ icon }}</v-icon>
            </v-btn>
         </v-badge>
      </template>
      <span>{{ tooltip }}</span>
   </v-tooltip>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { GateCondition } from "@backend/api/pmToolApi";
import ConditionUtils from "@utils/ConditionUtils";

@Component({
   name: "ConditionIcon",
   components: {},
})
export default class ConditionIcon extends Vue {
   @Prop({ required: true })
   value: GateCondition | null | undefined;

   @Prop({ required: true })
   icon: string;

   @Prop({ required: true })
   color: string;

   @Prop({ required: true })
   tooltip: string;

   @Prop({ default: false })
   desaturate: boolean;

   get badgeColor(): string {
      return this.desaturate ? "grey lighten-1" : `${this.color} lighten-3`;
   }

   ConditionUtils = ConditionUtils;
}
</script>
