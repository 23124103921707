<template>
   <div>
      <v-expansion-panels v-model="showPanel" flat>
         <v-expansion-panel @change="setPanelValue()">
            <v-expansion-panel-header color="bg240">
               <v-container fluid>
                  <v-row align="center">
                     <v-col class="p-0">
                        <p class="m-0">{{ translateKey("tableFilter.filterLabel") }}</p>
                     </v-col>
                     <v-col class="p-0">
                        <p v-show="showPanel !== 0 && filteredByText" class="m-0" style="color: grey">
                           (Active filters: {{ filteredByText }})
                        </p>
                     </v-col>
                     <v-col class="p-0 pr-3 d-flex justify-end">
                        <v-btn color="error" @click.native.stop @click="onResetFilterButtonClick">
                           {{ translateKey("tableFilter.resetFilterButton") }}
                        </v-btn>
                        <column-selection
                           v-if="showColumnSelection"
                           :headers="headers"
                           :defaultHeaders="['version', 'code', 'displayText', 'entityStatus']"
                           :entityType="entityType"
                           @selected-columns="setColumns"
                        ></column-selection>
                     </v-col>
                  </v-row>
               </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="table-filter-panel-content">
               <v-container fluid>
                  <v-row v-if="displayTagClouds">
                     <v-col v-if="isSearchIdentifierCloudShown" cols="12">
                        <tag-cloud
                           v-model="internalValue.searchIdentifiers"
                           :items="searchIdentifierItems"
                           :loading="loadingSearchIdentifiers"
                           itemName="Search Identifier"
                           @input="input(1)"
                        />
                     </v-col>
                     <v-col cols="12">
                        <tag-cloud
                           v-model="internalValue.tags"
                           :items="tagItems"
                           :loading="loadingTags"
                           itemName="Tag"
                           @input="input(0)"
                        />
                     </v-col>
                  </v-row>
                  <v-row align="center">
                     <v-col v-if="displayLastVisited" class="p-0" cols="2">
                        <v-checkbox
                           v-model="recentItemsCheckBox"
                           :disabled="lastVisitedDisabled"
                           hide-details
                           class="mr-4"
                           @change="checkLastVisited()"
                        >
                           <template #label>
                              <p class="mb-0 mt-2">{{ translateKey("tableFilter.myRecentItemsSwitch") }}</p>
                           </template>
                        </v-checkbox>
                     </v-col>
                     <v-col v-if="displayOnlyActiveStatus" class="p-0" cols="2">
                        <v-checkbox
                           v-model="onlyActiveItemsCheckBox"
                           hide-details
                           class="mr-4"
                           @change="checkOnlyActive()"
                        >
                           <template #label>
                              <p class="mb-0 mt-2">{{ translateKey("tableFilter.activeStatusSwitch") }}</p>
                           </template>
                        </v-checkbox>
                     </v-col>
                     <v-col v-if="showEntityStatusesFilter" class="p-0 pt-2" cols="2">
                        <v-select
                           v-model="entityStatusesFilter"
                           class="mr-4"
                           multiple
                           :items="entityStatuses"
                           item-value="value"
                           :item-text="(item) => getDecoratorText(item)"
                           label="Status"
                           @change="onEntityStatusesFilterChanged()"
                        ></v-select>
                     </v-col>

                     <v-col v-if="displayOwnership" class="p-0" cols="2">
                        <v-checkbox
                           v-model="onlyUserCreated"
                           hide-details
                           class="mr-4"
                           @change="checkOnlyUserCreated()"
                        >
                           <template #label>
                              <p class="mb-0 mt-2">{{ translateKey("tableFilter.createdByMeSwitch") }}</p>
                           </template>
                        </v-checkbox>
                     </v-col>
                     <slot></slot>
                  </v-row>
               </v-container>
            </v-expansion-panel-content>
         </v-expansion-panel>
      </v-expansion-panels>
   </div>
</template>

<script lang="ts">
import {
   ContentBrickDefinitionFilterOptions,
   SubContentBrickDefinitionFilterOptions,
   DomainDataModelFilterOptions,
   DomainDataModelInstanceFilterOptions,
   EntityStatus,
   EntityType,
   IFilterOptions,
   ItemReference,
   LibraryDataModelFilterOptions,
   PbbFilterOptions,
   ProjectFilterOptions,
   TagApi,
   TagFilterOptions,
   TagReference,
   VersionedFilterOptions,
} from "@backend/api/pmToolApi";
import { Component, Prop, Watch } from "vue-property-decorator";
import TagCloud from "@components/Shared/tag-cloud.vue";
import filterStore from "@backend/store/filterStore";
import { TagTypeEnum } from "@models/filter/TagTypeEnum";
import { cloneDeep, debounce, orderBy } from "lodash";
import ColumnSelection from "@components/Shared/column-selection.vue";
import authStore from "@backend/store/authStore";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { EntityStatusDecorator } from "@models/shared/EntityStatusDecorator";
import globalStore from "@backend/store/globalStore";

// NOTE: This component shall be extended/refactored in future, in order to satisfy all filtering features
@Component({
   components: {
      TagCloud,
      ColumnSelection,
   },
})
export default class TableFilter extends ComponentBase {
   @Prop({ required: true })
   value: VersionedFilterOptions;

   @Prop({ default: () => [] })
   currentReferences!: ItemReference[];

   @Prop({ required: true })
   entityType: EntityType;

   @Prop({ default: true })
   useLocalStorage: boolean;

   @Prop({ default: false })
   hideActiveStateFilter: boolean;

   @Prop({ default: true })
   showColumnSelection: boolean;

   @Prop({ default: true })
   hasTags!: boolean;

   @Prop({ default: false })
   hasSearchIdentifiers!: boolean;

   @Prop()
   additionalFilterText: string[];

   @Prop({ required: true })
   headers;

   @Prop({ default: undefined })
   domain?: number;

   @Prop({ default: true })
   showSearchIdentifiers: boolean;

   @Prop({ default: undefined })
   entityFilter:
      | ContentBrickDefinitionFilterOptions
      | SubContentBrickDefinitionFilterOptions
      | DomainDataModelFilterOptions
      | LibraryDataModelFilterOptions
      | PbbFilterOptions
      | ProjectFilterOptions
      | undefined;

   @Prop({
      default: () => () => new VersionedFilterOptions(),
   })
   getDefaultFilterValue: () => VersionedFilterOptions;

   @Prop({ default: false })
   hideLastVisited: boolean;

   @Prop({ default: true })
   showEntityStatusesFilter: boolean;

   @Prop({ default: true })
   showOwnershipFilter: boolean;

   entityStatuses: EntityStatusDecorator[] = EntityStatusDecorator.AllItems;
   // TODO: replace with translation after merge with '31234-remove-labels-from-decorators' branch
   getEntityStatusText(item: EntityStatusDecorator) {
      return `${item.label}`;
   }

   //---------- Filter handling -----------
   get displayLastVisited(): boolean {
      return this.hideLastVisited
         ? false
         : this.entityType == EntityType.ProcessBuildingBlockDefinition ||
              this.entityType == EntityType.DomainDataModelDefinition ||
              this.entityType == EntityType.ContentBrickDefinition ||
              this.entityType == EntityType.LibraryDataModelDefinition ||
              this.entityType == EntityType.ProjectDefinition ||
              this.entityType == EntityType.ProjectDraftDefinition ||
              this.entityType == EntityType.ProjectTaskDefinition ||
              this.entityType == EntityType.DomainDataModelInstance;
   }

   get displayOnlyActiveStatus(): boolean {
      return (
         (this.entityType == EntityType.ProcessBuildingBlockDefinition ||
            this.entityType == EntityType.DomainDataModelDefinition ||
            this.entityType == EntityType.ContentBrickDefinition ||
            this.entityType == EntityType.LibraryDataModelDefinition ||
            this.entityType == EntityType.DomainDataModelInstance) &&
         !this.hideActiveStateFilter
      );
   }

   get displayTagClouds() {
      return (
         this.entityType != EntityType.ProjectDraftDefinition &&
         this.entityType != EntityType.ProjectTaskDefinition &&
         this.entityType != EntityType.RoleDefinition &&
         this.entityType != EntityType.List &&
         this.entityType != EntityType.KanbanReport &&
         this.entityType != EntityType.GridReport &&
         this.entityType != EntityType.AutomaticImportDefinition &&
         this.entityType != EntityType.EnactmentPriorityLabel &&
         this.entityType != EntityType.Tag &&
         this.entityType != EntityType.TechnicalMeasure
      );
   }

   get displayOwnership() {
      return (
         (this.entityType == EntityType.ProjectDefinition || this.entityType == EntityType.ProjectDraftDefinition) &&
         this.showOwnershipFilter
      );
   }

   get internalValue(): VersionedFilterOptions {
      return this.value;
   }

   get filteredByText(): string {
      let result = [] as Array<string>;
      if (this.value.lastVisited) {
         result.push("My recent items");
      }
      if (this.value.createdBy && this.value.createdBy == authStore.getUserId()) {
         result.push("Created by me");
      }
      if (this.value.entityStatus && this.value.entityStatus == EntityStatus.Active && !this.hideActiveStateFilter) {
         result.push("Only active records");
      }
      if (this.value.tags) {
         let tagsText = this.value.tags.map((x) => x.displayText as string);
         if (tagsText) {
            result = result.concat(tagsText);
         }
      }
      if (this.value.searchIdentifiers) {
         let searchIdentifiersText = this.value.searchIdentifiers.map((x) => x.displayText as string);
         if (searchIdentifiersText) {
            result = result.concat(searchIdentifiersText);
         }
      }

      if (this.additionalFilterText) {
         result = result.concat(this.additionalFilterText);
      }

      return result.join(", ");
   }

   set internalValue(value: VersionedFilterOptions) {
      this.updateValue(value);
   }

   updateValue(value: IFilterOptions) {
      this.$emit("input", value);
   }

   onResetFilterButtonClick() {
      // First step: Reset local and localStorage filters
      this.resetFilters();

      // Second step: Use defaultFilter that can use also latest settings of localStorage filters
      var newFilter = cloneDeep(this.getDefaultFilterValue());
      this.internalValue = newFilter;

      this.reloadTagsAndSearchIdentifiers();

      this.$emit("reset-filter");
      this.filter();
   }

   resetFilters() {
      this.recentItemsCheckBox = false;
      this.onlyActiveItemsCheckBox = false;
      this.onlyUserCreated = false;
      this.entityStatusesFilter = [EntityStatus.Active, EntityStatus.Draft];

      if (this.useLocalStorage) {
         this.resetLocalStorage();
      }
   }

   resetLocalStorage() {
      filterStore.setOnlyUserCreated(this.onlyUserCreated, this.entityType);

      filterStore.setSelectedTagsAndSearchIdentifiersValues(this.entityType, [], TagTypeEnum.Tag);
      filterStore.setSelectedTagsAndSearchIdentifiersValues(this.entityType, [], TagTypeEnum.SearchIdentifier);

      filterStore.setIsLastVisitedActive(this.recentItemsCheckBox, this.entityType);
      filterStore.setOnlyActive(this.onlyActiveItemsCheckBox, this.entityType);
      filterStore.setEntityStatuses(this.entityStatusesFilter, this.entityType);
   }

   filter() {
      this.$emit("filter", this.internalValue);
   }

   input(type: TagTypeEnum) {
      let data: ItemReference[] | undefined = [];
      switch (type) {
         case TagTypeEnum.Tag: {
            data = this.removeDuplicateTags(this.internalValue.tags);
            break;
         }
         case TagTypeEnum.SearchIdentifier: {
            data = this.removeDuplicateTags(this.internalValue.searchIdentifiers);
            break;
         }
      }
      if (this.useLocalStorage) {
         filterStore.setSelectedTagsAndSearchIdentifiersValues(this.entityType, data, type);
      } else {
         this.internalValue.tags = data;
      }

      this.filter();
   }

   @Watch("currentReferences", { deep: true })
   onValueChanged(): void {
      if (this.displayTagClouds) {
         // Trigger tag/searchIdentifier loading when filter is changed
         if (JSON.stringify(this.entityFilterInternalValue) !== JSON.stringify(this.internalValue)) {
            this.entityFilterInternalValue = cloneDeep(this.internalValue);
            this.reloadTagsAndSearchIdentifiersDebounced();
         }
      }
   }

   @Watch("internalValue", { deep: true })
   oninternalValueValueChanged(): void {}

   sortArray(arrays) {
      return orderBy(arrays, "displayText", "asc");
   }

   //-------- Tags and SI ---------
   private reloadTagsAndSearchIdentifiersDebounced = debounce(() => this.reloadTagsAndSearchIdentifiers(), 200);

   entityFilterInternalValue:
      | ContentBrickDefinitionFilterOptions
      | SubContentBrickDefinitionFilterOptions
      | DomainDataModelFilterOptions
      | LibraryDataModelFilterOptions
      | PbbFilterOptions
      | ProjectFilterOptions
      | undefined;

   // Check and remove duplicite tags or Search Identifiers from collection
   removeDuplicateTags(tags: ItemReference[] | undefined): ItemReference[] {
      return tags && tags.length > 0 ? [...new Map(tags.map((m) => [m.id, m])).values()] : [];
   }

   reloadTagsAndSearchIdentifiers() {
      if (this.hasTags || this.searchIdentifierFilter) {
         this.loadTagsAndSearchIdentifiers();
      }
   }

   initTagFilter(tagType: string, filteredEntities: ItemReference[]): TagFilterOptions {
      return new TagFilterOptions({
         entityStatus: EntityStatus.Active,
         entityCodes: undefined,
         tagType: tagType,
         filteredEntityDomain: this.domain,
         filteredEntities: filteredEntities,
         filteredEntityType: this.entityType,
         filteredEntityFilterOptions: this.getDefaultFilterValue(),
         domains: [globalStore.getDomain()],
      });
   }

   //---------- Panels ------------
   showPanel: number | null = null;

   initPanel() {
      let panel: number | null = null;
      if (this.useLocalStorage && filterStore.getShowFilter(this.entityType)) {
         panel = 0;
      }

      this.showPanel = panel;
   }

   setPanelValue() {
      if (this.useLocalStorage) {
         if (this.showPanel !== 0) {
            filterStore.setShowFilter(true, this.entityType);
         } else {
            filterStore.setShowFilter(false, this.entityType);
         }
      }
   }

   //---------- Tags --------------
   loadingTags: boolean = false;
   tagItems: ItemReference[] | null = null;
   tagFilter: TagFilterOptions | null = this.hasTags ? this.initTagFilter("Tag", this.currentReferences) : null;

   setTagItems(tags: TagReference[]) {
      tags = tags.filter((t) => t.tagType == "Tag");

      if (!tags || tags.length == 0) {
         if (this.useLocalStorage) {
            this.internalValue.tags = filterStore.getSelectedTagCloud(TagTypeEnum.Tag, this.entityType) ?? [];
         } else if (!this.internalValue.tags) {
            this.internalValue.tags = [];
         }

         this.tagItems = this.sortArray(this.internalValue.tags);
         this.internalValue.tags = this.sortArray(
            this.tagItems?.filter((x) => this.internalValue.tags.some((y) => y.id == x.id)) ?? []
         );
      } else {
         this.tagItems = this.sortArray(tags);

         if (this.useLocalStorage) {
            this.internalValue.tags = this.sortArray(
               this.tagItems?.filter((x) =>
                  filterStore.getSelectedTagCloud(TagTypeEnum.Tag, this.entityType).some((y) => y.id == x.id)
               ) ?? []
            );
         } else {
            if (!this.internalValue.tags) {
               this.internalValue.tags = [];
            }
            this.internalValue.tags = this.sortArray(
               this.tagItems?.filter((x) => this.internalValue.tags.some((y) => y.id == x.id)) ?? []
            );
         }
      }
   }

   addEntityFilter(filter: TagFilterOptions) {
      if (this.entityFilter && filter) {
         if (this.entityType === EntityType.ContentBrickDefinition) {
            filter.filteredEntityFilterOptions = cloneDeep(this.entityFilter as ContentBrickDefinitionFilterOptions);
         } else if (this.entityType === EntityType.SubContentBrickDefinition) {
            filter.filteredEntityFilterOptions = cloneDeep(this.entityFilter as SubContentBrickDefinitionFilterOptions);
         } else if (this.entityType === EntityType.DomainDataModelDefinition) {
            filter.filteredEntityFilterOptions = cloneDeep(this.entityFilter as DomainDataModelFilterOptions);
         } else if (this.entityType === EntityType.DomainDataModelInstance) {
            filter.filteredEntityFilterOptions = cloneDeep(this.entityFilter as DomainDataModelInstanceFilterOptions);
         } else if (this.entityType === EntityType.LibraryDataModelDefinition) {
            filter.filteredEntityFilterOptions = cloneDeep(this.entityFilter as LibraryDataModelFilterOptions);
         } else if (this.entityType === EntityType.ProcessBuildingBlockDefinition) {
            filter.filteredEntityFilterOptions = cloneDeep(this.entityFilter as PbbFilterOptions);
         } else if (this.entityType === EntityType.Project) {
            filter.filteredEntityFilterOptions = cloneDeep(this.entityFilter as ProjectFilterOptions);
         }
      }
   }

   //---------- Search Identifiers --------------
   loadingSearchIdentifiers: boolean = false;
   searchIdentifierItems: ItemReference[] | null = null;
   searchIdentifierFilter: TagFilterOptions | null = this.hasSearchIdentifiers
      ? this.initTagFilter("Search Identifier", this.currentReferences)
      : null;

   // Sadly javascript's pass object by copy of a reference disallows more generic approach
   setSearchIdentifierItems(searchIdentifiers: TagReference[]) {
      searchIdentifiers = searchIdentifiers.filter((t) => t.tagType == "Search Identifier");

      if (!searchIdentifiers || searchIdentifiers.length == 0) {
         if (this.useLocalStorage) {
            this.internalValue.searchIdentifiers =
               filterStore.getSelectedTagCloud(TagTypeEnum.SearchIdentifier, this.entityType) ?? [];
         } else if (!this.internalValue.searchIdentifiers) {
            this.internalValue.searchIdentifiers = [];
         }

         this.searchIdentifierItems = this.sortArray(this.internalValue.searchIdentifiers);
         this.internalValue.searchIdentifiers = this.sortArray(
            this.searchIdentifierItems?.filter((x) => this.internalValue.searchIdentifiers.some((y) => y.id == x.id)) ??
               []
         );
      } else {
         this.searchIdentifierItems = this.sortArray(searchIdentifiers);
         this.internalValue.searchIdentifiers = this.sortArray(
            this.searchIdentifierItems?.filter((x) =>
               filterStore.getSelectedTagCloud(TagTypeEnum.SearchIdentifier, this.entityType).some((y) => y.id == x.id)
            ) ?? []
         );
      }
   }

   get isSearchIdentifierCloudShown(): boolean {
      // SIs are hidden now. When enabled, note that they became a separate entity.
      // eslint-disable-next-line no-constant-binary-expression
      return false && !!this.searchIdentifierItems && this.showSearchIdentifiers;
   }

   //---------- Last Visited --------------
   recentItemsCheckBox: boolean = false;

   checkLastVisited() {
      if (this.useLocalStorage) {
         filterStore.setIsLastVisitedActive(this.recentItemsCheckBox, this.entityType);
      }
      if (this.recentItemsCheckBox) {
         this.internalValue.lastVisited = filterStore.getLastVisited(this.entityType).map((x) => x.id);
      } else {
         this.internalValue.lastVisited = undefined;
      }

      this.filter();
   }

   get lastVisitedDisabled() {
      let lastVisitedIds = filterStore.getLastVisited(this.entityType).map((x) => x.id);
      return !lastVisitedIds.length;
   }

   //---------- Only Active --------------
   onlyActiveItemsCheckBox: boolean = false;

   checkOnlyActive() {
      if (this.useLocalStorage) {
         filterStore.setOnlyActive(this.onlyActiveItemsCheckBox, this.entityType);
      }
      if (this.onlyActiveItemsCheckBox) {
         this.internalValue.entityStatus = EntityStatus.Active;
      } else {
         this.internalValue.entityStatus = undefined;
      }

      this.filter();
   }

   //---------- Entity statuses --------------
   entityStatusesFilter: EntityStatus[] = [];

   onEntityStatusesFilterChanged() {
      if (this.useLocalStorage) {
         filterStore.setEntityStatuses(this.entityStatusesFilter, this.entityType);
      }
      this.internalValue.entityStatuses = this.entityStatusesFilter;
      this.filter();
   }

   get onlyActiveDisabled() {
      return !this.currentReferences.some((x) => x.entityStatus == EntityStatus.Active);
   }

   //---------- Only User Created --------------
   onlyUserCreated: boolean = false;

   checkOnlyUserCreated() {
      if (this.useLocalStorage) {
         filterStore.setOnlyUserCreated(this.onlyUserCreated, this.entityType);
      }
      if (this.onlyUserCreated) {
         let userId = authStore.getUserId();
         if (userId) {
            this.internalValue.createdBy = userId;
         } else {
            this.internalValue.createdBy = undefined;
         }
      } else {
         this.internalValue.createdBy = undefined;
      }

      this.filter();
   }

   // ---- API -----
   async loadTagsAndSearchIdentifiers(): Promise<void> {
      this.loadingTags = true;
      this.loadingSearchIdentifiers = true;

      try {
         let filterOption: TagFilterOptions | null;

         filterOption = this.hasTags ? this.tagFilter : null;
         filterOption ??= this.initTagFilter("Tag", []);

         this.addEntityFilter(filterOption);

         let result = await TagApi.getAllTagReferences(filterOption);

         this.setTagItems(result);
      } catch (error) {
         this.notifyError(error, "load", "SearchIdentifiers");
      }

      this.loadingTags = false;
      this.loadingSearchIdentifiers = false;
   }

   routeNames: string[] = ["table-filter", "entity-status-decorator"];

   mounted() {
      this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => this.loadRouteTranslations(this.routeNames));
      this.recentItemsCheckBox = this.useLocalStorage ? filterStore.getIsLastVisitedActive(this.entityType) : false;
      this.onlyActiveItemsCheckBox = this.useLocalStorage ? filterStore.getOnlyActive(this.entityType) : false;
      this.entityStatusesFilter = this.useLocalStorage
         ? filterStore.getEntityStatuses(this.entityType)
         : [EntityStatus.Draft, EntityStatus.Active];

      this.onlyUserCreated = this.useLocalStorage ? filterStore.getOnlyUserCreated(this.entityType) : false;

      this.initPanel();
   }

   @Watch("domain")
   onDomainChanged() {
      // First step: Reset local and localStorage filters
      this.resetFilters();

      // Second step: Use defaultFilter that can use alsow latest settings of localStorage filters
      var newFilter = cloneDeep(this.getDefaultFilterValue());
      this.internalValue = newFilter;

      this.tagFilter = this.hasTags ? this.initTagFilter("Tag", []) : null;
      this.searchIdentifierFilter = this.hasSearchIdentifiers ? this.initTagFilter("Search Identifier", []) : null;

      this.reloadTagsAndSearchIdentifiers();

      this.$emit("reset-filter");
      this.filter();
   }

   setColumns(selected) {
      selected.sort(
         (a, b) => this.headers.findIndex((x) => x.value == a) - this.headers.findIndex((x) => x.value == b)
      );
      this.$emit("selected-columns", selected);
   }
}
</script>

<style scoped lang="scss">
.table-filter {
   &-panel {
      &-content {
         border-style: solid;
         border-color: rgb(240, 240, 240);
         border-width: 2px;
      }
   }
}
</style>
