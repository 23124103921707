<template>
   <div v-if="listModel">
      <v-row>
         <v-col cols="12" class="pl-8">
            <v-btn-toggle v-model="isMulticolumn" dense color="red" mandatory @change="toggleMultiColumn">
               <v-btn :value="false">
                  {{ translateKey("listItemsTab.setSingleColumn", translations) }}
               </v-btn>
               <v-btn :value="true">
                  {{ translateKey("listItemsTab.setMultipleColumns", translations) }}
               </v-btn>
            </v-btn-toggle>
         </v-col>
      </v-row>
      <list-detail-tab-multicolumn-items
         v-if="isMulticolumn"
         ref="multicolumnItems"
         :listModel="listModel"
         :readonly="readonly"
         :translations="translations"
         @list-items-updated="onListItemsUpdated"
      ></list-detail-tab-multicolumn-items>
      <list-detail-tab-singlecolumn-items
         v-else
         :listModel="listModel"
         :readonly="readonly"
         :translations="translations"
      ></list-detail-tab-singlecolumn-items>
      <confirm-dialog-async
         ref="confirmDialog"
         :message="translateKey('listItemsTab.confirmListTypeChange', translations)"
      ></confirm-dialog-async>
   </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import ListDetailTabSinglecolumnItems from "./list-detail-tab-singlecolumn-items.vue";
import ListDetailTabMulticolumnItems from "./list-detail-tab-multicolumn-items.vue";
import { List, ListItem, TranslationPublicModel } from "@backend/api/pmToolApi";
import ConfirmDialogAsync, { ConfirmDialogResult } from "@components/Project/Shared/confirm-dialog-async.vue";
import ComponentBase from "@components/Shared/Base/component-base.vue";

@Component({
   name: "ListDetailTabItems",
   components: {
      ListDetailTabSinglecolumnItems,
      ListDetailTabMulticolumnItems,
      ConfirmDialogAsync,
   },
})
export default class ListDetailTabItems extends ComponentBase {
   @Prop({ required: true })
   listModel: List;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   pendingValue: boolean | null = null;

   get isMulticolumn(): boolean {
      return this.listModel.isMultiColumn;
   }

   set isMulticolumn(value: boolean) {
      this.listModel.isMultiColumn = value;
   }

   async updateIsMultiColumn(value: boolean) {
      if (this.listModel.items?.filter((x) => !x.disabled).length > 0) {
         if ((await this.getConfirmDialog().showDialogAsync()) !== ConfirmDialogResult.Ok) {
            return;
         }
         this.listModel.items = [];
         this.listModel.headers = [];
      }
      this.listModel.isMultiColumn = value;
   }

   get toggleMultiColumnLabel() {
      return this.isMulticolumn
         ? this.translateKey("listItemsTab.setMultipleColumns", this.translations)
         : this.translateKey("listItemsTab.setSingleColumn", this.translations);
   }

   async toggleMultiColumn(value: boolean) {
      if (this.listModel.items?.filter((x) => !x.disabled).length > 0) {
         if ((await this.getConfirmDialog().showDialogAsync()) !== ConfirmDialogResult.Ok) {
            this.isMulticolumn = !value;
            return;
         }
         this.listModel.items = [];
         this.listModel.headers = [];
         this.isMulticolumn = value;
         this.$refs.multicolumnItems?.clearItems();
      }
   }

   getConfirmDialog(): ConfirmDialogAsync {
      return this.$refs.confirmDialog as ConfirmDialogAsync;
   }

   onListItemsUpdated(listItems: ListItem[]) {
      this.$emit("list-items-updated", listItems);
   }
}
</script>

<style scoped></style>
