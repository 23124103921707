<template>
   <v-card>
      <v-card-title primary-title>{{ translateKey("contentTab.generalLabel", translations) }}</v-card-title>
      <v-card-text>
         <v-row>
            <v-col>
               <v-text-field
                  v-model="subContentBrickName"
                  color="error"
                  :label="translateKey('contentTab.nameFieldLabel', translations)"
                  :rules="nameRules"
                  counter
                  maxlength="80"
                  :readonly="!canEdit"
                  @blur="onNameBlur"
               />
            </v-col>
         </v-row>
         <v-row>
            <v-col>
               <v-text-field
                  v-model="subContentBrick.identifier"
                  color="error"
                  :label="translateKey('contentTab.identifierFieldLabel', translations)"
                  :rules="identifierRules"
                  counter
                  maxlength="80"
                  :readonly="!canEdit"
               />
            </v-col>
         </v-row>
         <v-row>
            <v-col>
               <v-autocomplete
                  v-model="dataModelDomains"
                  :label="translateKey('contentTab.domainsFieldLabel', translations)"
                  class="cb-z-index"
                  :readonly="!canEdit"
                  :loading="loadingDomains"
                  :items="domains"
                  item-text="displayText"
                  item-value="id"
                  :rules="domainRules"
                  chips
                  deletable-chips
                  hide-selected
                  multiple
               ></v-autocomplete>
            </v-col>
         </v-row>
         <v-row>
            <v-col class="py-0 px-3">
               <add-reference
                  v-model="subContentBrick.tags"
                  :entity="translateKey('contentTab.tagsFieldLabel', translations)"
                  :translate="false"
                  :apiEndpoint="getTags"
                  newItemRoute="/tag/new"
                  :hideNoData="false"
                  :createApiEndpoint="createTagApiCall"
                  itemDetailRoute="/tag/detail"
                  :disabled="!canEdit"
               ></add-reference>
            </v-col>
         </v-row>
         <v-row>
            <v-col class="d-flex pl-0 ml-0">
               <v-card-title primary-title>{{ translateKey("contentTab.fieldsLabel", translations) }}</v-card-title>
               <v-btn elevation="2" :disabled="!canEdit" class="mt-3" @click="showAddFieldDialog">
                  <v-icon color="success">mdi-plus</v-icon>
                  <span>{{ translateKey("button.add", translations) }}</span>
               </v-btn>
            </v-col>
         </v-row>
         <v-data-table
            :mobile-breakpoint="0"
            class="pmtool-table-overview"
            dense
            :headers="headerFields"
            :items="fields"
            hide-default-footer
            disable-pagination
            :no-data-text="translateKey('contentTab.noFieldsDefinedLabel', translations)"
            :custom-sort="customSort"
         >
            <template #item.name="{ item }">
               <v-text-field
                  v-model="item.name"
                  :rules="nameRules"
                  maxlength="80"
                  :readonly="!canEdit"
                  @blur="EventBus.$emit(Events.P2P, { eId: item.id })"
               ></v-text-field>
            </template>
            <template #item.identifier="{ item }">
               <identifier-field
                  v-model="item.identifier"
                  :readonly="!canEdit"
                  :source="item.name"
                  :eventId="item.id"
               ></identifier-field>
            </template>
            <template #item.type="{ item }">
               <div class="d-flex">
                  <v-autocomplete
                     v-model="item.type"
                     :items="fieldTypeComboItems"
                     :item-text="(item) => getDecoratorText(item, translations)"
                     item-value="value"
                     :readonly="!canEdit"
                  ></v-autocomplete>
               </div>
            </template>
            <template #item.isMandatory="{ item }">
               <v-switch
                  v-model="item.isMandatory"
                  title="Is Mandatory"
                  color="error"
                  class="pt-0 pb-0 pl-3 mt-2"
                  :disabled="!canEdit"
               />
            </template>
            <template #item.regularExpression="{ item }">
               <add-reference-single
                  :value="getRegularExpressionValue(item.regularExpressionId)"
                  entity="Formatting"
                  :apiEndpoint="() => getRegularExpressions(item)"
                  newItemRoute="/regularExpressions/new"
                  itemDetailRoute="/regularExpressions/detail"
                  :disabled="!canEdit || !isRegularExpressionEnabled(item)"
                  required
                  :sortDesc="true"
                  class="mr-3"
                  :showOnlyButton="true"
                  icon="mdi-regex"
                  @input="setRegularExpressionValue(item, $event)"
               ></add-reference-single>
            </template>
            <template #item.others="{ item }">
               <v-row v-if="requiresUnits(item)" dense>
                  <v-col cols="6">
                     <add-reference-single
                        :value="item.unitType"
                        entity="Unit Type"
                        :apiEndpoint="getUnitTypes"
                        newItemRoute="/unitTypes/new"
                        itemDetailRoute="/unitTypes/detail"
                        :disabled="!canEdit"
                        required
                        :headers="headerUnitTypes"
                        @input="onUnitTypeChanged(item, $event)"
                     ></add-reference-single>
                  </v-col>
                  <v-col cols="6">
                     <div class="d-flex flex-row">
                        <add-reference-single
                           v-if="item.unitType"
                           v-model="item.unit"
                           entity="Unit"
                           :apiEndpoint="() => getUnits(item)"
                           newItemRoute="/units/new"
                           itemDetailRoute="/units/detail"
                           :disabled="!canEdit"
                           required
                           :headers="headerUnits"
                        ></add-reference-single>
                        <div class="d-flex flex-column ml-2 unit-editable-switch">
                           <span class="text--secondary">Is Fixed</span>
                           <v-switch
                              v-model="item.isUnitFixed"
                              color="error"
                              class="pt-0 pb-0 mt-0"
                              hide-details
                              :disabled="!canEdit"
                           />
                        </div>
                     </div>
                  </v-col>
               </v-row>
            </template>
            <template #item.actions="{ item }">
               <v-btn
                  icon
                  elevation="2"
                  :disabled="!canMoveFieldUp(item) || !canEdit"
                  @click="onMoveFieldUpClick(item)"
               >
                  <v-icon color="blue">mdi-arrow-up</v-icon>
               </v-btn>
               <v-btn
                  icon
                  elevation="2"
                  :disabled="!canMoveFieldDown(item) || !canEdit"
                  @click="onMoveFieldDownClick(item)"
               >
                  <v-icon color="blue">mdi-arrow-down</v-icon>
               </v-btn>
               <v-btn icon elevation="2" :disabled="!canEdit" @click="onRemoveFieldClick(item)">
                  <v-icon color="error">mdi-delete</v-icon>
               </v-btn>
            </template>
         </v-data-table>
         <content-brick-conditions
            v-model="subContentBrick.condition"
            :conditionResultsIn.sync="subContentBrick.contentBrickConditionResultsIn"
            :conditionResultsDescription.sync="subContentBrick.contentBrickConditionResultsDescription"
            :fields="fields"
            :lists="lists"
            :readonly="!canEdit"
            :isFromProject="false"
            :translations="translations"
            :title="translateKey('contentTab.conditionsLabel', translations)"
         ></content-brick-conditions>
      </v-card-text>
      <content-brick-add-field-dialog
         :show-dialog="isAddFieldDialogShown"
         :fieldTypes="fieldTypeComboItems"
         :booleanLayouts="fieldBooleanLayoutComboItems"
         :units="units"
         :lists="listsReferences"
         :loadingDependencies="loadingDependencies"
         :getUnits="getUnits"
         :getUnitTypes="getUnitTypes"
         :getLists="getLists"
         :getRegularExpressions="getRegularExpressions"
         :headerUnits="headerUnits"
         :headerUnitTypes="headerUnitTypes"
         :fields="fields"
         :isUnitFixedDefaultValue="true"
         @addContentBrickField="onAddContentBrickField"
         @hideDetailDialog="hideAddFieldDialog"
         @reloadDependencies="onReloadDependencies"
      ></content-brick-add-field-dialog>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";

import VueQueryBuilder from "vue-query-builder";
import QueryBuilderGroup from "@components/ContentBricks/TabDetail/QueryBuilder/vuetify-group.vue";
import ContentBrickAddFieldDialog from "@components/ContentBricks/TabDetail/content-brick-add-field-dialog.vue";
import AddReference from "@components/Shared/add-reference.vue";
import AddReferenceSingle from "@components/Shared/add-reference-single.vue";
import ContentBrickConditions from "@components/ContentBricks/Shared/content-brick-conditions.vue";

import { ContentBrickFieldTypeDecorator } from "@models/shared/ContentBrickFieldTypeDecorator";
import { VueEditor } from "vue2-editor";
import {
   UsageTypeApi,
   TagApi,
   ContentBrickFieldType,
   ISubContentBrickDefinition,
   SubContentBrickFieldDefinition,
   ISubContentBrickFieldDefinition,
   ItemReference,
   EntityStatus,
   QueryOptionsOfUsageTypeFilterOptions,
   TagFilterOptions,
   IUnitReference,
   ContentBrickFieldDefinition,
   QueryOptionsOfListFilterOptions,
   ListFilterOptions,
   ListApi,
   List,
   ListType,
   UnitReference,
   FormattingType,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import ViewItem from "@models/view/ViewItem";
import ValidationRules, { ValidationRule } from "@models/shared/ValidationRules";
import DataModelUtils from "@utils/DataModelUtils";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import RegularExpressionUtils from "@utils/RegularExpressionUtils";
import { BooleanFieldLayoutDecorator } from "@models/shared/BooleanFieldLayoutDecorator";
import globalStore from "@backend/store/globalStore";
import DomainModel from "@models/domain/domainModel";
import UnitTypeCachedApi from "@backend/store/apiCache/UnitTypeCachedApi";
import UnitCachedApi from "@backend/store/apiCache/UnitCachedApi";
import RegularExpressionCachedApi from "@backend/store/apiCache/RegularExpressionCachedApi";
import IdentifierField from "@components/Shared/identifier-field.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import TagUtils from "@utils/TagUtils";

@Component({
   name: "SubContentBrickDetailTabContent",
   components: {
      VueQueryBuilder,
      QueryBuilderGroup,
      ContentBrickAddFieldDialog,
      AddReference,
      AddReferenceSingle,
      VueEditor,
      ContentBrickConditions,
      IdentifierField,
   },
})
export default class SubContentBrickDetailTabContent extends ComponentBase {
   loadingUnits: boolean = false;
   loadingLists: boolean = false;
   loadingDomains: boolean = false;
   loadingListReferences: boolean = false;
   loadingRegularExpressionReferences: boolean = false;
   units: IUnitReference[] = [];
   lists: List[] = [];
   listsReferences: ItemReference[] = [];
   regularExpressionReferences: ItemReference[] = [];
   domains: DomainModel[] | undefined = [];
   headerFields = [
      { text: "Name", value: "name", translationKey: "contentTab.nameHeader" },
      { text: "Identifier", value: "identifier", translationKey: "contentTab.identifierHeader" },
      {
         text: "Data Type",
         value: "type",
         class: "pmtool-table-header-fixed-xxsm",
         translationKey: "contentTab.dataTypeHeader",
      },
      {
         text: "Mandatory",
         value: "isMandatory",
         class: "pmtool-table-header-fixed-ssm",
         translationKey: "contentTab.mandatoryHeader",
      },
      {
         text: "Others",
         value: "others",
         class: "pmtool-table-header-fixed-xlg",
         sortable: false,
         translationKey: "contentTab.othersHeader",
      },
      {
         text: "",
         value: "regularExpression",
         class: "pmtool-table-header-fixed-xs",
         sortable: false,
         translationKey: "contentTab.regularExpressionHeader",
      },
      { text: "", value: "actions", class: "pmtool-table-header-fixed-actions", translationKey: "contentTab.actions" },
   ];
   headerUnits: ViewItem[] = [{ text: "Name", value: "displayText" }];
   headerUnitTypes: ViewItem[] = [{ text: "Name", value: "displayText" }];
   Events: any = Events;
   EventBus: any = EventBus;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Watch("translations")
   onTransaltionsChanged() {
      this.translateHeaders(this.headerFields, this.translations);
   }

   customSort(items: ContentBrickFieldDefinition[], index, isDesc) {
      const fieldName = index[0];
      const res1 = !isDesc[0] ? 1 : -1;
      const res2 = res1 * -1;
      if (fieldName === "type") {
         items.sort(function (a, b) {
            return ContentBrickFieldType[a.type] > ContentBrickFieldType[b.type] ? res1 : res2;
         });
      } else if (fieldName === "unit") {
         items.sort(function (a, b) {
            return a[fieldName]?.displayText > b[fieldName]?.displayText ? res1 : res2;
         });
      } else if (fieldName !== undefined) {
         items.sort(function (a, b) {
            return a[fieldName] > b[fieldName] ? res1 : res2;
         });
      }
      return items;
   }

   @Prop({ default: null })
   subContentBrick: ISubContentBrickDefinition | null;

   @Prop({ default: null })
   originalStatus: EntityStatus | null;

   @Prop({ default: false })
   isNew: boolean;

   get fieldBooleanLayoutComboItems() {
      return BooleanFieldLayoutDecorator.AllItems;
   }

   get fieldTypeComboItems() {
      return this.getSortedByDecoratorText(
         ContentBrickFieldTypeDecorator.AllItems.filter(
            (t) => t.value === ContentBrickFieldType.Integer || t.value === ContentBrickFieldType.Decimal
         ),
         this.translations
      );
   }

   get subContentBrickName(): string | undefined {
      return this.subContentBrick?.name;
   }

   set subContentBrickName(value: string | undefined) {
      if (this.subContentBrick) {
         this.$set(this.subContentBrick, "name", value?.trim());
      }
   }

   get fields(): ISubContentBrickFieldDefinition[] {
      return this.subContentBrick?.fields ? this.subContentBrick.fields : [];
   }

   requiresUnits(field: ISubContentBrickFieldDefinition): boolean {
      return new ContentBrickFieldTypeDecorator(field.type).hasUnits;
   }

   get canEdit(): boolean {
      return (
         this.originalStatus === EntityStatus.Draft &&
         (this.isNew || this.subContentBrick?.permissions?.update === true)
      );
   }

   get canEditUsageTypes(): boolean {
      return this.canEdit && (this.isNew || this.subContentBrick?.permissions?.updateUsageTypes === true);
   }

   mounted() {
      this.loadDependencies();
      this.translateHeaders(this.headerFields, this.translations);
   }

   onNameBlur() {
      if (!this.subContentBrick) return;

      if (
         this.subContentBrick.name &&
         this.subContentBrick.name.length > 0 &&
         this.subContentBrick.identifier === undefined
      ) {
         // when node name has value, and identifier was never set
         this.$set(
            this.subContentBrick,
            "identifier",
            DataModelUtils.generateNodeIdentifier(this.subContentBrick.name)
         ); // prefill identifier from name
      }
   }

   onUnitTypeChanged(item: ISubContentBrickFieldDefinition, unitType: ItemReference) {
      if (item.unit && item.unit.unitType!.id !== unitType.id) {
         item.unit = undefined;
      }

      this.$set(item, "unitType", unitType);
   }

   // -------- Fields -------------
   fieldNameLabel(type: ContentBrickFieldType): string {
      return type === ContentBrickFieldType.Document ? "Document name" : "Name";
   }

   canMoveFieldUp(field: ISubContentBrickFieldDefinition): boolean {
      return this.fields[0] !== field;
   }

   canMoveFieldDown(field: ISubContentBrickFieldDefinition): boolean {
      return this.fields[Math.max(0, this.fields.length - 1)] !== field;
   }

   onMoveFieldUpClick(field: ISubContentBrickFieldDefinition): void {
      this.moveFieldBySteps(field, -1);
   }

   onMoveFieldDownClick(field: ISubContentBrickFieldDefinition): void {
      this.moveFieldBySteps(field, 1);
   }

   onRemoveFieldClick(field: ISubContentBrickFieldDefinition) {
      var index = this.fields.indexOf(field);
      if (index > -1) {
         this.fields.splice(index, 1);
      }
   }

   moveFieldBySteps(field: ISubContentBrickFieldDefinition, steps: number): void {
      const fromIdx = this.fields.indexOf(field);

      if (fromIdx !== -1 && this.subContentBrick?.fields) {
         const toIdx = Math.min(this.fields.length, Math.max(0, fromIdx + steps));
         this.fields.splice(toIdx, 0, this.fields.splice(fromIdx, 1)[0]); // 1. remove&return moved element 2. insert it at target position
      }
   }

   // -------- Add Field Dialog -------------
   isAddFieldDialogShown: boolean = false;
   async onAddContentBrickField(field: ISubContentBrickFieldDefinition): Promise<void> {
      if (!this.subContentBrick) return;
      this.subContentBrick.fields!.push(new SubContentBrickFieldDefinition(field));
      this.hideAddFieldDialog();

      if (field.regularExpressionId) {
         await this.reloadMissingRegularExpressions(field.regularExpressionId);
      }
   }

   showAddFieldDialog(): void {
      this.isAddFieldDialogShown = true;
   }

   hideAddFieldDialog(): void {
      this.isAddFieldDialogShown = false;
   }

   onReloadDependencies() {
      this.loadDependencies();
   }

   get loadingDependencies(): boolean {
      return (
         this.loadingUnits || this.loadingLists || this.loadingListReferences || this.loadingRegularExpressionReferences
      );
   }

   // ------- domains -------
   get dataModelDomains(): number[] {
      return this.subContentBrick?.domains ?? [];
   }

   set dataModelDomains(dataModelDomains: number[]) {
      if (this.subContentBrick) {
         dataModelDomains.sort((a, b) => {
            return a > b ? 1 : -1;
         });
         this.subContentBrick.domains = dataModelDomains;
      }
   }

   // ------- validation -------
   nameRules: ValidationRule[] = [
      (v) => !!v?.trim() || "Name is required",
      (v) => v!.length <= 80 || "Name must be less than 80 characters",
   ];

   fieldIdentifierRules: ValidationRule[] = [
      ...ValidationRules.identifier,
      (v) => {
         var duplicateIdentifiers = this.fields
            .map((field) => field.identifier)
            .filter((identifier) => identifier === v);
         return duplicateIdentifiers.length < 2 || "Identifier is already used for another field";
      },
   ];

   identifierRules: ValidationRule[] = ValidationRules.identifier;
   domainRules: ValidationRule[] = [(v) => (!!v && v.length >= 1) || "Domain is required"];

   validate(): boolean {
      return true; //if ever Calc fields are implemented...
   }

   // -------- RegularExpression --------
   isRegularExpressionEnabled(field: ISubContentBrickFieldDefinition): boolean {
      return RegularExpressionUtils.isRegularExpressionEnabled(field?.type);
   }

   async loadFieldsRegularExpressions(id: string | undefined = undefined) {
      let regularExpressionIds: string[] = [];

      if (this.fields && this.fields.length > 0) {
         this.fields.forEach((field) => {
            if (field.regularExpressionId && regularExpressionIds.indexOf(field.regularExpressionId) === -1) {
               regularExpressionIds.push(field.regularExpressionId);
            }
         });
      }

      if (id && regularExpressionIds.indexOf(id) === -1) {
         regularExpressionIds.push(id);
      }

      if (regularExpressionIds.length > 0) {
         this.regularExpressionReferences = (await this.loadRegularExpressionsByIds(regularExpressionIds)) ?? [];
      } else {
         this.regularExpressionReferences = [];
      }
   }

   getRegularExpressionValue(regularExpressionId: string | undefined): ItemReference | undefined {
      if (!regularExpressionId) {
         return undefined;
      }
      var regularExpressionReference = this.regularExpressionReferences.find((r) => r.id === regularExpressionId);
      return (
         regularExpressionReference ??
         new ItemReference({
            id: regularExpressionId,
            code: undefined,
            displayText: undefined,
            entityStatus: undefined,
            lastModified: undefined,
         })
      );
   }

   async setRegularExpressionValue(
      field: ISubContentBrickFieldDefinition,
      regularExpressionReference: ItemReference | undefined
   ) {
      if (field && regularExpressionReference?.id) {
         await this.reloadMissingRegularExpressions(regularExpressionReference.id);
         this.$set(field, "regularExpressionId", regularExpressionReference.id);
      }
   }

   async reloadMissingRegularExpressions(regularExpressionId: string | undefined) {
      if (regularExpressionId && this.regularExpressionReferences.findIndex((r) => r.id === regularExpressionId) < 0) {
         // no regularExpression found, reload regularExpressions
         await this.loadFieldsRegularExpressions(regularExpressionId);
      }
   }

   // -------- API -------------
   async getTags(): Promise<ItemReference[]> {
      return await TagApi.getTagOfTypeTagReferences(
         new TagFilterOptions({
            domains: [globalStore.getDomain()],
         })
      );
   }

   async createTagApiCall(text: string): Promise<ItemReference> {
      return await TagUtils.getCreateTagApiCall(text, "Tag");
   }

   async getUsageTypes(): Promise<ItemReference[]> {
      let queryOptions = new QueryOptionsOfUsageTypeFilterOptions();
      var data = await UsageTypeApi.getUsageTypeReferences(queryOptions);
      return data.documents!;
   }

   async getUnits(field: ISubContentBrickFieldDefinition): Promise<UnitReference[] | undefined> {
      let res = await UnitCachedApi.getAllUnitReferences(EntityStatus.Active, field.unitType?.id);
      return res;
   }

   async getUnitTypes(): Promise<ItemReference[] | undefined> {
      let res = await UnitTypeCachedApi.getActiveUnitTypes();
      return res;
   }

   async getLists(): Promise<ItemReference[] | undefined> {
      let queryOptions = new QueryOptionsOfListFilterOptions();
      queryOptions.filter = new ListFilterOptions({
         entityStatus: EntityStatus.Active,
         entityCodes: undefined,
         excludeEntityCodes: undefined,
         lastVisited: undefined,
         createdBy: undefined,
         searchQuery: undefined,
         lastOnly: true,
         tags: undefined,
         searchIdentifiers: undefined,
         lastOnlyAnyStatus: false,
         type: ListType.List,
      });
      let res = await ListApi.queryAllListReferences(queryOptions);
      return res.documents;
   }

   async getRegularExpressions(
      field: ISubContentBrickFieldDefinition | undefined = undefined
   ): Promise<ItemReference[] | undefined> {
      try {
         const formattingType: FormattingType | undefined =
            field?.type === ContentBrickFieldType.Integer ||
            field?.type === ContentBrickFieldType.Decimal ||
            field?.type === ContentBrickFieldType.Calculated
               ? FormattingType.Number
               : undefined;

         let result = await RegularExpressionCachedApi.getRegularExpressionReferences(
            undefined,
            EntityStatus.Active,
            formattingType
         );
         return result;
      } catch (error) {
         this.notifyError(error, "load", "API SubContentBrickDefinition/Formatting");
      }
   }

   async loadRegularExpressionsByIds(ids: string[]): Promise<ItemReference[] | undefined> {
      this.loadingRegularExpressionReferences = true;
      try {
         let result = await RegularExpressionCachedApi.getRegularExpressionReferences(ids);
         return result ?? [];
      } catch (error) {
         this.notifyError(error, "load", "API SubContentBrickDefinition/Formatting");
      } finally {
         this.loadingRegularExpressionReferences = false;
      }
   }

   loadDependencies() {
      this.loadUnits();
      this.loadDomains();
      this.loadFieldsRegularExpressions();
   }

   async loadUnits(): Promise<void> {
      this.loadingUnits = true;
      try {
         // Call the API
         let units = await UnitCachedApi.getAllUnitReferences(EntityStatus.Active, undefined);
         // Process/Save data etc.
         this.units = units;
      } catch (error) {
         this.notifyError(error, "load", "API SubContentBrickDefinition/Units load error");
      }
      this.loadingUnits = false;
   }

   async loadDomains(): Promise<void> {
      this.loadingDomains = true;
      try {
         this.domains = await globalStore.getDomains();
      } catch (error) {
         this.notifyError(error, "load", "API SubContentBrickDefinition/Domains load error");
      }
      this.loadingDomains = false;
   }
}
</script>

<style scoped>
/* Vue Query Builder uses bootstrap */
@import "~bootstrap/dist/css/bootstrap.css";

.document-type-input {
   width: 277px;
}
</style>
<style>
/* hide arrow in chips input - there wont be any dropdowns */
.options-input .mdi-menu-down {
   display: none !important;
}

.unit-editable-switch {
   line-height: 22px;
}

td.text-start {
   padding-left: 0px !important;
   padding-right: 5px !important;
}

th.text-start {
   padding-left: 0px !important;
   padding-right: 0px !important;
}
</style>
