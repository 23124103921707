<template>
   <v-row dense align="start" class="fill-height pb-1 pt-3">
      <!-- DGL value field -->
      <template v-if="Util.isValueField(value)">
         <!-- Is hidden in task -->
         <v-col cols="auto">
            <v-switch
               v-model="value.isHiddenInTask"
               :readonly="readonly"
               :label="translateKey('contentTab.fieldsTable.hideInTaskLabel', translations)"
               color="error"
               class="value-field-expanded-component"
            ></v-switch>
         </v-col>
         <!-- Evaluation type -->
         <v-col cols="auto">
            <v-select
               v-model="value.evaluationType"
               :readonly="readonly"
               :label="translateKey('contentTab.fieldsTable.evaluationLabel', translations)"
               :item-text="(item) => getDecoratorText(item)"
               item-value="value"
               :items="DesignGuidelineFieldEvaluationDecorator.AllItems"
               :rules="evaluationRules"
               hide-details="auto"
               class="value-field-expanded-component"
            ></v-select>
         </v-col>
         <!-- Value -->
         <v-col cols="auto">
            <v-text-field
               v-if="isValueTextField(value)"
               v-model="value.value"
               label="Value"
               :rules="getRegexRules(value)"
               :readonly="readonly"
            ></v-text-field>
            <div v-if="isTextAreaField(value)" @click="showEditorModal(value)">
               <v-row align="center">
                  <v-col cols="12" class="html-container">
                     <div class="html-ellipsis value-field-text-area-type-input" v-html="value.value" />
                  </v-col>
               </v-row>
            </div>
            <div v-if="value.type === ContentBrickFieldType.Boolean">
               <v-checkbox
                  v-if="value.booleanLayout === BooleanFieldLayout.Checkbox"
                  v-model="value.value"
                  label="Value"
                  color="error"
                  :readonly="readonly"
               ></v-checkbox>
               <v-switch v-else v-model="value.value" label="Value" :readonly="readonly" color="error"></v-switch>
            </div>

            <v-text-field
               v-else-if="isValueNumberField(value)"
               v-model="value.value"
               :step="value.type === ContentBrickFieldType.Decimal ? '0.01' : '1'"
               type="number"
               label="Value"
               :readonly="readonly"
               :rules="getRegexRules(value)"
            ></v-text-field>

            <date-time-picker
               v-else-if="isValueDateTimeField(value)"
               v-model="value.value"
               label="Value"
               class="mr-auto flex-grow-0"
               :readonly="readonly"
               :clearable="!readonly"
               :date="value.type !== ContentBrickFieldType.Time"
               :time="value.type !== ContentBrickFieldType.Date"
            ></date-time-picker>

            <v-tooltip
               v-else-if="
                  value.type === ContentBrickFieldType.List ||
                  value.type === ContentBrickFieldType.MultiSelectList ||
                  value.type === ContentBrickFieldType.ComboBox
               "
               top
            >
               <template #activator="{}">
                  <multi-column-list-select
                     v-model="value.value"
                     :list="value.list"
                     :label="value.name"
                     :multiple="value.type === ContentBrickFieldType.MultiSelectList"
                  ></multi-column-list-select>
               </template>
               <span>{{ value.name }}</span>
            </v-tooltip>
            <template
               v-else-if="
                  value.type === ContentBrickFieldType.Document ||
                  value.type === ContentBrickFieldType.Image ||
                  value.type === ContentBrickFieldType.Video
               "
            >
               <div
                  v-if="!isNew"
                  ref="fileTypeFiled"
                  @dragover.prevent="onDragOver($event)"
                  @dragleave="onDragLeave($event)"
                  @drop.prevent="onFileDrop($event)"
               >
                  <template v-if="!isDragging">
                     <v-combobox
                        v-model="value.value"
                        label="Value"
                        :class="{ 'field-editable': readonly } + ' value-field-document-type-input mb-2'"
                        :readonly="true"
                        hide-details="auto"
                        :loading="isAttachmentUploading"
                        multiple
                        small-chips
                        append-icon=""
                        @mouseup.stop.prevent="!readonly && $refs.fileInput.$refs.input.click()"
                     >
                        <template #progress>
                           <v-progress-linear
                              v-if="!!uploading"
                              class="v-progress-linear--absolute"
                              style="height: 24px"
                              color="primary lighten-2"
                              :value="uploading && uploading.allFilesProgress * 100"
                              :indeterminate="uploading && uploading.isFinalizing"
                           >
                              <strong>{{ attachmentFieldHint }}</strong>
                           </v-progress-linear>
                        </template>
                        <template #append-outer>
                           <v-tooltip top>
                              <template #activator="{ on }">
                                 <div v-on="on">
                                    <v-file-input
                                       v-if="!readonly"
                                       ref="fileInput"
                                       label="File input"
                                       hide-input
                                       multiple
                                       :class="['pa-0', 'mt-0']"
                                       prepend-icon="mdi-file-upload"
                                       :accept="attachmentAcceptTypes"
                                       :disabled="value._isUnsavedNewField"
                                       @change="onUploadAttachments({ files: $event, field: value })"
                                    ></v-file-input>
                                 </div>
                              </template>
                              <span>Add Attachment</span>
                           </v-tooltip>
                        </template>
                        <template #selection="{ attrs, item }">
                           <v-chip
                              v-if="value.type === ContentBrickFieldType.Document && !attachmentsLoading"
                              v-bind="attrs"
                              small
                              :close="!readonly"
                              :disabled="isAttachmentLoading"
                              @click="onDownloadAttachmentClick(item)"
                              @mouseup.stop.prevent
                              @click:close="onDeleteAttachmentClick(item)"
                           >
                              {{ getAttachmentName(item) }}
                              <span class="grey--text ml-1">{{ getAttachmentSize(item) }}</span>
                           </v-chip>
                           <v-col
                              v-else-if="value.type === ContentBrickFieldType.Image"
                              cols="12"
                              xs="12"
                              sm="12"
                              md="12"
                              lg="6"
                              xl="4"
                              align-self="start"
                           >
                              <v-tooltip top>
                                 <template #activator="{ on, attrs }">
                                    <v-card flat tile v-bind="attrs" v-on="on">
                                       <v-img
                                          v-if="!mediaLoading"
                                          max-height="200"
                                          max-width="200"
                                          :src="mediaUrls[item]"
                                          @click="onDownloadAttachmentClick(item)"
                                          @mouseup.stop.prevent
                                       >
                                          <div class="mr-2 mt-2 d-flex justify-content-end">
                                             <attachment-delete-btn
                                                v-if="!readonly"
                                                @click="onDeleteAttachmentClick(item)"
                                             />
                                          </div>
                                          <template #placeholder>
                                             <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular
                                                   indeterminate
                                                   color="blue lighten-5"
                                                ></v-progress-circular>
                                             </v-row>
                                          </template>
                                       </v-img>
                                    </v-card>
                                 </template>
                                 <div @mouseup.stop.prevent>
                                    <template v-if="!attachmentsLoading">
                                       {{ getAttachmentName(item) }}
                                       <span class="grey--text ml-1">
                                          {{ getAttachmentSize(item) }}
                                       </span>
                                    </template>
                                    <template v-else>Loading...</template>
                                 </div>
                              </v-tooltip>
                           </v-col>
                           <v-col
                              v-else-if="value.type === ContentBrickFieldType.Video"
                              cols="12"
                              xl="6"
                              align-self="start"
                              class="pa-1"
                           >
                              <v-tooltip top>
                                 <template #activator="{ on, attrs }">
                                    <v-card flat tile v-bind="attrs" v-on="on">
                                       <video
                                          v-if="!mediaLoading"
                                          :src="mediaUrls[item]"
                                          class="w-100 h-100 object-fit-cover"
                                          controls
                                          preload="metadata"
                                          @mouseup.stop.prevent
                                       ></video>
                                       <v-row
                                          v-else
                                          class="fill-height ma-0 w-100 h-100 object-fit-cover"
                                          align="center"
                                          justify="center"
                                       >
                                          <v-progress-circular
                                             indeterminate
                                             color="blue lighten-5"
                                          ></v-progress-circular>
                                       </v-row>
                                       <attachment-delete-btn
                                          v-if="!readonly"
                                          absolute
                                          top
                                          right
                                          @click="onDeleteAttachmentClick(item)"
                                       />
                                       <attachment-download-btn
                                          absolute
                                          top
                                          left
                                          @click="onDownloadAttachmentClick(item)"
                                       />
                                    </v-card>
                                 </template>
                                 <div @mouseup.stop.prevent>
                                    <template v-if="!attachmentsLoading">
                                       {{ getAttachmentName(item) }}
                                       <span class="grey--text ml-1">
                                          {{ getAttachmentSize(item) }}
                                       </span>
                                    </template>
                                    <template v-else>Loading...</template>
                                 </div>
                              </v-tooltip>
                           </v-col>
                        </template>
                     </v-combobox>
                  </template>
                  <template v-else>
                     <div class="drop-area" :style="{ height: cbFieldDropAreaHeight }">
                        <span v-if="dragError" class="grey--text">
                           <v-icon class="red--text text--lighten-2">mdi-cancel</v-icon>
                           {{ dragError }}
                        </span>
                        <span v-else class="grey--text">Drop file here to upload</span>
                     </div>
                  </template>
               </div>
               <span v-else>Save Draft before working with Attachment values</span>
            </template>
         </v-col>
      </template>
      <!-- CB/DGL normal field -->
      <template v-else>
         <!-- Is mandatory -->
         <v-col cols="auto">
            <v-switch
               v-model="value.isMandatory"
               label="Is Mandatory"
               color="error"
               :disabled="readonly"
               class="value-field-expanded-component"
            />
         </v-col>
      </template>
      <confirm-delete-dialog-async ref="confirmDeleteDialog"></confirm-delete-dialog-async>
   </v-row>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import {
   ContentBrickTreeNodeTypeUtils as Util,
   ContentBrickFieldTypeUtils as FieldUtil,
   FieldDefinition,
} from "@models/shared/ContentBrickTreeNodeTypeUtils";
import {
   ContentBrickFieldType,
   DesignGuidelineValueFieldDefinition,
   BooleanFieldLayout,
   RegularExpression,
   ContentBrickDefinitionApi,
   AttachmentDownloadModel,
   AttachmentMetadata,
   ContentBrickDefinition,
   ListItem,
   TranslationPublicModel,
} from "@backend/api/pmToolApi";
import { DesignGuidelineFieldEvaluationDecorator } from "@models/shared/DesignGuidelineFieldEvaluationDecorator";
import RegularExpressionUtils from "@utils/RegularExpressionUtils";
import AttachmentDeleteBtn from "@components/Shared/attachment-delete-btn.vue";
import AttachmentDownloadBtn from "@components/Shared/attachment-download-btn.vue";
import AttachmentUtils, { AttachmentUploadInfo } from "@utils/AttachmentUtils";
import ContentBrickUtils from "@utils/ContentBrickUtils";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import AttachmentApi from "@backend/api/attachmentsApi";
import ConfirmDeleteDialogAsync from "@components/Shared/confirm-delete-dialog-async.vue";
import DateTimePicker from "@components/ContentBricks/Shared/field-date-time-picker.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import MultiColumnListSelect from "@components/Shared/multi-column-list-select.vue";
import { ValidationRule } from "@models/shared/ValidationRules";

@Component({
   name: "ContentBrickFieldDetail",
   components: {
      AttachmentDeleteBtn,
      AttachmentDownloadBtn,
      ConfirmDeleteDialogAsync,
      DateTimePicker,
      MultiColumnListSelect,
   },
})
export default class ContentBrickFieldDetail extends ComponentBase {
   // Caution component is mutating this prop
   @Prop({ required: true })
   value: FieldDefinition;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: false })
   isNew: boolean;

   @Prop({ required: true })
   hasChanges: boolean;

   @Prop({ default: () => [] })
   regularExpressions: RegularExpression[];

   @Prop({ required: true })
   contentBrick: ContentBrickDefinition;

   @Prop({ default: false })
   attachmentsLoading: boolean;

   @Prop({ default: () => {} })
   attachments: { [key: string]: AttachmentMetadata };

   @Prop({ default: false })
   mediaLoading: boolean;

   @Prop({ default: () => {} })
   mediaUrls: { [key: string]: string };

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   Util: any = Util;
   BooleanFieldLayout: any = BooleanFieldLayout;
   ContentBrickFieldType: any = ContentBrickFieldType;
   DesignGuidelineFieldEvaluationDecorator: any = DesignGuidelineFieldEvaluationDecorator;

   isOriginalDglField: boolean = false;

   routeNames: string[] = ["design-guideline-field-evaluation-decorator"];

   mounted() {
      this.isOriginalDglField = !!this.contentBrick?.designGuidelineValueFields?.some((f) => f.id === this.value.id);

      this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadRouteTranslations(this.routeNames);
      });
   }

   isValueTextField(field: FieldDefinition): boolean {
      return field.type === ContentBrickFieldType.TextBox;
   }

   isTextAreaField(field: FieldDefinition): boolean {
      return field.type === ContentBrickFieldType.TextArea;
   }

   isValueNumberField(field: FieldDefinition): boolean {
      return field.type === ContentBrickFieldType.Integer || field.type === ContentBrickFieldType.Decimal;
   }

   isValueDateTimeField(field: FieldDefinition): boolean {
      return (
         field.type === ContentBrickFieldType.Time ||
         field.type === ContentBrickFieldType.DateTime ||
         field.type === ContentBrickFieldType.Date
      );
   }

   isListField(field: FieldDefinition): boolean {
      return FieldUtil.isListField(field);
   }

   getListItems(field: FieldDefinition): ListItem[] {
      return field?.list?.items?.filter((li) => !li.disabled) ?? [];
   }

   // ----------- Attachments ------------------
   downloading: boolean = false;
   uploading: AttachmentUploadInfo | null = null;
   deleting: boolean = false;

   get attachmentAcceptTypes(): string | undefined {
      const docType = AttachmentUtils.getAttachmentTypeDecoratorFromFieldType(this.value.type);
      return docType.accept;
   }

   get isAttachmentLoading(): boolean {
      if (!AttachmentUtils.isAttachmentField(this.value.type)) return false;

      return this.downloading || !!this.uploading || this.deleting;
   }

   get isAttachmentUploading(): boolean {
      if (!AttachmentUtils.isAttachmentField(this.value.type)) return false;
      return !!this.uploading;
   }

   async onDownloadAttachmentClick(blobName: string): Promise<void> {
      var file = await this.downloadAttachment(blobName);
      if (file) {
         AttachmentUtils.downloadFile(file);
      }
   }

   get attachmentFieldHint(): string | null {
      if (!this.value?.id || !this.uploading) return null;

      const uploadInfo = this.uploading;
      let hint = "Uploading ";

      if (uploadInfo.filesCount > 1) {
         hint += `(${uploadInfo.doneFilesCount}/${uploadInfo.filesCount}) `;
      }

      hint += `file ${(uploadInfo.currentFileProgress * 100).toFixed(1)}% `;

      if (uploadInfo.isFinalizing) {
         hint += "saving to cloud";
      } else {
         hint += `@${AttachmentUtils.speedHumanReadable(uploadInfo.wmaSpeed)}`;
      }
      return hint;
   }

   async onDeleteAttachmentClick(blobName: string): Promise<void> {
      let res = await this.$refs["confirmDeleteDialog"].showDialogAsync(this.attachments[blobName]?.fileName);
      if (!res) {
         return;
      }

      var deleted = await this.deleteAttachmentAsync(this.contentBrick.id, blobName);

      // NOTE: commented out already in previous implementation
      if (deleted) {
         const hadChanges = this.hasChanges;
         delete this.attachments[blobName];
         (this.value as DesignGuidelineValueFieldDefinition).value =
            this.value.value?.filter((fv) => fv !== blobName) ?? [];
         // all values are duplicated in scriptField.value, update is as well
         // if (this.hasPassedValue(cbField)) {
         //    cbField.scriptField!.value = cbField.value?.slice();
         // }
         this.$emit("attachment-modified", hadChanges);
      }
   }

   async onUploadAttachments(uploadModel: {
      files: File[];
      field: DesignGuidelineValueFieldDefinition;
   }): Promise<void> {
      if (this.readonly || !uploadModel.files?.length) {
         return;
      }

      let fieldValue = uploadModel.field.value;
      let cb = this.contentBrick;
      let redirect = false;
      let newBlobs: string[] = [];

      if (this.isNewDesignGuidelineAttachementFieldChanges) {
         if (this.contentBrick?.id) {
            cb = await ContentBrickDefinitionApi.updateContentBrickDefinition(this.contentBrick.id, this.contentBrick);
         } else {
            this.$emit("disableRouteGuard");
            cb = await ContentBrickDefinitionApi.createContentBrickDefinition(this.contentBrick);
            redirect = true;
         }
      }

      try {
         let upload = new AttachmentUploadInfo(uploadModel.files);
         this.uploading = upload;

         for (var i = 0; i < uploadModel.files.length; i++) {
            const hadChanges = this.hasChanges;
            let newAttachment = await this.uploadAttachmentAsync(
               uploadModel.field.id,
               cb.id,
               uploadModel.files[i],
               upload
            );

            if (newAttachment) {
               this.attachments[newAttachment.blobName!] = newAttachment;
               if (!fieldValue) {
                  fieldValue = uploadModel.field.value = [];
               }
               fieldValue.push(newAttachment.blobName!);
               newBlobs.push(newAttachment.blobName!);
               this.$emit("attachment-modified", hadChanges);
            }
         }
      } finally {
         this.uploading = null;

         if (AttachmentUtils.isMediaField(uploadModel.field?.type)) {
            this.$emit("mediaUploaded", newBlobs);
         }

         if (redirect) {
            this.$router.replace({
               name: "design-guidelines-detail",
               params: {
                  id: cb.id,
               },
            });
         }
      }
   }

   async deleteAttachmentAsync(cbId: string, blobName: string) {
      try {
         this.deleting = true;
         let attachments = await ContentBrickDefinitionApi.deleteDesignGuidelineAttachment(
            cbId,
            this.value.id,
            blobName
         );

         var sucess = !!attachments;

         return sucess;
      } catch (error) {
         this.notifyError(error, "delete", "DesignGuideline Attachment");
      } finally {
         this.deleting = false;
      }
   }

   async uploadAttachmentAsync(
      fieldId: string,
      cbId: string,
      file: File,
      uploadInfo: AttachmentUploadInfo
   ): Promise<AttachmentMetadata | undefined> {
      try {
         let metadata = await AttachmentApi.uploadDesignGuidelinesAttachment(cbId, fieldId, file, uploadInfo);

         return metadata;
      } catch (error) {
         this.notifyError(error, "upload", "DesignGuideline Attachment");
      }
   }

   async downloadAttachment(blobName: string): Promise<AttachmentDownloadModel | undefined> {
      if (!blobName) {
         throw "Blob name is required";
      }

      try {
         this.downloading = true;

         // Call the API FileResponse
         let file = ContentBrickDefinitionApi.generateAttachmentDownloadUrl(this.contentBrick.id, blobName);
         return file;
      } catch (error) {
         this.notifyError(error, "download", "DesignGuideline Attachment");
      } finally {
         this.downloading = false;
      }
   }

   getAttachmentName(blobName: string): string | undefined {
      var metadata = this.attachments?.[blobName];
      return metadata?.fileName;
   }

   getAttachmentSize(blobName: string): string | undefined {
      var metadata = this.attachments?.[blobName];
      if (metadata) {
         return AttachmentUtils.sizeHumanReadable(metadata.size);
      }
   }

   get isNewDesignGuidelineAttachementFieldChanges() {
      return this.contentBrick?.designGuidelineValueFields?.some(
         (x) => AttachmentUtils.isAttachmentField(x.type) && !this.isOriginalDglField
      );
   }

   // ----------- File drag n drop ------------------
   isDragging: boolean = false;
   dragError: string | null = null;
   cbFieldDropAreaHeightValue: number = 52;

   get cbFieldDropAreaHeight(): string {
      return this.cbFieldDropAreaHeightValue.toString() + "px";
   }

   changeFieldDropAreaHeight() {
      // Set drop-area height according selected cbFileFiled height
      if (!this.isDragging && this.$refs?.fileTypeFiled?.clientHeight) {
         const currentHeight = this.$refs.fileTypeFiled.clientHeight;

         if (currentHeight !== this.cbFieldDropAreaHeightValue) {
            this.cbFieldDropAreaHeightValue = currentHeight;
         }
      }
   }

   onDragOver(event: any) {
      if (this.readonly) {
         event.dataTransfer.dropEffect = "none";
         return;
      }

      if (this.value["_isUnsavedNewField"]) {
         this.dragError = "Save before uploading Attachments";
      }

      this.changeFieldDropAreaHeight();

      event.dataTransfer.dropEffect = this.dragError ? "none" : "copy";

      if (!this.isDragging) {
         this.isDragging = true;
      }
   }

   onDragLeave(event: any) {
      this.isDragging = false;
      this.dragError = null;
   }

   onFileDrop(event: any) {
      this.isDragging = false;
      this.dragError = null;
      if (event.dataTransfer.files?.length) {
         this.onUploadAttachments({
            files: [...event.dataTransfer.files],
            field: this.value as DesignGuidelineValueFieldDefinition,
         });
      }
   }

   // ------ Rules ------
   get evaluationRules(): ValidationRule<unknown>[] {
      const self = this;
      return [() => ContentBrickUtils.validateEvaluationType(self.contentBrick, self.value)];
   }

   getRegexRules(field: DesignGuidelineValueFieldDefinition): ValidationRule<unknown>[] {
      return [() => this.validateFieldValueByRegex(field)];
   }

   // Returns True when validation of the specific designGuideLineValue field type is enabled
   isDesignGuideLineFieldRegexValidationEnabled(field: DesignGuidelineValueFieldDefinition): boolean {
      return field && (this.isValueNumberField(field) || this.isValueTextField(field));
   }

   // Validates designGuideLineValueField value using regularExpression
   validateFieldValueByRegex(field: DesignGuidelineValueFieldDefinition): boolean | string {
      if (this.isDesignGuideLineFieldRegexValidationEnabled(field) && field.regularExpressionId) {
         if (field.value?.length == 0) return true;

         const regularExpression = this.regularExpressions.find((r) => r.id === field.regularExpressionId);
         if (regularExpression) {
            return RegularExpressionUtils.validate(
               field.value,
               regularExpression.validationRegex?.regex,
               regularExpression.validationRegex?.flags
            )
               ? true
               : RegularExpressionUtils.regexNotMatchMessage;
         } else {
            return false;
         }
      } else {
         // If field does not have regularExpression
         return true;
      }
   }
}
</script>

<style scoped>
.document-type-input {
   width: 277px;
}

.value-field-document-type-input {
   width: 400px;
}

.value-field-text-area-type-input {
   width: 150px;
}

.value-field-expanded-component {
   padding-right: 30px;
}
</style>
