<template>
   <div class="d-inline field-dgl-label">
      <span class="field-label">{{ fieldLabel }}</span>
      <div v-if="designGuidelineNodeResult" class="d-inline-flex dgl-icon-container pl-1">
         <v-tooltip bottom>
            <template #activator="{ on }">
               <v-badge class="mr-2" overlap bottom color="transparent" offset-x="9">
                  <v-icon color="purple" v-on="on">mdi-code-greater-than-or-equal</v-icon>
                  <template #badge>
                     <v-icon :color="dglContionCheckColor" v-on="on">{{ dglContionIcon }}</v-icon>
                  </template>
               </v-badge>
            </template>
            <span>
               {{ translateKey("taskDetail.fieldConditionResult", translations) }}:
               {{ translateKey(dglConditionResultKey, translations) }}
            </span>
         </v-tooltip>
         <v-tooltip bottom>
            <template #activator="{ on }">
               <v-badge
                  class="mr-2"
                  overlap
                  bottom
                  color="error lighten-3"
                  offset-x="9"
                  :value="!!designGuidelineNodeResult.manualResultNote"
               >
                  <v-btn
                     color="error"
                     class="manual-result-note-btn"
                     icon
                     small
                     v-on="on"
                     @click.stop.prevent="onEditNote"
                  >
                     <v-icon>mdi-account-details</v-icon>
                  </v-btn>
                  <template #badge>
                     <span color="error" class="badge-interactive" v-on="on" @click.stop.prevent="onEditNote">A</span>
                  </template>
               </v-badge>
            </template>
            <span v-html="manualResultNoteTooltip"></span>
         </v-tooltip>
      </div>
   </div>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import DesignGuidelineResultDecorator from "@models/shared/DesignGuidelineResultDecorator";
import { DesignGuidelineNodeResult, TranslationPublicModel } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";

@Component({
   components: {},
})
export default class FieldValueLabel extends ComponentBase {
   @Prop({ default: null })
   field: any;

   @Prop({ default: null })
   fieldLabel: string | null;

   @Prop({ default: null })
   designGuidelineNodeResult: DesignGuidelineNodeResult | null;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   get dglConditionResultKey(): string | undefined {
      if (!this.designGuidelineNodeResult) return undefined;
      return DesignGuidelineResultDecorator.get(this.designGuidelineNodeResult?.conditionResult).translationKey;
   }

   get dglContionIcon(): string | undefined {
      if (!this.designGuidelineNodeResult) return undefined;
      return DesignGuidelineResultDecorator.get(this.designGuidelineNodeResult?.conditionResult).icon;
   }

   get dglContionCheckColor(): string | undefined {
      if (!this.designGuidelineNodeResult) return undefined;
      return DesignGuidelineResultDecorator.get(this.designGuidelineNodeResult?.conditionResult).color;
   }

   get manualResultNoteTooltip(): string | undefined {
      let tooltip = this.translateKey("taskDetail.fieldManualResultNote", this.translations);
      if (this.designGuidelineNodeResult?.manualResultNote) {
         tooltip += `:<br/>${this.designGuidelineNodeResult.manualResultNote}`;
      }
      return tooltip;
   }

   onEditNote() {
      this.$emit("edit-manual-result-note", {
         field: this.field,
         designGuidelineNodeResult: this.designGuidelineNodeResult,
      });
   }
}
</script>
<style lang="scss">
.field-dgl-label .field-label {
   overflow: hidden;
   pointer-events: none;
}

// adjust 1px to better visually align the icons
.v-btn.manual-result-note-btn {
   padding-top: 1px;
}
</style>
<style lang="scss">
.field-value {
   label.v-label {
      // move interactivity and hiding logic to child, the actual label
      overflow: hidden;
      pointer-events: all;
      z-index: 1;
      text-overflow: ellipsis;

      &.v-label--active {
         overflow: visible;
      }
   }

   .field-dgl-label {
      .v-btn--icon.v-size--small {
         width: 20px;
         height: 20px;
      }

      .v-badge {
         top: -2px;

         .v-badge__badge {
            padding: 0;

            .v-icon {
               font-size: 16px !important;
               background-color: rgb(255, 255, 255, 0.75);
               border-radius: 50%;
            }

            span {
               font-size: 16px !important;
            }
         }
      }
   }
}
</style>
