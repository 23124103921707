<template>
   <v-dialog v-if="showDialog && fieldCalculation" v-model="showDialog" width="75%" persistent>
      <v-card>
         <v-card-title primary-title>
            <span>{{ title }}</span>
            <v-spacer></v-spacer>
            <v-btn class="red--text" icon @click="clickClose">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <field-calculation
               v-model="fieldCalculation"
               :title="title"
               :fieldReferences="calculatableFields"
               :readonly="disabled"
            ></field-calculation>
         </v-card-text>

         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" text @click="onSaveClicked()">{{ saveButtonTitle }}</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { IContentBrickFieldDefinition, FieldCalculationDefinition } from "@backend/api/pmToolApi";
import CodeEditor from "@components/Shared/code-editor.vue";
import FieldCalculation from "@components/ContentBricks/Shared/field-calculation.vue";
import ContentBrickUtils, { IFieldCalculationReference } from "@utils/ContentBrickUtils";
import { cloneDeep } from "lodash";

@Component({
   name: "ContentBrickCalculationDialog",
   components: {
      CodeEditor,
      FieldCalculation,
   },
})
export default class ContentBrickCalculationDialog extends Vue {
   @Prop({ default: false })
   showDialog: boolean;

   @Prop({ default: null })
   contentBrickField: IContentBrickFieldDefinition;

   @Prop({ default: () => [] })
   contentBrickFields: IContentBrickFieldDefinition[];

   @Prop({ default: false })
   disabled: boolean;

   fieldCalculation: FieldCalculationDefinition | null = null;

   @Watch("showDialog", { deep: true })
   onValueChanged(): void {
      if (this.showDialog) {
         this.fieldCalculation = this.contentBrickField.fieldCalculation
            ? new FieldCalculationDefinition(cloneDeep(this.contentBrickField.fieldCalculation))
            : new FieldCalculationDefinition();
      }
   }

   get calculatableFields(): IFieldCalculationReference[] {
      return ContentBrickUtils.getCalculatableContentBrickFields(this.contentBrickField, this.contentBrickFields);
   }

   get saveButtonTitle(): string {
      return this.disabled ? "Close" : "Save";
   }

   get title(): string {
      return `Calculated field ${this.contentBrickField.name}`;
   }

   hideDetailDialog(): void {
      this.$emit("hideDialog");
   }

   clickClose(): void {
      this.hideDetailDialog();
   }

   onSaveClicked(): void {
      if (!this.disabled) {
         this.$emit("updateCalculation", this.fieldCalculation!);
      }
      this.hideDetailDialog();
   }
}
</script>
