import { render, staticRenderFns } from "./field-value.vue?vue&type=template&id=793c00c6&scoped=true"
import script from "./field-value.vue?vue&type=script&lang=ts"
export * from "./field-value.vue?vue&type=script&lang=ts"
import style0 from "./field-value.vue?vue&type=style&index=0&id=793c00c6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793c00c6",
  null
  
)

export default component.exports