<template>
   <v-dialog v-model="showDialog" width="500" persistent>
      <v-card>
         <v-card-title primary-title>
            <span>Confirm</span>
         </v-card-title>
         <v-card-text>
            <v-row>
               <v-col>You have modified the item. Either save, discard changes of cancel to continue editing.</v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text" outlined @click="cancelClick">Cancel</v-btn>
            <v-btn class="red--text" outlined @click="discardClick">Discard changes</v-btn>
            <v-btn
               v-shortkey.once="['ctrl', 's']"
               :disabled="!canSave"
               class="error"
               text
               @shortkey="
                  () => {
                     if (canSave) saveClick();
                  }
               "
               @click="saveClick"
            >
               Save changes
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import ConfirmCloseDialogResult from "@models/shared/ConfirmCloseDialogResult";

@Component({
   name: "ConfirmCloseDialogAsync",
   components: {},
})
export default class ConfirmCloseDialogAsync extends Vue {
   showDialog: boolean = false;
   resolve: (result: ConfirmCloseDialogResult) => void;

   @Prop({ default: true })
   canSave: boolean;

   async showDialogAsync(): Promise<ConfirmCloseDialogResult> {
      return new Promise<ConfirmCloseDialogResult>((resolve) => {
         this.showDialog = true;
         this.resolve = resolve;
      });
   }

   cancelClick() {
      this.showDialog = false;
      this.resolve?.(ConfirmCloseDialogResult.Cancel);
   }

   discardClick() {
      this.showDialog = false;
      this.resolve?.(ConfirmCloseDialogResult.Discard);
   }

   saveClick() {
      this.showDialog = false;
      this.resolve?.(ConfirmCloseDialogResult.Save);
   }
}
</script>
