<template>
   <div style="display: contents">
      <v-row>
         <v-col>
            <h6>
               <span>Queries</span>
               <v-menu open-on-hover offset-y>
                  <template #activator="{ on, attrs }">
                     <v-btn class="mx-6" v-bind="attrs" elevation="2" :disabled="readonly" v-on="on">
                        <v-icon color="success">mdi-plus</v-icon>
                        <span>Add</span>
                     </v-btn>
                  </template>
                  <v-list>
                     <v-list-item>
                        <v-btn text block @click="onAddQueryClick(ProjectDataModelType.DomainDataModel)">
                           <v-icon color="success">mdi-database-outline</v-icon>
                           <span>Domain Data Model</span>
                        </v-btn>
                     </v-list-item>
                     <v-list-item>
                        <v-btn text block @click="onAddQueryClick(ProjectDataModelType.ProcessDataModel)">
                           <v-icon color="success">mdi-cog-outline</v-icon>
                           <span>Process Data Model</span>
                        </v-btn>
                     </v-list-item>
                  </v-list>
               </v-menu>
            </h6>
         </v-col>
      </v-row>
      <v-row>
         <v-col>
            <v-data-table
               class="pmtool-table-overview query-table"
               :mobile-breakpoint="0"
               dense
               :headers="headerQueries"
               :items="queries"
               hide-default-footer
               disable-pagination
               no-data-text="No queries defined. You can add queries via the 'Add' button"
            >
               <template #item.name="{ item }">
                  <v-text-field
                     v-model="item.name"
                     :rules="nameRules"
                     :readonly="readonly"
                     maxlength="80"
                  ></v-text-field>
               </template>
               <template #item.dataModelType="{ item }">
                  <v-tooltip v-if="item.dataModelType === ProjectDataModelType.DomainDataModel" top>
                     <template #activator="{ on }">
                        <v-icon class="grey--text mt-4" v-on="on">mdi-database-outline</v-icon>
                     </template>
                     <span>Domain Data Model</span>
                  </v-tooltip>
                  <v-tooltip v-else-if="item.dataModelType === ProjectDataModelType.ProcessDataModel" top>
                     <template #activator="{ on }">
                        <v-icon class="grey--text mt-4" v-on="on">mdi-cog-outline</v-icon>
                     </template>
                     <span>Process Data Model</span>
                  </v-tooltip>
               </template>
               <template #item.ignoreFailStateResults="{ item }">
                  <v-switch
                     v-if="item.dataModelType === ProjectDataModelType.ProcessDataModel"
                     v-model="item.ignoreFailStateResults"
                     color="error"
                  />
               </template>
               <template #item.query="{ item }">
                  <code-editor v-model="item.query" language="javascript" class="mt-2" :readonly="readonly" />
               </template>
               <template #item.actions="{ item }">
                  <v-tooltip :disabled="canDeleteQuery(item)" top>
                     <template #activator="{ on }">
                        <div v-on="on">
                           <v-btn
                              icon
                              class="mt-1"
                              :disabled="readonly || !canDeleteQuery(item)"
                              elevation="2"
                              @click="onRemoveQueryClick(item)"
                           >
                              <v-icon color="error">mdi-delete</v-icon>
                           </v-btn>
                        </div>
                     </template>
                     <span>Query is used in formula</span>
                  </v-tooltip>
               </template>
            </v-data-table>
         </v-col>
      </v-row>
      <v-row>
         <v-col cols="12">
            <code-editor
               ref="editor"
               v-model="value.formula"
               class="mt-2"
               :readonly="readonly"
               label="Calculation formula"
               :queries="queryNames"
            />
         </v-col>
         <v-col cols="12">
            <v-btn
               v-for="calcField in sortedFieldReferences"
               :key="calcField.id"
               @click="onInsertFieldClick(calcField)"
            >
               {{ calcField.name || "Unknown Field" }}
            </v-btn>
            <v-btn
               v-for="fieldQuery in sortedQueryReferences"
               :key="fieldQuery.id"
               @click="onInsertQueryClick(fieldQuery)"
            >
               {{ fieldQuery.name || "Unknown Field" }}
            </v-btn>
         </v-col>
      </v-row>
   </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import {
   IGateConditionQuery,
   IFieldCalculationDefinition,
   ProjectDataModelType,
   GateConditionQuery,
} from "@backend/api/pmToolApi";
import CodeEditor from "@components/Shared/code-editor.vue";
import { IFieldCalculationReference } from "@utils/ContentBrickUtils";
import { Guid } from "guid-typescript";
import ViewItem from "@models/view/ViewItem";
import ValidationRules, { ValidationRule } from "@models/shared/ValidationRules";

@Component({
   name: "FieldCalculation",
   components: { CodeEditor },
})
export default class FieldCalculation extends Vue {
   @Prop({ required: true })
   value: IFieldCalculationDefinition;

   @Prop({ default: "Calculated field" })
   title: string;

   @Prop({ default: () => [] })
   fieldReferences: IFieldCalculationReference[];

   @Prop({ default: false })
   readonly: boolean;
   ProjectDataModelType: any = ProjectDataModelType;

   get sortedFieldReferences(): IFieldCalculationReference[] {
      return this.fieldReferences.sort((a, b) => this.compareName(a.name, b.name));
   }

   get sortedQueryReferences(): IGateConditionQuery[] {
      return this.value.queries!.filter((q) => q.name).sort((a, b) => this.compareName(a.name, b.name));
   }

   get queryNames(): string[] {
      return this.value.queries!.filter((q) => q.name).map((q) => q.name!);
   }

   compareName(a: string | null | undefined, b: string | null | undefined): number {
      if (a === undefined || a === null) {
         return 1;
      } else if (b === undefined || b === null) {
         return -1;
      }
      return a.localeCompare(b);
   }

   onInsertFieldClick(addedField: IFieldCalculationReference): void {
      this.value.formula += addedField.identifier;
      this.$refs.editor.focusEditor();
   }

   onInsertQueryClick(addedQuery: IGateConditionQuery): void {
      if (!addedQuery.name) return;

      this.value.formula += addedQuery.name;
      this.$refs.editor.focusEditor();
   }

   onAddQueryClick(type: ProjectDataModelType) {
      this.value.queries ??= [];
      this.value.queries.push(
         GateConditionQuery.fromJS({
            id: Guid.create().toString(),
            dataModelType: type,
            ignoreFailStateResults: type === ProjectDataModelType.ProcessDataModel ? true : undefined,
         })
      );
   }

   // ----- Queries ----------
   get headerQueries(): ViewItem[] {
      return [
         { text: "Name", value: "name", class: "pmtool-table-header-fixed-lg", align: "left" },
         { text: "Type", value: "dataModelType", class: "pmtool-table-header-fixed-xs" },
         { text: "Ignore Fail Results", value: "ignoreFailStateResults", width: 125 },
         { text: "JSONPath query", value: "query", align: "left" },
         {
            text: "",
            value: "actions",
            sortable: false,
            filterable: false,
            class: "pmtool-table-header-fixed-xs",
         },
      ];
   }

   get queries() {
      return this.value?.queries ?? [];
   }

   canDeleteQuery(query: GateConditionQuery) {
      const isInUse = this.value.formula?.includes(query.name!);
      return !isInUse;
   }

   onRemoveQueryClick(query: GateConditionQuery) {
      if (this.value?.queries) {
         this.value.queries = this.value.queries.filter((i) => i !== query);
      }
   }

   // ------- validation -------
   nameRules: ValidationRule[] = [
      ...ValidationRules.identifier,
      (v) => {
         var fieldIdentifierCount = this.fieldReferences
            .map((field) => field.identifier)
            .filter((identifier) => identifier === v).length;
         var queryNameCount = this.value.queries!.map((query) => query.name).filter((name) => name === v).length;
         return fieldIdentifierCount + queryNameCount < 2 || "Identifier is already used for another field/query";
      },
   ];
}
</script>
<style>
.query-table td {
   vertical-align: top;
}

.query-table .code-editor-title {
   display: none;
}

.query-table .code-editor {
   min-height: 35px;
   font-size: 16px;
}
</style>
