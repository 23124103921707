<template>
   <v-dialog :value="showDialog" width="500" persistent>
      <v-card>
         <v-card-title primary-title>
            <span>{{ title }}</span>
         </v-card-title>
         <v-card-text>
            <v-row>
               <v-col>
                  {{ message }}
               </v-col>
            </v-row>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="red--text" outlined @click="cancelClick">
               {{ cancelLabel }}
            </v-btn>
            <v-btn class="error" text @click="okClick">
               {{ okLabel }}
            </v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
   name: "ConfirmDialogAsync",
   components: {},
})
export default class ConfirmDialogAsync extends Vue {
   showDialog: boolean = false;
   resolve: (result: ConfirmDialogResult) => void;

   @Prop({ default: "Confirm" })
   title: string;

   @Prop({ default: "Confirm action?" })
   message: string;

   @Prop({ default: "Cancel" })
   cancelLabel: string;

   @Prop({ default: "Confirm" })
   okLabel: string;

   async showDialogAsync(): Promise<ConfirmDialogResult> {
      return new Promise<ConfirmDialogResult>((resolve) => {
         this.showDialog = true;
         this.resolve = resolve;
      });
   }

   cancelClick() {
      this.showDialog = false;
      this.resolve?.(ConfirmDialogResult.Cancel);
   }

   okClick() {
      this.showDialog = false;
      this.resolve?.(ConfirmDialogResult.Ok);
   }
}

enum ConfirmDialogResult {
   Cancel = 0,
   Ok = 1,
}

export { ConfirmDialogAsync, ConfirmDialogResult };
</script>
