<template>
   <div v-if="!loading && contentBricks">
      <div id="printable" class="container">
         <div v-for="contentBrick in contentBricks" :key="contentBrick.id">
            <!-- Title -->
            <div class="row mb-5">
               <div class="col">
                  <h4>
                     <span>{{ contentBrick.code }}</span>
                     <span>{{ contentBrick.name }}</span>
                  </h4>
               </div>
            </div>

            <!-- Description -->
            <div class="row mb-5">
               <div class="col">
                  <div v-html="contentBrick.description?.value"></div>
               </div>
            </div>

            <!-- Tags -->
            <div v-if="contentBrick.tags && contentBrick.tags.length > 0" class="row mb-5">
               <div class="col">
                  <export-pdf-design-guideline-tags
                     :contentBrick="contentBrick"
                     :translations="routeTranslations"
                  ></export-pdf-design-guideline-tags>
               </div>
            </div>

            <!-- Fields -->
            <div class="row mb-5">
               <div class="col">
                  <export-pdf-design-guideline-fields
                     :contentBrick="contentBrick"
                     :translations="routeTranslations"
                     :hasValueFields="hasValueFields(contentBrick)"
                     :mediaUrls="mediaUrls"
                     @loaded="onFieldsLoaded()"
                  ></export-pdf-design-guideline-fields>
               </div>
            </div>

            <!-- Metadata -->
            <div v-if="hasValueFields(contentBrick)" class="row mb-5">
               <div class="col">
                  <export-pdf-design-guideline-metadata
                     :contentBrick="contentBrick"
                     :translations="routeTranslations"
                  ></export-pdf-design-guideline-metadata>
               </div>
            </div>

            <!-- History -->
            <div class="row mb-5">
               <div class="col">
                  <export-pdf-design-guideline-history
                     :contentBrick="contentBrick"
                     :translations="routeTranslations"
                  ></export-pdf-design-guideline-history>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script lang="ts">
import { ContentBrickDefinition } from "@backend/api/pmToolApi";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { Component, Prop } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import ExportPdfDesignGuidelineFields from "@components/ContentBricks/Export/export-pdf-design-guideline-fields.vue";
import ExportPdfDesignGuidelineMetadata from "@components/ContentBricks/Export/export-pdf-design-guideline-metadata.vue";
import ExportPdfDesignGuidelineHistory from "@components/ContentBricks/Export/export-pdf-design-guideline-history.vue";
import ExportPdfDesignGuidelineTags from "@components/ContentBricks/Export/export-pdf-design-guideline-tags.vue";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";

@Component({
   name: "ExportDesignGuideLineContainer",
   components: {
      ExportPdfDesignGuidelineFields,
      ExportPdfDesignGuidelineMetadata,
      ExportPdfDesignGuidelineHistory,
      ExportPdfDesignGuidelineTags,
   },
})
export default class ExportDesignGuidelineContainer extends ComponentBase {
   @Prop({ required: true })
   contentBricks!: ContentBrickDefinition[];

   @Prop({ required: true })
   mediaUrls: { [key: string]: string };

   loading: boolean = true;
   routeNames: string[] = ["dgl-export-pdf", "content-brick-field-type-decorator"];

   hasValueFields(contentBrick: ContentBrickDefinition): boolean {
      return ContentBrickTypeDecorator.get(contentBrick.type).hasValueFields;
   }

   async mounted() {
      await this.loadTranslations();
      EventBus.$on(Events.LanguageChanged, this.loadTranslations);
   }

   async loadTranslations() {
      this.loading = true;
      await this.loadRouteTranslations(this.routeNames);
      this.loading = false;
   }

   loadedFieldsCounter: number = 0;
   onFieldsLoaded() {
      this.loadedFieldsCounter++;
      if (this.loadedFieldsCounter == this.contentBricks.length) {
         this.print();
      }
   }

   async print() {
      let contents = document.getElementById("printable").innerHTML;
      let iFrame = document.createElement("iframe");
      let localStylesheet = '<link rel="stylesheet" href="/css/export-pdf.css" />';

      iFrame.name = "iFrame";
      iFrame.style.position = "absolute";
      iFrame.style.top = "-1000000px";

      document.body.appendChild(iFrame);

      let frameDoc = iFrame.contentWindow
         ? iFrame.contentWindow.document
         : iFrame.contentDocument.document || iFrame.contentDocument;

      frameDoc.open();
      frameDoc.write(`
        <html lang="en">
            <head>
                ${localStylesheet}
                <title>
                  ${
                     this.contentBricks.length == 1
                        ? this.contentBricks[0].code + " " + this.contentBricks[0].name
                        : "Design Guidelines export"
                  }
                </title>
            </head>
            <body>
                ${contents}
            </body>
        </html>
    `);
      frameDoc.close();

      // Wait for stylesheets to load
      await new Promise((resolve) => {
         let interval = setInterval(() => {
            if (iFrame.contentWindow.document.readyState === "complete") {
               clearInterval(interval);
               resolve();
               iFrame.contentWindow.focus();
               iFrame.contentWindow.print();
               document.body.removeChild(iFrame);
               this.$emit("export-done");
            }
         }, 50);
      });
   }
}
</script>

<style scoped></style>
