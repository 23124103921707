<template>
   <div>
      <!-- Multiple selectable (with pills) autocomplete -->
      <template v-if="showOnlyButton">
         <v-btn tile color="error" elevation="2" icon ui-test-data="upload-btn" @click="onOpenAddDialogClick">
            <v-icon>{{ icon }}</v-icon>
         </v-btn>
      </template>
      <template v-else>
         <v-autocomplete
            v-if="maxReferences > 1"
            ref="autoComplete"
            :key="autocompleteReloadKey"
            v-model="vModel"
            :label="selectLabel"
            :items="autocompleteItems"
            :search-input.sync="search"
            :loading="loading"
            item-text="displayText"
            return-object
            :multiple="true"
            hide-selected
            :hide-no-data="hideNoData"
            :readonly="disabled"
            :rules="rules"
            color="error"
            ui-test-data="autocomplete-input"
            @click="onAutocompleteClicked"
            @focus="onAutocompleteFocused"
            @dblclick="onOpenAddDialogClick"
            @keyup.enter="createNewEntityAndSet()"
         >
            <template v-if="$scopedSlots['autocomplete-item']" #item="data">
               <slot name="autocomplete-item" v-bind="data" />
            </template>
            <template #selection="{ item }">
               <v-tooltip top :disabled="readOnlyItemReason?.(item) === undefined">
                  <template #activator="{ on, attrs }">
                     <v-chip
                        v-bind="attrs"
                        :key="item.id"
                        :close="canRemoveItem(item)"
                        class="ma-1"
                        v-on="on"
                        @click:close="removeItem(item)"
                     >
                        {{ item.displayText }}
                     </v-chip>
                  </template>
                  <span>{{ readOnlyItemReason?.(item) }}</span>
               </v-tooltip>
            </template>
            <template slot="append-outer">
               <v-btn tile color="error" elevation="2" icon ui-test-data="upload-btn" @click="onOpenAddDialogClick">
                  <v-icon>{{ icon }}</v-icon>
               </v-btn>
            </template>
            <template v-if="createApiEndpoint && search && search.length > 1" slot="no-data">
               <v-col
                  class="pb-0 cursor-pointer"
                  @keyup.enter="createNewEntityAndSet()"
                  @click="createNewEntityAndSet()"
               >
                  Add new {{ entity.toLowerCase() }}:
                  <v-chip>{{ search }}</v-chip>
               </v-col>
            </template>
         </v-autocomplete>
         <!-- Single selectable (with text value) autocomplete -->
         <v-autocomplete
            v-else
            ref="autoComplete"
            :key="autocompleteReloadKey"
            v-model="vModelSingle"
            :label="selectLabel"
            :items="autocompleteItems"
            :search-input.sync="search"
            :loading="loading"
            item-text="displayText"
            return-object
            :multiple="false"
            :clearable="minReferences < 1 && !disabled"
            :hide-no-data="hideNoData"
            :readonly="disabled"
            :rules="rules"
            color="error"
            :hide-details="hideDetails"
            ui-test-data="entity-details-input"
            @click="onAutocompleteClicked"
            @focus="onAutocompleteFocused"
            @dblclick="onOpenAddDialogClick"
         >
            <template slot="append-outer">
               <v-btn
                  class="add-reference-textfield-append"
                  tile
                  color="error"
                  elevation="2"
                  icon
                  ui-test-data="open-list-btn"
                  @click="onOpenAddDialogClick"
               >
                  <v-icon>{{ icon }}</v-icon>
               </v-btn>
            </template>
            <template v-if="$scopedSlots['autocomplete-item']" #item="data">
               <slot name="autocomplete-item" v-bind="data" />
            </template>
         </v-autocomplete>
      </template>
      <add-reference-selection-dialog
         :filter="vModel"
         :entity="entity"
         :apiEndpoint="apiEndpoint"
         :newItemRoute="newItemRoute"
         :itemDetailRoute="itemDetailRoute"
         :minReferences="minReferences"
         :maxReferences="maxReferences"
         :showDomainSelect="showDomainSelect"
         :headers="headers"
         :isAdd="false"
         :showDialog="isAddReferenceSelectionDialogShown"
         :disabled="disabled"
         :sortBy.sync="sortByInternal"
         :sortDesc.sync="sortDescInternal"
         :show-expand="showExpand"
         :read-only-item-reason="readOnlyItemReason"
         @addReferences="addReferences"
         @hideDialog="hideAddReferenceSelectionDialog"
      >
         <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
            <slot v-if="slot !== 'autocomplete-item'" :name="slot" v-bind="scope" />
         </template>
      </add-reference-selection-dialog>
   </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BaseResponse from "@models/BaseResponse";
import AddReferenceDialogHeader from "@components/Shared/add-reference-dialog-header.vue";
import { IFilterOptions, ItemReference } from "@backend/api/pmToolApi";
import ViewItem from "@models/view/ViewItem";
import AddReferenceSelectionDialog from "@components/Shared/add-reference-selection-dialog.vue";
import pluralize from "pluralize";
import globalStore from "@backend/store/globalStore";
import _ from "lodash";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";
import { RoutePathWithoutParams } from "@root/routes";
import { ValidationRule } from "@models/shared/ValidationRules";
import { PagingStore, QueryOptions } from "@utils/Paging";
import { MIN_PAGE_SIZE } from "@components/Shared/Base/overview-base.vue";

@Component({
   name: "AddReference",
   components: {
      AddReferenceSelectionDialog,
      AddReferenceDialogHeader,
   },
})
export default class AddReference extends Vue {
   loadingSearch: boolean = false;
   loadingItemReferences: boolean = false;
   searchedItems: ItemReference[] = [];
   search: string | null = null;

   // This is a hack to avoid chips in autocomplete not matching the data. TODO: solve properly as part of #35866
   autocompleteReloadKey: number = 0;

   @Prop({ required: true })
   value: ItemReference[];

   @Prop({ default: "Reference" })
   entity: string;

   @Prop({ default: null })
   newItemRoute: RoutePathWithoutParams;

   @Prop({ default: false })
   showExpand: boolean;

   /**
    * API endpoint which is used to get and select references via popup dialog with table
    * (may be serverside paginated -> returning paged data, or non-serverside paginated -> returning all references at once)
    */
   @Prop({
      default: () => () => {
         throw "invalid API endpoint";
      },
   })
   apiEndpoint: (pagingStore: PagingStore, domain?: number, search?: string) => Promise<ItemReference[]>;

   @Prop({ default: false })
   disabled: boolean;

   @Prop({ default: 0 })
   minReferences: number;

   @Prop({ default: Infinity })
   maxReferences: number;

   @Prop({ default: false })
   hideDetails: boolean;

   @Prop({ default: false })
   singleLine: boolean;

   @Prop()
   headers?: ViewItem[];

   @Prop({ default: () => [] })
   rules: ValidationRule<ItemReference | null | undefined>[];

   @Prop({ default: true })
   hideNoData: boolean;

   @Prop({ default: null })
   createApiEndpoint: (value: string) => Promise<ItemReference> | null;

   @Prop({ default: null })
   itemDetailRoute: RoutePathWithoutParams;

   @Prop({ default: "mdi-upload" })
   icon: string;

   @Prop({ default: false })
   showOnlyButton;
   /**
    * Whether or not to show domain selection.
    * When set to true, "apiEndpoint" is expected to have a "domain" parameter, in order to load references tied to the given domain
    */
   @Prop({ default: false })
   showDomainSelect: boolean;

   @Prop({ default: true })
   showLabelOnInput: boolean;

   @Prop({ default: undefined })
   sortBy?: string | [];

   @Prop({ default: undefined })
   sortDesc?: boolean | [];

   /**
    * Should return a reason why the item is readonly or `undefined` if it is not.
    */
   @Prop({ default: undefined })
   readOnlyItemReason?: (x: ItemReference) => string | undefined;

   @Watch("search")
   async onValueChanged() {
      if (!this.isAutocompleteMenuAllowed) this.allowAutocompleteMenu(); // undo hiding autocomplete menu
      await this.onSearchChangedDebounced();
   }

   async onSearchChanged() {
      if (
         this.search != null &&
         this.search.length > 1 &&
         (this.maxReferences > 1 || this.search != this.textFieldValue)
      ) {
         this.loadingSearch = true;

         await this.loadSearchItems(this.search);

         this.loadingSearch = false;
      }
   }

   // debounced handling of search changed -> as user is typing, respond only once
   onSearchChangedDebounced: Function = _.debounce(this.onSearchChanged, 300);

   /**
    * Reset autocomplete search text input and hide menu after selecting item
    */
   @Watch("vModel")
   onVModelChanged() {
      if (this.$refs.autoComplete) {
         this.$refs.autoComplete.internalSearch = "";
         this.$refs.autoComplete.isMenuActive = false; // when item selected, menu should be closed
         this.autocompleteReloadKey++;
      }
   }

   get autocompleteItems() {
      return [...this.searchedItems, ...this.vModel];
   }

   get loading(): boolean {
      return this.loadingItemReferences;
   }

   get vModel(): ItemReference[] {
      return (this.value ?? []).map((item) => new ItemReference({ ...item }));
   }

   set vModel(value: ItemReference[]) {
      if (value.length < this.minReferences || value.length > this.maxReferences) value = this.vModel;
      value.sort(function (a: ItemReference, b: ItemReference) {
         return a?.displayText?.toLowerCase() > b?.displayText?.toLowerCase() ? 1 : -1;
      });
      this.$emit("input", value);
   }

   get vModelSingle(): ItemReference | undefined {
      return this.vModel[0];
   }

   set vModelSingle(value: ItemReference | undefined) {
      this.vModel = value == undefined ? [] : [value];
   }

   canRemoveItem(item: ItemReference) {
      return !this.disabled && this.readOnlyItemReason?.(item) === undefined;
   }

   removeItem(item: ItemReference) {
      if (!this.canRemoveItem(item)) {
         throw new Error("Invariant error. Cannot remove readonly item.");
      }
      this.vModel = this.vModel.filter((x) => x.id !== item.id);
   }

   get textFieldValue(): string | null {
      return this.vModel?.[0]?.displayText ?? null;
   }

   set textFieldValue(value: string | null) {
      if (!value) {
         this.vModel = [];
      }
   }

   get selectLabel(): string | undefined {
      return this.showLabelOnInput ? (this.maxReferences < 2 ? this.entity : this.entityPlural) : undefined;
   }

   @Prop({ default: true })
   pluralize: boolean;

   get entityPlural(): string {
      return this.pluralize ? pluralize(this.entity) : this.entity;
   }

   // -------- Add dialog -------------
   isAddReferenceSelectionDialogShown: boolean = false;
   newEntityCreationInProgress: boolean = false;

   showAddReferenceSelectionDialog() {
      this.isAddReferenceSelectionDialogShown = true;
      setTimeout(() => {
         if (this.$refs.autoComplete) {
            this.$refs.autoComplete.isMenuActive = false;
         }
      }, 200);
   }

   hideAddReferenceSelectionDialog() {
      this.isAddReferenceSelectionDialogShown = false;
   }

   onOpenAddDialogClick() {
      this.showAddReferenceSelectionDialog();
   }

   addReferences(selectedReferences: ItemReference[]) {
      this.vModel = selectedReferences;
      this.hideAddReferenceSelectionDialog();
   }

   createNewEntityAndSet() {
      if (this.newEntityCreationInProgress) {
         return; // Prevent duplicate API requests
      }

      let trimmedSearch = this.search?.trim();

      if (!trimmedSearch || trimmedSearch.length == 0) {
         return; // If input is empty or already existing entity was selected from the list by 'enter' keyboard button.
      }

      if (trimmedSearch.length <= 1) {
         EventBus.$emit(Events.DisplayToast, { color: "error", text: "Minimal length is 2 characters" });
         return;
      }

      this.newEntityCreationInProgress = true;

      const add = async () => {
         let matchingItems = this.searchedItems.filter(
            (obj) => obj.displayText?.toLowerCase() == trimmedSearch?.toLowerCase()
         );
         if (matchingItems.length > 0) {
            if (!this.value.some((obj) => obj.displayText === matchingItems[0].displayText)) {
               if (this.maxReferences < this.vModel.length + 1) {
                  this.vModel = [...this.vModel.slice(0, this.maxReferences - 1), matchingItems[0]];
               } else {
                  this.vModel = [...this.vModel, matchingItems[0]];
               }
            } else {
               console.warn("already exists");
            }
         } else if (this.createApiEndpoint) {
            let res = await this.createApiEndpoint(trimmedSearch);
            if (res) {
               if (this.maxReferences < this.vModel.length + 1) {
                  this.vModel = [...this.vModel.slice(0, this.maxReferences - 1), res];
               } else {
                  this.vModel = [...this.vModel, res];
               }
            }
         }
         this.newEntityCreationInProgress = false;
      };

      setTimeout(add, 500);
   }

   // -------- Sort ------------
   get sortByInternal(): string | [] | undefined {
      return this.sortBy;
   }

   set sortByInternal(value: string | [] | undefined) {
      this.$emit("update:sortBy", value);
   }

   get sortDescInternal(): boolean | [] | undefined {
      return this.sortDesc;
   }

   set sortDescInternal(value: boolean | [] | undefined) {
      this.$emit("update:sortDesc", value);
   }

   //--------- Autocomplete menu hiding ---------
   /**
    * Whether or not to hide/disallow the autocomplete's menu with suggestions
    * (should be only shown/allowed when currently searching)
    */
   isAutocompleteMenuAllowed: boolean = true;

   onAutocompleteFocused() {
      this.disallowAutocompleteMenu();
   }

   onAutocompleteClicked() {
      this.disallowAutocompleteMenu();
   }

   disallowAutocompleteMenu() {
      this.isAutocompleteMenuAllowed = false;
   }

   allowAutocompleteMenu() {
      // NOTE: only undo of forced hiding of the menu
      this.isAutocompleteMenuAllowed = true;
   }

   registerAutocompleteMenuWatch() {
      this.$watch("$refs.autoComplete.isMenuActive", (newValue: boolean, oldValue: boolean) => {
         if (oldValue === false && newValue === true) {
            // when autocomplete component tries to show the menu
            if (!this.isAutocompleteMenuAllowed) {
               // and if the flag to show/allow the menu is set to 'false'
               this.$refs.autoComplete.isMenuActive = false; // discard showing of menu
            }
         }
      });
   }

   mounted() {
      this.registerAutocompleteMenuWatch();
   }

   // -------- API -------------
   async loadSearchItems(search: string): Promise<void> {
      // Items have already been requested
      if (this.loadingItemReferences) {
         return;
      }

      this.loadingItemReferences = true;

      try {
         this.searchedItems = await this.apiEndpoint(
            {
               OnBeforePagedQuery(queryOptions: QueryOptions) {
                  queryOptions.pageSize = MIN_PAGE_SIZE;
                  queryOptions.filter ??= {} as IFilterOptions;
                  queryOptions.filter.searchQuery = search;
               },
               OnAfterPagedQuery() {},
            },
            this.showDomainSelect ? globalStore.getDomain() : undefined,
            search
         );
      } catch (e) {
         const error = e as BaseResponse;
         console.log(`API ${this.entity} load error:`, error);
         EventBus.$emit(Events.DisplayToast, {
            color: "error",
            text: `Failed to load ${this.entity}: ${error?.message}`,
         });
      }
      this.loadingItemReferences = false;
   }
}
</script>
<style lang="scss" scoped>
/* hide arrow in chips input - there wont be any dropdowns */
::v-deep .hide-dropdown .mdi-menu-down {
   display: none !important;
}
.cursor-pointer {
   cursor: pointer;
}

.add-reference {
   &-textfield-append {
      margin-top: -7px;
   }
}
</style>
