<template>
   <v-chip light :color="color" :text-color="textColor" label :small="small">
      <span class="status-chip-text">{{ translateKey(pbbEnactmentStatusVM.translationKey) }}</span>
   </v-chip>
</template>
<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { PbbEnactmentStatusDecorator } from "@models/shared/PbbEnactmentStatusDecorator";
import { PbbEnactmentStatus } from "@backend/api/pmToolApi";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

@Component({
   name: "StatusChip",
   components: {},
})
export default class StatusChip extends ComponentBase {
   @Prop({ required: true })
   value: PbbEnactmentStatus;

   @Prop({ default: false })
   small: boolean;

   routeNames: string[] = ["pbb-enactment-status-decorator"];
   mounted() {
      this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadRouteTranslations(this.routeNames);
      });
   }

   // -------- Pbb enactment status -------------
   get pbbEnactmentStatusVM(): PbbEnactmentStatusDecorator {
      return new PbbEnactmentStatusDecorator(this.value);
   }

   get textColor(): string {
      if (this.isDark !== undefined) {
         return this.isDark ? "white" : "black";
      }

      // previous implementation kept for compatibility with other status usages
      if (this.color != "#aed6f1") return "white";
      else return "black";
   }

   get isDark(): boolean {
      return this.pbbEnactmentStatusVM.isDark;
   }

   get color(): string {
      return this.pbbEnactmentStatusVM.color;
   }
}
</script>
