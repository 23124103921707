<template>
   <v-card :loading="loading">
      <v-card-title primary-title>
         <v-row>
            <v-col>{{ translateKey("scriptsTab.scriptsLabel", translations) }}</v-col>
         </v-row>
      </v-card-title>
      <v-card-text>
         <v-row>
            <v-col cols="12">
               <v-row dense>
                  <v-col cols="auto">
                     <v-subheader>{{ translateKey("scriptsTab.scriptOnLoadLabel", translations) }}</v-subheader>
                  </v-col>
                  <v-col cols="auto">
                     <v-btn
                        :loading="loadingOnLoadValidation"
                        @click="onValidateScriptButtonClick(contentBrickScriptOnLoadType)"
                     >
                        <v-icon color="green">mdi-play</v-icon>
                        {{ translateKey("scriptsTab.validateButton", translations) }}
                     </v-btn>
                  </v-col>
                  <v-col cols="auto">
                     <v-menu open-on-hover offset-y>
                        <template #activator="{ on, attrs }">
                           <v-btn class="ml-2" icon v-bind="attrs" v-on="on">
                              <v-icon dense color="info">mdi-help</v-icon>
                           </v-btn>
                        </template>
                        <v-card flat>
                           <v-card-text>
                              <div class="d-flex flex-column">
                                 <code-editor
                                    v-model="scriptOnLoadSignature"
                                    :label="translateKey('scriptsTab.scriptOnLoadHintTitle', translations)"
                                    :readonly="true"
                                    :hideLineNumbers="true"
                                 />
                              </div>
                           </v-card-text>
                        </v-card>
                     </v-menu>
                  </v-col>
               </v-row>
               <v-row dense>
                  <v-col cols="12">
                     <prism-editor
                        :value="scripts?.onLoad"
                        class="pm-script-editor grey lighten-5"
                        :highlight="highlighter"
                        :readonly="disabled || !scripts"
                        line-numbers
                        @input="scripts.onLoad = $event"
                     ></prism-editor>
                  </v-col>
               </v-row>
            </v-col>
         </v-row>
         <v-row>
            <v-col cols="12">
               <v-row dense>
                  <v-col cols="auto">
                     <v-subheader>{{ translateKey("scriptsTab.scriptOnValidationLabel", translations) }}</v-subheader>
                  </v-col>
                  <v-col cols="auto">
                     <v-btn
                        :loading="loadingOnValidateValidation"
                        @click="onValidateScriptButtonClick(contentBrickScriptOnValidateType)"
                     >
                        <v-icon color="green">mdi-play</v-icon>
                        {{ translateKey("scriptsTab.validateButton", translations) }}
                     </v-btn>
                  </v-col>
                  <v-col cols="auto">
                     <v-menu open-on-hover offset-y>
                        <template #activator="{ on, attrs }">
                           <v-btn class="ml-2" icon v-bind="attrs" v-on="on">
                              <v-icon dense color="info">mdi-help</v-icon>
                           </v-btn>
                        </template>
                        <v-card flat>
                           <v-card-text>
                              <div class="d-flex flex-column">
                                 <code-editor
                                    v-model="scriptOnValidationSignature"
                                    :label="translateKey('scriptsTab.scriptOnValidationHintTitle', translations)"
                                    :readonly="true"
                                    :hideLineNumbers="true"
                                 />
                              </div>
                           </v-card-text>
                        </v-card>
                     </v-menu>
                  </v-col>
               </v-row>
               <v-row dense>
                  <v-col cols="12">
                     <prism-editor
                        :value="scripts?.onValidate"
                        class="pm-script-editor grey lighten-5"
                        :highlight="highlighter"
                        :readonly="disabled || !scripts"
                        line-numbers
                        @update="scripts.onValidate = $event"
                     ></prism-editor>
                  </v-col>
               </v-row>
            </v-col>
         </v-row>
      </v-card-text>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import {
   ContentBrickDefinitionApi,
   ContentBrickDefinitionScripts,
   ContentBrickScriptType,
   TranslationPublicModel,
   ValidationResult,
} from "@backend/api/pmToolApi";
import { ScriptOnLoadSignature, ScriptOnValidationSignature } from "@backend/examples/scriptSignature";
import { ContentBrickScriptTypeDecorator } from "@models/shared/ContentBrickScriptTypeDecorator";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-csharp";
import "prismjs/themes/prism.css"; // import syntax highlighting styles
import ComponentBase from "@components/Shared/Base/component-base.vue";
import CodeEditor from "@components/Shared/code-editor.vue";

@Component({
   name: "ContentBrickDetailTabScript",
   components: {
      PrismEditor,
      CodeEditor,
   },
})
export default class ContentBrickDetailTabScript extends ComponentBase {
   loading: boolean = false;
   loadingOnLoadValidation: boolean = false;
   loadingOnValidateValidation: boolean = false;
   hasChanges: boolean = false;
   originalScripts: string = "";
   scripts: ContentBrickDefinitionScripts = new ContentBrickDefinitionScripts({
      onLoad: "",
      onValidate: "",
   });
   contentBrickScriptOnLoadType: ContentBrickScriptType = ContentBrickScriptType.OnLoad;
   contentBrickScriptOnValidateType: ContentBrickScriptType = ContentBrickScriptType.OnValidate;
   scriptOnLoadSignature: string = ScriptOnLoadSignature;
   scriptOnValidationSignature: string = ScriptOnValidationSignature;

   @Prop({ default: null })
   contentBrickId: string;

   @Prop({ default: false })
   disabled: boolean;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Watch("scripts", { deep: true })
   onValueChanged(): void {
      this.hasChanges =
         this.scripts != null && this.originalScripts != null && JSON.stringify(this.scripts) !== this.originalScripts;
      this.$emit("hasChangesChanged", this.hasChanges);
   }

   highlighter(code: string): string {
      return highlight(code, languages.cs); // languages.<insert language> to return html with markup
   }

   async saveAsync(): Promise<void> {
      await this.updateContentBrickDefinitionScripts();
   }

   onValidateScriptButtonClick(scriptType: ContentBrickScriptType) {
      this.validateContentBrickDefinitionScript(scriptType);
   }

   getScript(scriptType: ContentBrickScriptType): string | undefined {
      switch (scriptType) {
         case ContentBrickScriptType.OnLoad:
            return this.scripts.onLoad;
         case ContentBrickScriptType.OnValidate:
            return this.scripts.onValidate;
         default:
            throw "Unsupported ContentBrick script type";
      }
   }

   setLoadingValidation(scriptType: ContentBrickScriptType, value: boolean) {
      switch (scriptType) {
         case ContentBrickScriptType.OnLoad:
            this.loadingOnLoadValidation = value;
            break;
         case ContentBrickScriptType.OnValidate:
            this.loadingOnValidateValidation = value;
            break;
         default:
            throw "Unsupported ContentBrick script type";
      }
   }

   notifyValidationResult(scriptType: ContentBrickScriptType, validationResult: ValidationResult) {
      if (!validationResult) throw "Validation result is required to notify";

      if (validationResult.success === true) {
         const message = `Content Brick script '${this.translateKey(
            new ContentBrickScriptTypeDecorator(scriptType).translationKey,
            this.translations
         )}' validation succeeded`;
         this.notifySuccess(message);
      } else {
         const message = `Content Brick script '${this.translateKey(
            new ContentBrickScriptTypeDecorator(scriptType).translationKey,
            this.translations
         )}' validation failed: ${validationResult.errorMessage ?? "Unknown Error"}`;
         this.notifyError(message);
      }
   }

   mounted() {
      this.loadContentBrickDefinitionScripts();
   }

   // ---- API -----
   async loadContentBrickDefinitionScripts(): Promise<void> {
      this.loading = true;
      try {
         // Call the API
         let scripts = await ContentBrickDefinitionApi.getContentBrickDefinitionScripts(this.contentBrickId);
         // Process/Save data etc.
         scripts.onLoad = scripts.onLoad ?? "";
         scripts.onValidate = scripts.onValidate ?? "";
         this.originalScripts = JSON.stringify(scripts);
         this.scripts = scripts;
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition Scripts");
      }
      this.loading = false;
   }

   async updateContentBrickDefinitionScripts(): Promise<void> {
      this.loading = true;
      try {
         // Call the API
         let scripts = await ContentBrickDefinitionApi.updateContentBrickDefinitionScripts(
            this.contentBrickId,
            this.scripts
         );
         // Process/Save data etc.
         scripts.onLoad = scripts.onLoad ?? "";
         scripts.onValidate = scripts.onValidate ?? "";
         this.originalScripts = JSON.stringify(scripts);
         this.scripts = scripts;
      } catch (error) {
         if (error.errors) {
            if (error.errors["onLoad"] || error.errors["onValidation"]) {
               for (const prop in error.errors) {
                  this.notifyError(`Content Brick script '${prop}' validation failed: ${error.errors[prop]}`);
               }
            } else {
               for (const prop in error.errors) {
                  this.notifyError(`API validation error: ${error.errors[prop]}`);
               }
            }
         } else {
            this.notifyError(`Failed to update Content Brick Scripts: ${error?.message}`);
         }
      }
      this.loading = false;
   }

   async validateContentBrickDefinitionScript(scriptType: ContentBrickScriptType): Promise<void> {
      this.setLoadingValidation(scriptType, true);
      try {
         var script = this.getScript(scriptType);
         // Call the API
         let validationResult = await ContentBrickDefinitionApi.validateContentBrickDefinitionScript(
            this.contentBrickId,
            scriptType,
            script ?? ""
         );
         // Process/Save data etc.
         this.notifyValidationResult(scriptType, validationResult);
      } catch (error) {
         this.notifyError(`Failed to validate Content Brick Script: ${error?.message}`);
      }
      this.setLoadingValidation(scriptType, false);
   }
}
</script>
