<template>
   <v-card>
      <v-card-title primary-title>
         <v-row>
            <v-col cols="12" md="6">
               {{ translateKey("dataModelReferencesTab.dataModelReferencesLabel", translations) }}
            </v-col>
            <v-col cols="12" md="6">
               <div class="d-flex">
                  <v-switch v-model="showAllVersions" color="error" label="Show all Versions" class="mr-8"></v-switch>
                  <v-text-field
                     v-model="searchDataModels"
                     append-icon="mdi-magnify"
                     :label="translateKey('label.search', translations)"
                     single-line
                     hide-details
                     color="red darken-2"
                     autofocus
                     @input="searchDebounced()"
                  />
               </div>
            </v-col>
         </v-row>
      </v-card-title>
      <v-card-text>
         <v-data-table
            :mobile-breakpoint="0"
            class="overview-table pmtool-table"
            dense
            :headers="headerDataModels"
            :items="dataModels"
            :loading="loading"
            :search="searchDataModels"
            :custom-filter="customSearch"
            :server-items-length="pagingTotalDocuments"
            :options.sync="dataTableOptions"
            :footer-props="footerProps"
         >
            <template #item.version="{ item }">
               <router-link :to="getDataModelDetailValue(item)" class="black--text">
                  <span>v{{ ("000" + item.version.major).slice(-4) }}</span>
               </router-link>
            </template>
            <template #item.code="{ item }">
               <router-link :to="getDataModelDetailValue(item)" class="black--text">
                  <div>
                     {{ item.code }}
                  </div>
               </router-link>
            </template>
            <template #item.displayText="{ item }">
               <router-link :to="getDataModelDetailValue(item)" class="black--text">
                  <div>
                     {{ item.displayText }}
                  </div>
               </router-link>
            </template>
         </v-data-table>
      </v-card-text>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop, Watch } from "vue-property-decorator";
import {
   ContentBrickDefinitionApi,
   IContentBrickDefinition,
   IItemVersionReference,
   QueryOptionsOfVersionedFilterOptions,
   TranslationPublicModel,
   VersionedFilterOptions,
} from "@backend/api/pmToolApi";
import OverviewBase from "@components/Shared/Base/overview-base.vue";
import { debounce } from "lodash";
import { DataOptions } from "vuetify";
import RouterUtils from "@utils/RouterUtils";

@Component({
   name: "ContentBrickDetailTabDataModelUsage",
   components: {},
})
export default class ContentBrickDetailTabDataModelUsage extends OverviewBase {
   loading: boolean = true;
   dataModels: IItemVersionReference[] = [];
   filter: VersionedFilterOptions = VersionedFilterOptions.fromJS({ lastOnly: true });
   searchDataModels: string = "";

   @Prop({ default: null })
   contentBrick: IContentBrickDefinition | null;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   //------------------- Base --------------------
   loadData = this.loadContentBrickDefinitionDataModelReferences;
   protected defaultDataTableOptions: Partial<DataOptions> | undefined = {
      sortBy: ["code"],
      sortDesc: [true],
   };

   @Watch("translations")
   onTranslationsChanged() {
      this.translateHeaders(this.headerDataModels, this.translations);
   }

   //------------------ Filter -------------------
   get showAllVersions(): boolean {
      return !this.filter.lastOnly;
   }

   set showAllVersions(value: boolean) {
      this.filter.lastOnly = !value;
      this.searchDebounced();
   }

   private searchDebounced = debounce(() => this.search(), 200);

   search() {
      this.filter.searchQuery = this.searchDataModels.length >= 3 ? this.searchDataModels : undefined;
      this.loadContentBrickDefinitionDataModelReferences();
   }

   // ---- table -----
   headerDataModels = [
      { text: "Code", value: "code", translationKey: "dataModelReferencesTab.codeHeader" },
      { text: "Name", value: "displayText", translationKey: "dataModelReferencesTab.nameHeader" },
      { text: "Version", value: "version", translationKey: "dataModelReferencesTab.versionHeader" },
   ];

   getDataModelDetailValue(dataModel: IItemVersionReference): object {
      return RouterUtils.getDataModelDetailRouteValue(dataModel);
   }

   mounted() {
      this.loadData();
      this.translateHeaders(this.headerDataModels, this.translations);
   }

   // ---- API -----
   async loadContentBrickDefinitionDataModelReferences(): Promise<void> {
      this.loading = true;
      try {
         // prepare api call parameters
         let queryOptions = new QueryOptionsOfVersionedFilterOptions();
         queryOptions.filter = VersionedFilterOptions.fromJS({ ...this.filter });
         this.OnBeforePagedQuery(queryOptions);

         // Call the API
         let result = await ContentBrickDefinitionApi.queryContentBrickDefinitionDataModels(
            this.contentBrick.code,
            queryOptions
         );
         // Process/Save data etc.
         this.dataModels = result.documents;

         // preserve pagination parameters
         this.OnAfterPagedQuery(result.continuationToken, result.totalDocumentsCount);
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition DomainDataModel references");
      }
      this.loading = false;
   }
}
</script>
