<template>
   <div>
      <attachments-field
         v-if="!ownerIsNew"
         v-model="files"
         :translations="translations"
         :label="label"
         :readonly="readonly"
         :rules="rules"
         :maxFileCount="maxFileCount"
         :attachmentAcceptTypes="attachmentAcceptTypes"
         :uploading="uploading"
         :downloading="downloading"
         :deleting="deleting"
         :removeBtnVisible="deleteCallback != null"
         @download-attachment="downloadAttachment"
         @delete-attachment="deleteAttachment"
         @upload-attachments="uploadAttachments"
      ></attachments-field>
      <span v-else>{{ translateKey("attachments.saveBeforeUploadingAttachments", translations) }}</span>
   </div>
</template>

<script lang="ts">
import { AttachmentDownloadModel, AttachmentMetadata, TranslationPublicModel } from "@backend/api/pmToolApi";
import AttachmentUtils, { AttachmentUploadInfo } from "@utils/AttachmentUtils";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import AttachmentsField from "@components/Shared/attachments-field.vue";
import { ValidationRule } from "@models/shared/ValidationRules";

@Component({
   components: { AttachmentsField },
})
export default class AttachmentUploadField extends ComponentBase {
   @VModel()
   files: AttachmentMetadata[];

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ required: true })
   label: string;

   @Prop({ default: false })
   ownerIsNew: boolean;

   @Prop({ required: true })
   readonly: boolean;

   @Prop({ default: () => [] })
   rules: ValidationRule<any>[];

   @Prop({ default: undefined })
   maxFileCount: number | undefined;

   @Prop({ default: undefined })
   attachmentAcceptTypes: string[] | undefined;

   @Prop()
   uploadCallback: (file: File, uploadInfo: AttachmentUploadInfo) => Promise<AttachmentMetadata>;

   @Prop()
   deleteCallback: (blobName: string) => Promise<boolean>;

   @Prop({ required: true })
   downloadUrlGetter: (blobName: string) => Promise<AttachmentDownloadModel>;

   uploading: AttachmentUploadInfo | null = null;
   downloading: boolean = false;
   deleting: boolean = false;

   @Watch("readonly", { immediate: true })
   onReadonlyChanged(readonly: boolean) {
      if (!readonly) {
         if (!this.deleteCallback) {
            throw new Error("Delete callback required if the field is not readonly.");
         }
         if (!this.uploadCallback) {
            throw new Error("Upload callback required if the field is not readonly.");
         }
      }
   }

   async uploadAttachments(files: File[]): Promise<void> {
      this.uploading = new AttachmentUploadInfo(files);

      try {
         for (var i = 0; i < files.length; i++) {
            let newAttachment = await this.uploadAttachment(files[i], this.uploading);

            if (newAttachment) {
               this.files = [...this.files, newAttachment];
            }
         }
      } finally {
         this.uploading = null;
      }
   }

   async uploadAttachment(file: File, uploadInfo: AttachmentUploadInfo): Promise<AttachmentMetadata | undefined> {
      try {
         return await this.uploadCallback(file, uploadInfo);
      } catch (error) {
         this.notifyError(error, "upload", "Attachment");
      }
   }

   async deleteAttachment(blobName: string): Promise<boolean | undefined> {
      try {
         this.deleting = true;
         if (await this.deleteCallback(blobName)) {
            this.files = this.files.filter((x) => x.blobName !== blobName);
            return true;
         }
      } catch (error) {
         this.notifyError(error, "delete", "Attachment");
      } finally {
         this.deleting = false;
      }
   }

   async downloadAttachment(blobName: string): Promise<void> {
      try {
         this.downloading = true;

         const file = await this.downloadUrlGetter(blobName);
         AttachmentUtils.downloadFile(file);
      } catch (error) {
         this.notifyError(error, "download", "Attachment");
      } finally {
         this.downloading = false;
      }
   }
}
</script>
