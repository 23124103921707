<template>
   <div>
      <v-menu v-if="showMenu" v-model="menu" max-width="225" min-width="225" offset-y allow-overflow auto left>
         <template #activator="{}">
            <status
               :propagate-click="true"
               :pointer="showPointer"
               :text="translateKey(statusVM.translationKey)"
               :color="statusVM.color"
               @clicked="onStatusClicked"
            ></status>
         </template>
         <v-list>
            <v-list-item
               v-for="entityStatus of EntityStatusDecorator.AllItems"
               :key="entityStatus.value"
               @click="selectStatus(entityStatus.value)"
            >
               <status
                  :pointer="showPointer"
                  :text="translateKey(entityStatus.translationKey)"
                  :color="entityStatus.color"
                  :propagate-click="false"
               ></status>
            </v-list-item>
         </v-list>
      </v-menu>
      <status
         v-else
         :pointer="showPointer"
         :text="translateKey(statusVM.translationKey)"
         :color="statusVM.color"
         :propagate-click="false"
      ></status>
   </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import Status from "@components/Shared/status.vue";
import { EntityStatus } from "@backend/api/pmToolApi";
import { EntityStatusDecorator } from "@models/shared/EntityStatusDecorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import EventBus from "@backend/EventBus";
import Events from "@models/shared/Events";

@Component({
   name: "EntityStatusPicker",
   components: {
      Status,
   },
})
export default class EntityStatusPicker extends ComponentBase {
   menu: boolean = false;

   @Prop({
      default: EntityStatus.Draft,
   })
   status: EntityStatus;

   @Prop({
      default: false,
   })
   showMenu: boolean;

   @Prop({
      default: false,
   })
   showPointer: boolean;

   get statusVM(): EntityStatusDecorator {
      return new EntityStatusDecorator(this.status);
   }

   onStatusClicked() {
      if (!this.menu) {
         this.menu = true;
      } else {
         this.selectStatus(this.status);
      }
   }

   selectStatus(status: EntityStatus) {
      this.$emit("updateEntityStatus", status);
      this.menu = false;
   }

   routeNames: string[] = ["entity-status-decorator"];
   mounted() {
      this.loadRouteTranslations(this.routeNames);
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadRouteTranslations(this.routeNames);
      });
   }
}
</script>
