export const translations = {
   "en-EN": {
      common: {
         PMTitle: "PMP",
      },

      administration: {
         login: "Login",
         register: "Registration",
         createUser: "Create user",
      },

      homePage: {
         title: "PBB Overview",
         projects: "Projects",
      },

      loginPage: {
         title: "PMP - Login",
         lblEmail: "Email",
         lblPassword: "Password",
         lblRememberMe: "Remember me ?",
      },

      registerPage: {
         title: "PMP - Registration",
         lblEmail: "Email",
         lblFirstName: "First name",
         lblLastName: "Last name",
         lblPassword: "Password",
         lblPasswordAgain: "Password again",
      },

      completeRegistration: {
         titileSuccess: "Account activation was successful",
         titleUnsuccessful: "Account activation failed",
         lblInfo: "The link may have expired. Please try again",
      },

      greetings: {
         welcome: "Welcome",
      },

      temporary: {
         unavaible: "Temporarily unavailable",
      },

      accountDetail: {
         title: "Account detail",
         lblEmail: "Email",
         lblFirstName: "First name",
         lblLastName: "Last name",
         lblBlockedAcc: "Blocked account",
         lblPreferedLang: "Prefered language",
         lblDescription: "Description",
         lblRoles: "Roles",
         lblGroups: "Groups",
         lblSubstitude: "Substitude",
         lblWorkingHours: "Working hours",
         lblLastLogin: "Last login",
         lblAccountChangeSuccess: "Your account has been updated successfully",
         btnSave: "Save",
         btnClose: "Close",
      },

      pbbDetail: {
         lblPbbDetail: "PBB Detail",
         lblPbbType: "PBB Type",
         version: "Version: ",
         created: "Created: ",
         modified: "Modified: ",
      },

      pbbOverView: {
         title: "PBB Overview",
         createPbb: "Create PBB",
         lblSearch: "Search",
      },

      pbbToolbar: {
         save: "Save",
         settings: "Settings",
      },

      pbbTabDetail: {
         microLevel: "Micro Level",
         contentEnviroment: "Content Enviroment",
         inboundConditions: "Inbound Conditions",
         gates: "Gates",
         content: "Content",
         attachments: "Attachments",
         history: "History",
         pbbStatusModel: "PBB Status Model",
      },

      treeView: {
         expand: "Expand",
      },

      navMenu: {
         mdiFileTree: "mdi-file-tree",
         pbb: "PBB",
         projects: "Projects",
         myAccount: "My Account",
         mdiAcc: "mdi-account",
         mdiCube: "mdi-cube",
         overView: "Overview",
         addNew: "Add new",
         published: "Published",
         unpublished: "Unpublished",
         inProgress: "In progress",
         archive: "Archive",
         search: "Search",
         pbbs: "PBBs",
         userAdministration: "User Administration",
         user: "User",
         administration: "Administration",
         qualifications: "Qualifications",
      },

      domainData: {
         addChild: "Add child",
         selectNode: "Select a node",
         name: "Name",
         rootComponent: "Root component ?",
         dataType: "Data Type",
         readOnly: "Read only ?",
         icon: "Icon",
      },

      domainDataOverView: {
         domainDataModels: "Domain data models",
         search: "Search",
      },

      pbbTags: {
         noResultsMatching: "No results matching",
         press: "Press",
         enter: "enter",
         toCreateNew: "to create a new one",
      },

      macroLevel: {
         title: "Macro Level",
         name: "Name",
         owner: "Owner",
         secOwner: "Sec. Owner",
         maintainer: "Maintainer",
         tags: "Tags",
         searchIdentifiers: "Search Identifiers",
         qualifications: "Qualifications",
         resourceTime: "Resource Time",
         planTime: "Plan Time",
         description: "Description",
      },

      groupsDetailDialog: {
         title: "Update Group",
         name: "Name",
         roles: "Roles",
         active: "Active",
         update: "Update",
         create: "Create",
      },

      groupsOverView: {
         title: "Groups",
         search: "Search",
         createGroup: "Create Group",
      },

      qualifications: {
         title: "Qualifications",
         search: "Search",
      },

      qualificationsDetail: {
         title: "Account detail",
         qualificationName: "Qualification Name",
         owner: "Owner",
         qualificationWithExpiration: "Qualification with expiration",
         expiresIn: "Expires in",
         expireDuration: "Expire duration",
         save: "Save",
         close: "Close",
         qualificationSuccessfully: "Qualification have been updated successfully",
      },

      userAndGroup: {
         title: "Choose user or group",
         user: "User",
         group: "Group",
         users: "Users",
         groups: "Groups",
         update: "Update",
      },

      roles: {
         title: "Roles",
         search: "Search",
      },

      treeTable: {
         expand: "Expand",
         search: "Search",
      },

      orgChart: {
         selectWorker: "Select worker:",
         newWorker: "New worker:",
         name: " Name",
         label: " Label",
         addChildNode: "Add Department",
         addSibingNode: "Add Sibing Node",
         addRootNode: "Add Root Node",
         removeNodes: "Remove Nodes",
         updateNode: "Update Node",
      },

      improveTimeframe: {
         writeTime: "Write time",
         convert: "Convert",
      },

      topBar: {
         logOut: "Log Out",
         myAcc: "My Account",
         search: "Search",
      },
   },
};
