/* intent unclear */
/* eslint-disable no-undef, @typescript-eslint/no-require-imports*/

import "babel-polyfill";
import "./class-component-hooks";
import Vue from "vue";
import router from "./router";
import Application from "@components/app-root.vue";
import vuetify from "./utils/vuetify";
import VueAppInsights from "vue-application-insights";

// Plugins
import axios from "axios";
import VueRouter from "vue-router";
import VueLocalize from "v-localize";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { translations } from "@utils/translations";
import SvgIcon from "vue-svgicon";
import wysiwyg from "vue-wysiwyg";
import FlowChart from "flowchart-vue";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import Masonry from "vue-masonry-css";
import convert from "convert-units";
import moment from "moment";
import ZkTable from "vue-table-with-tree-grid";
import VueEasyTree from "@wchbrad/vue-easy-tree";
import "@wchbrad/vue-easy-tree/src/assets/index.scss";
import LazyComponent from "v-lazy-component";
import * as linkify from "linkifyjs";

// pmtool SCSS
import "@styles/pmtool.scss";

import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import store from "./backend/store/authStore";
import Keycloak from "keycloak-js";
import { AxiosKeycloak } from "./backend/axiosKeycloak";
import Events from "./models/shared/Events";
import EventBus from "@backend/EventBus";
import { UserApi } from "./backend/api/pmToolApi";
const KeycloakSettings = require("../keycloaksettings.json");
import AppSettings from "@backend/store/settingStore";
import globalStore from "@backend/store/globalStore";
import { setCookie, getCookie, deleteCookie } from "./utils/CookieUtils";
import TableColumnDragAndDrop from "@utils/TableColumnDragAndDrop";
import { CachedApiBase } from "@backend/store/apiCache/CachedApiBase";
import { getLanguageIso1 } from "@utils/TranslationUtils";

import { productFruits } from "product-fruits";

console.log("Booting Up .... ");

// XHR Hook to mock Reporting Designer backend responses
// mock.mockReportDesignerBackend();

Vue.use(FlowChart);
Vue.use(VueRouter);
Vue.use(wysiwyg);
Vue.use(VueLocalize);
Vue.use(Masonry);
Vue.use(SvgIcon, {
   tagName: "svgicon",
   defaultWidth: "1.2rem",
   defaultHeight: "1.2rem",
});
Vue.use(ZkTable);
Vue.use(require("vue-shortkey"));
Vue.use(VueEasyTree);
Vue.use(LazyComponent);

Vue.prototype.$moment = moment;
moment.locale("en-gb");
Vue.prototype.$convert = convert;

Vue.directive("sortable-table", TableColumnDragAndDrop);
linkify.registerCustomProtocol("navision");

//console.log(process.env.BASE_URL);
//AXIOS
Vue.prototype.$http = axios;

//font awesome
library.add(fas);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

//localize
let localize = VueLocalize.config({
   default: "en-EN",
   available: ["en-EN"],
   fallback: "?",
   localizations: translations,
});

async function getApp() {
   let initResults = await Promise.all([AppSettings.init(), axios.get(KeycloakSettings)]);
   var keycloakConfig = initResults[1];

   let initOptions = null;
   if (process.env.NODE_ENV == "development") {
      initOptions = {
         url: keycloakConfig.data.dev.url,
         realm: keycloakConfig.data.dev.realm,
         clientId: keycloakConfig.data.dev.clientId,
         onLoad: "login-required",
         checkLoginIframe: false,
      };
   } else {
      initOptions = {
         url: keycloakConfig.data.azure.url,
         realm: keycloakConfig.data.azure.realm,
         clientId: keycloakConfig.data.azure.clientId,
         onLoad: "login-required",
         checkLoginIframe: false,
      };
   }
   axios.defaults.baseURL = AppSettings.getSetting("BaseUrl");
   let keycloak = new Keycloak(initOptions);
   console.log(keycloak);

   if (AppSettings.getSetting("AppInsightsKey")) {
      Vue.use(VueAppInsights, {
         baseName: "PMP",
         id: AppSettings.getSetting("AppInsightsKey"),
         router,
         appInsightsConfig: {
            maxAjaxCallsPerView: -1,
         },
      });
      let userIdTelemetryInitializer = (envelope) => {
         try {
            envelope.data.accountId = AxiosKeycloak.getKeycloak()?.subject;
         } catch (e) {
            console.error("Could not resolve User Id for AppInsights telemetry." + JSON.stringify(e));
         }
      };
      Vue.appInsights.addTelemetryInitializer(userIdTelemetryInitializer);
   }

   let axiosInstance = AxiosKeycloak.createAxiosInstance(keycloak);
   console.log(axiosInstance);

   if (keycloak.token && keycloak != getCookie("token")) {
      setCookie("token", keycloak.token);
      EventBus.$emit(Events.TokenUpdated);
   }

   let keycloakToken = getCookie("token");
   let keycloakRefreshToken = getCookie("refreshToken");

   keycloak
      .init({
         onLoad: initOptions.onLoad,
         token: keycloakToken,
         refreshToken: keycloakRefreshToken,
         checkLoginIframe: false,
      })
      .then((auth) => {
         keycloak.updateToken(30).then((x) => {
            setCookie("token", keycloak.token);
            setCookie("refreshToken", keycloak.refreshToken);
         });

         EventBus.$emit(Events.TokenUpdated);

         // User must have role of PM USER to login
         if (keycloak.realmAccess.roles.indexOf("pm_user") < 0) {
            keycloak.logout();
            deleteCookie("token");
            deleteCookie("refreshToken");
            return;
         }

         //User doesn't have to be synchronized so take name/id from keycloak instance
         store.setUserName(keycloak.tokenParsed["name"]);
         store.setUserId(keycloak.tokenParsed["sub"]); // userId

         new Vue({
            el: "#app-root",
            data: {
               isDomainChangeDisabled: false,
            },
            router: router,
            render: (h) => h(Application),
            vuetify,
            localize,
         }).$mount("#app-root");

         EventBus.$emit(Events.UserLoggedIn);
         //   get user from cosmos or sync users from keycloak
         (async () => {
            await UserApi.getUserBootModel(keycloak.subject)
               .then((response) => {
                  // get user base data
                  const name = response.firstName + " " + response.lastName;
                  store.setUserName(name);
                  store.setUserId(response.id);
                  store.setUserEmail(response.email);

                  const productFruitsWorkspaceCode = AppSettings.getSetting("productFruitsWorkspaceCode");
                  if (productFruitsWorkspaceCode) {
                     function initProductFruits() {
                        window.productFruits?.services?.destroy();
                        const langName = globalStore.getLanguage()?.name;
                        // Language model has language name but no actual iso codes for the language, so we map it here:
                        const iso1 = getLanguageIso1(langName);
                        const tok = keycloak.tokenParsed;
                        const user = {
                           userId: response.id,
                           firstName: response.firstName,
                           lastName: response.lastName,
                           name,
                           username: tok.preferred_username,
                           email: response.email,
                           roles: tok.realm_access.roles,
                        };
                        productFruits.init(productFruitsWorkspaceCode, iso1, {
                           username: user.username,
                           firstname: user.firstName,
                           email: user.email,
                           lastname: user.lastName,
                           role: user.roles.join(", "),
                           props: user,
                        });
                     }
                     initProductFruits();
                     EventBus.$on(Events.LanguageChanged, initProductFruits);
                  }

                  let authDate = moment.unix(keycloak.tokenParsed.auth_time);
                  if (!moment(response.userDetail.lastLoginDate).isSame(authDate)) {
                     UserApi.loginUser(authDate).catch((e) => {
                        console.log("Login API error", e);
                     });
                  }
               })
               .catch((e) => {
                  console.log("API User load error (login):", e);

                  if (e.errors && Object.keys(e.errors).length > 0) {
                     for (var prop in e.errors) {
                        EventBus.$emit(Events.DisplayToast, {
                           color: "error",
                           text: `Failed to load User profile: ${e.errors[prop]}`,
                        });
                     }
                  } else {
                     EventBus.$emit(Events.DisplayToast, {
                        color: "error",
                        text: `Failed to load User profile: ${e.message || e}`,
                     });
                  }
               });
         })();
      })
      .catch(() => {
         console.error("Authenticated Failed");
      });

   CachedApiBase.init(AppSettings.getSetting("CacheTtlSeconds"));
   EventBus.$on(Events.UserLoggedOut, () => {
      CachedApiBase.invalidate();
   });
}

export { getApp, router };
