import { render, staticRenderFns } from "./design-guideline-detail-tab-reports.vue?vue&type=template&id=58facc5a&scoped=true"
import script from "./design-guideline-detail-tab-reports.vue?vue&type=script&lang=ts"
export * from "./design-guideline-detail-tab-reports.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58facc5a",
  null
  
)

export default component.exports