<template>
   <v-card>
      <v-card-title primary-title>{{ translateKey("contentTab.generalLabel", translations) }}</v-card-title>
      <v-card-text>
         <v-row>
            <v-col>
               <v-text-field
                  v-model="contentBrickName"
                  color="error"
                  :label="translateKey('contentTab.nameFieldLabel', translations)"
                  :rules="nameRules"
                  counter
                  maxlength="80"
                  :readonly="!canEdit"
                  @blur="EventBus.$emit(Events.P2P, { eId: _uid })"
               />
               <identifier-field
                  v-model="contentBrick.identifier"
                  :source="contentBrickName"
                  :eventId="_uid"
                  :readonly="!canEdit"
                  :label="translateKey('contentTab.identifierFieldLabel', translations)"
               />
               <three-state-switch
                  v-model="contentBrick.saveOnlyInPdm"
                  color="error"
                  :allowSetIndeterminate="false"
                  :readonly="!canEdit"
                  :label="translateKey('contentTab.saveOnlyInPdm', translations)"
               ></three-state-switch>
               <add-reference-single
                  v-if="contentBrickType === ContentBrickType.DesignGuideline"
                  v-model="contentBrick.enactmentPriorityLabel"
                  class="py-0"
                  :entity="translateKey('contentTab.enactmentPriorityFieldLabel', translations)"
                  :translate="false"
                  :apiEndpoint="getEnactmentPriorityLabels"
                  newItemRoute="/enactmentPriorityLabels/new"
                  itemDetailRoute="/enactmentPriorityLabels/detail"
                  :disabled="!canEdit"
                  :headers="enactmentPriorityLabelHeaders"
               ></add-reference-single>
            </v-col>
            <v-col>
               <vue-editor
                  v-model="contentBrickDescription"
                  outlined
                  class="cb-description-editor"
                  label="Description"
                  :disabled="!canEdit"
               ></vue-editor>
            </v-col>
         </v-row>
         <v-row>
            <v-col cols="12" class="py-0 px-3">
               <add-reference
                  v-model="contentBrick.usageTypes"
                  class="py-0"
                  :entity="translateKey('contentTab.usageTypesFieldLabel', translations)"
                  :translate="false"
                  :apiEndpoint="getUsageTypes"
                  newItemRoute="/usageTypes/new"
                  itemDetailRoute="/usageTypes/detail"
                  :disabled="!canEditUsageTypes"
               ></add-reference>
               <add-reference
                  v-model="contentBrick.tags"
                  :entity="translateKey('contentTab.tagsFieldLabel', translations)"
                  :translate="false"
                  :apiEndpoint="getTags"
                  newItemRoute="/tag/new"
                  :hideNoData="false"
                  :createApiEndpoint="createTagApiCall"
                  itemDetailRoute="/tag/detail"
                  :disabled="!canEdit"
               ></add-reference>
            </v-col>
         </v-row>
         <v-row>
            <v-col cols="12">
               <content-brick-fields-table
                  ref="fieldsTable"
                  :contentBrick="contentBrick"
                  :translations="translations"
                  :contentBrickType="contentBrickType"
                  :loadingDependencies="loadingDependencies"
                  :readonly="!canEdit"
                  :isNew="isNew"
                  :hasChanges="hasChanges"
                  :units="units"
                  :attachments="attachments"
                  :attachmentsLoading="attachmentsLoading"
                  @reloadDependencies="onReloadDependencies"
                  @attachment-modified="$emit('attachment-modified', $event)"
               ></content-brick-fields-table>
            </v-col>
            <v-col v-if="hasValueFields" cols="12">
               <content-brick-metadata-fields-table
                  :contentBrick="contentBrick"
                  :translations="translations"
                  :contentBrickType="contentBrickType"
                  :loadingDependencies="loadingDependencies"
                  :readonly="!canEdit"
                  :isNew="isNew"
                  :hasChanges="hasChanges"
                  :units="units"
                  :attachments="attachments"
                  :attachmentsLoading="attachmentsLoading"
                  @reloadDependencies="onReloadDependencies"
                  @attachment-modified="$emit('attachment-modified', $event)"
               ></content-brick-metadata-fields-table>
            </v-col>
         </v-row>
         <content-brick-conditions
            v-model="contentBrick.condition"
            :conditionResultsIn.sync="contentBrick.contentBrickConditionResultsIn"
            :conditionResultsDescription.sync="contentBrick.contentBrickConditionResultsDescription"
            :fields="fields"
            :lists="lists"
            :readonly="!canEdit"
            :isFromProject="false"
            :translations="translations"
            :title="translateKey('contentTab.conditionsLabel', translations)"
         ></content-brick-conditions>
      </v-card-text>
      <confirm-delete-dialog-async ref="confirmDeleteDialog"></confirm-delete-dialog-async>
   </v-card>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";

import {
   AttachmentMetadata,
   AttachmentDownloadModel,
   EntityStatus,
   ItemReference,
   EnactmentPriorityLabelReference,
   ContentBrickDefinitionApi,
   ContentBrickFieldType,
   IContentBrickDefinition,
   ContentBrickFieldDefinition,
   DesignGuidelineValueFieldDefinition,
   List,
   SubContentBrickDefinitionApi,
   SubContentBrickDefinitionReference,
   SubContentBrickDefinitionFilterOptions,
   QueryOptionsOfSubContentBrickDefinitionFilterOptions,
   TagApi,
   TagFilterOptions,
   IUnitReference,
   UsageTypeApi,
   QueryOptionsOfUsageTypeFilterOptions,
   TranslationPublicModel,
   TextArea,
   ContentBrickType,
   QueryOptionsOfEnactmentPriorityLabelFilterOptions,
   EnactmentPriorityLabelApi,
   EnactmentPriorityLabelFilterOptions,
} from "@backend/api/pmToolApi";
import UnitCachedApi from "@backend/store/apiCache/UnitCachedApi";
import EventBus from "@backend/EventBus";
import {
   ContentBrickTreeNodeTypeUtils as Util,
   ContentBrickFieldTypeUtils as FieldUtil,
   FieldDefinition,
} from "@models/shared/ContentBrickTreeNodeTypeUtils";
import Events from "@models/shared/Events";

import AttachmentUtils from "@utils/AttachmentUtils";
import ContentBrickUtils from "@utils/ContentBrickUtils";

import AddReference from "@components/Shared/add-reference.vue";
import AddReferenceSingle from "@components/Shared/add-reference-single.vue";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import ConfirmDeleteDialogAsync from "@components/Shared/confirm-delete-dialog-async.vue";
import IdentifierField from "@components/Shared/identifier-field.vue";
import ThreeStateSwitch from "@components/Shared/three-state-switch.vue";

import ContentBrickConditions from "@components/ContentBricks/Shared/content-brick-conditions.vue";
import ContentBrickFieldsTable from "@components/ContentBricks/TabDetail/FieldDefinition/content-brick-fields-table.vue";
import ContentBrickMetadataFieldsTable from "@components/ContentBricks/TabDetail/FieldDefinition/content-brick-metadata-fields-table.vue";
import { Guid } from "guid-typescript";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import globalStore from "@backend/store/globalStore";
import ViewItem from "@models/view/ViewItem";
import { ValidationRule } from "@models/shared/ValidationRules";
import TagUtils from "@utils/TagUtils";

@Component({
   name: "ContentBrickDetailTabContent",
   components: {
      AddReference,
      AddReferenceSingle,
      VueEditor,
      ContentBrickConditions,
      ConfirmDeleteDialogAsync,
      IdentifierField,
      ContentBrickFieldsTable,
      ContentBrickMetadataFieldsTable,
      ThreeStateSwitch,
   },
})
export default class ContentBrickDetailTabContent extends ComponentBase {
   loadingUnits: boolean = false;
   loadingList: boolean = false;
   loadingRegularExpressionReferences: boolean = false;
   loadingScbReferences: boolean = false;
   units: IUnitReference[] = [];
   subCbReferences: SubContentBrickDefinitionReference[] = [];
   EventBus: any = EventBus;
   Events: any = Events;
   ContentBrickType = ContentBrickType;

   get lists(): List[] {
      return this.fields?.filter((f) => this.isListField(f) && f.list)?.map((f) => f.list!) ?? [];
   }

   @Prop({ default: null })
   contentBrick: IContentBrickDefinition | null;

   @Prop({ default: null })
   originalStatus: EntityStatus | null;

   @Prop({ default: false })
   isNew: boolean;

   @Prop({ required: true })
   hasChanges: boolean;

   @Prop({ required: true })
   contentBrickType: ContentBrickType;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ default: () => {} })
   attachments: { [key: string]: AttachmentMetadata };

   @Prop({ default: false })
   attachmentsLoading: boolean;

   get hasValueFields(): boolean {
      return ContentBrickTypeDecorator.get(this.contentBrickType).hasValueFields;
   }

   get contentBrickName(): string | undefined {
      return this.contentBrick?.name;
   }

   set contentBrickName(value: string | undefined) {
      if (this.contentBrick) {
         this.$set(this.contentBrick, "name", value?.trim());
      }
   }

   allFieldsInternal: FieldDefinition[] | null = null;

   get allFields(): FieldDefinition[] {
      if (!this.hasValueFields) {
         // not DGL -> only fields
         return this.fields;
      }

      if (!this.allFieldsInternal) {
         this.allFieldsInternal = this.fields.concat(this.valueFields).sort((a, b) => a.order - b.order);
      }

      return this.allFieldsInternal;
   }

   set allFields(value: FieldDefinition[]) {
      if (!this.hasValueFields) {
         // not DGL -> only fields
         this.contentBrick!.fields = value as ContentBrickFieldDefinition[];
         return;
      }

      var fields = value.filter((f) => Util.isContentBrickField(f));
      var valueFields = value.filter((f) => Util.isValueField(f));

      // set both fields and DGL value fields
      this.contentBrick!.fields = fields as ContentBrickFieldDefinition[];
      this.contentBrick!.designGuidelineValueFields = valueFields as DesignGuidelineValueFieldDefinition[];
   }

   get fields(): ContentBrickFieldDefinition[] {
      return this.contentBrick?.fields ? this.contentBrick.fields : [];
   }

   get valueFields(): DesignGuidelineValueFieldDefinition[] {
      return this.contentBrick?.designGuidelineValueFields ?? [];
   }

   get canEdit(): boolean {
      return (
         this.originalStatus === EntityStatus.Draft && (this.isNew || this.contentBrick?.permissions?.update === true)
      );
   }

   get canEditUsageTypes(): boolean {
      return this.canEdit && (this.isNew || this.contentBrick?.permissions?.updateUsageTypes === true);
   }

   get contentBrickDescription(): string | undefined {
      return this.contentBrick?.description?.value;
   }

   set contentBrickDescription(value: string | undefined) {
      if (!this.contentBrick) return;

      if (!this.contentBrick.description) {
         this.contentBrick.description = TextArea.fromJS({ id: Guid.EMPTY });
      }

      this.contentBrick.description.value = value;
   }

   // ---------- Design Guideline value field START ----------
   isListField(field: ContentBrickFieldDefinition): boolean {
      return FieldUtil.isListField(field);
   }

   // ---------- Attachments ----------
   downloading: { [key: string]: boolean } = {};
   mediaUrlsInternal = {};

   get mediaUrls(): { [key: string]: string } {
      // ImageType and/or VideoType Fields content
      return this.mediaUrlsInternal;
   }

   set mediaUrls(value: { [key: string]: string }) {
      this.$set(this, "mediaUrlsInternal", value);
   }

   mediaUrlsUpdated: { [key: string]: boolean } = {};

   async downloadAttachment(
      field: DesignGuidelineValueFieldDefinition,
      blobName: string
   ): Promise<AttachmentDownloadModel | undefined> {
      if (!blobName) {
         throw "Blob name is required";
      }

      try {
         this.$set(this.downloading, field.id, true);

         // Call the API FileResponse
         let file = ContentBrickDefinitionApi.generateAttachmentDownloadUrl(this.contentBrick!.id, blobName);
         return file;
      } catch (error) {
         this.notifyError(error, "download", "DesignGuideline Attachment");
      } finally {
         this.$set(this.downloading, field.id, false);
      }
   }

   async downloadAttachmentsUrls(blobName: string, cbField: DesignGuidelineValueFieldDefinition): Promise<void> {
      let mediaUrlsToDownload: string[] = [];
      var mediaUrlsToSet: {} = {};

      if (cbField.value) {
         cbField.value.forEach((media) => {
            if (!this.mediaUrls[media]) {
               mediaUrlsToDownload.push(media);
            }
         });
      }

      try {
         for (const value of Object.values(mediaUrlsToDownload)) {
            var file = await this.downloadAttachment(cbField, value);

            if (file) {
               mediaUrlsToSet[file.blobName!] = file.downloadUrl;
            }
         }
      } finally {
         this.$set(this, "mediaUrls", { ...this.mediaUrls, ...mediaUrlsToSet });
         this.$set(this.mediaUrlsUpdated, cbField.id, true);
         this.$emit("mediaUrlsUpdated", this.mediaUrls);
      }
   }

   mediaLoading = false;

   async mediaPreload() {
      this.mediaLoading = true;
      // TODO: #36074
      await Promise.all(
         this.contentBrick?.designGuidelineValueFields
            ?.filter((x) => AttachmentUtils.isMediaField(x.type))
            .map((x) => this.downloadAttachmentsUrls("", x)) ?? []
      );
      this.mediaLoading = false;
   }

   // ---------- EnactmentPriorityLabel ----------
   enactmentPriorityLabelHeaders: ViewItem[] = [
      {
         text: "Code",
         value: "code",
         class: "pmtool-table-header-fixed-sm",
         translationKey: "enactmentPriorityLabelOverview.codeHeader",
      },
      { text: "Name", value: "displayText", translationKey: "enactmentPriorityLabelOverview.nameHeader" },
      {
         text: "Enactment Priority",
         value: "enactmentPriorityOrder",
         translationKey: "enactmentPriorityLabelOverview.enactmentPriorityOrderLabel",
      },
   ];

   // ---------- Design Guideline value field END ----------
   mounted() {
      this.loadDependencies();
      this.translateHeaders(this.enactmentPriorityLabelHeaders, this.translations);
      if (this.hasValueFields) {
         if (this.contentBrick?.id) {
            this.mediaPreload();
         }
      }
   }
   // ---------- Add Field Dialog ----------
   onReloadDependencies() {
      this.loadDependencies();
   }

   get loadingDependencies(): boolean {
      return (
         this.loadingUnits || this.loadingList || this.loadingRegularExpressionReferences || this.loadingScbReferences
      );
   }

   // ---------- validation ----------
   nameRules: ValidationRule[] = [
      (v) => !!v?.trim() || "Name is required",
      (v) => v!.length <= 80 || "Name must be less than 80 characters",
   ];

   validateCalculatedFields(): boolean {
      var calculatedFields = this.fields.filter((f) => f.type === ContentBrickFieldType.Calculated);

      if (calculatedFields.some((f) => !this.validateCalculatedField(f))) {
         return false;
      }

      return true;
   }

   validateCalculatedField(field: ContentBrickFieldDefinition): boolean {
      if (
         field.type !== ContentBrickFieldType.Calculated ||
         !field.fieldCalculation ||
         !field.fieldCalculation.formula ||
         field.fieldCalculation.formula.trim() === ""
      ) {
         this.notifyError(
            `Calculated field named: '${field.name}', identifier: '${field.identifier}' formula validation failed.`
         );
         return false;
      }

      return true;
   }

   validateDesignGuidelines(): boolean {
      let invalidIntegerFields: DesignGuidelineValueFieldDefinition[] = [];

      let intFields = this.contentBrick?.designGuidelineValueFields?.filter(
         (x) => x.type == ContentBrickFieldType.Integer
      );
      intFields?.forEach((x) => {
         if (!/^\d+$/.test(x.value)) {
            const error = x.value && x.value.length > 0 ? `must be an integer.` : `is required`;
            this.notifyError(`Value of the field named: '${x.name}', identifier: '${x.identifier}' ${error}.`);
            invalidIntegerFields.push(x);
         }
      });

      let invalidEvaluationFields: DesignGuidelineValueFieldDefinition[] = [];
      this.contentBrick?.designGuidelineValueFields?.forEach((dglField) => {
         if (ContentBrickUtils.validateEvaluationType(this.contentBrick!, dglField) !== true) {
            invalidEvaluationFields.push(dglField);
         }
      });

      if (invalidIntegerFields.length > 0 || invalidEvaluationFields.length > 0) {
         this.$refs.fieldsTable?.expandNodes(invalidEvaluationFields);
         return false;
      }
      return true;
   }

   validate(): boolean {
      return this.validateCalculatedFields() && this.validateDesignGuidelines();
   }

   // ---------- API ----------
   async getTags(): Promise<ItemReference[]> {
      return await TagApi.getTagOfTypeTagReferences(
         new TagFilterOptions({
            domains: [globalStore.getDomain()],
         })
      );
   }

   async createTagApiCall(text: string): Promise<ItemReference> {
      return await TagUtils.getCreateTagApiCall(text, "Tag");
   }

   async getUsageTypes(): Promise<ItemReference[]> {
      let queryOptions = new QueryOptionsOfUsageTypeFilterOptions();
      var data = await UsageTypeApi.getUsageTypeReferences(queryOptions);
      return data.documents ?? [];
   }

   async getEnactmentPriorityLabels(): Promise<EnactmentPriorityLabelReference[]> {
      let queryOptions = QueryOptionsOfEnactmentPriorityLabelFilterOptions.fromJS({
         filter: EnactmentPriorityLabelFilterOptions.fromJS({
            entityStatus: EntityStatus.Active,
            domains: this.contentBrick ? [this.contentBrick.domain] : [globalStore.getDomain()],
         }),
      });
      var data = await EnactmentPriorityLabelApi.queryAllEnactmentPriorityLabelReferences(queryOptions);
      return data.documents ?? [];
   }

   loadDependencies() {
      this.loadUnits();
      this.loadSubContentBrickReferences();
   }

   async loadUnits(): Promise<void> {
      this.loadingUnits = true;
      try {
         // Call the API
         let units = await UnitCachedApi.getAllUnitReferences(EntityStatus.Active, undefined);
         // Process/Save data etc.
         this.units = units;
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition/Units");
      }
      this.loadingUnits = false;
   }

   async loadSubContentBrickReferences(): Promise<void> {
      this.loadingScbReferences = true;
      try {
         // Call the API
         let queryOptions = new QueryOptionsOfSubContentBrickDefinitionFilterOptions();
         queryOptions.pageSize = -1; // all scbs
         queryOptions.filter = new SubContentBrickDefinitionFilterOptions({
            domains: this.contentBrick ? [this.contentBrick.domain] : undefined,
            entityStatus: EntityStatus.Active,
            entityCodes: this.contentBrick
               ? this.contentBrick.fields.filter((f) => f.subContentBrickCode).map((f) => f.subContentBrickCode!)
               : undefined,
            lastOnly: true,
         });

         let result = await SubContentBrickDefinitionApi.getSubContentBrickDefinitionReferences(queryOptions);
         // Process/Save data etc.
         this.subCbReferences = result.documents ?? [];
      } catch (error) {
         this.notifyError(error, "load", "ContentBrickDefinition/SubContentBricks");
      }
      this.loadingScbReferences = false;
   }
}
</script>

<style scoped>
/* Vue Query Builder uses bootstrap */
@import "~bootstrap/dist/css/bootstrap.css";

.document-type-input {
   width: 277px;
}

.value-field-document-type-input {
   width: 400px;
}

.value-field-text-area-type-input {
   width: 150px;
}

.value-field-expanded-component {
   padding-right: 30px;
}
</style>
<style lang="scss">
tr.v-data-table__expanded.v-data-table__expanded__content {
   background: #f7f7f7 !important;
   box-shadow: inset 0px -15px 17px rgba(0, 0, 0, 0.06) !important;
   border-bottom: 32px solid #fff !important;
}

tr.v-data-table__expanded.v-data-table__expanded__row {
   background: #eee;
}
/* hide arrow in chips input - there wont be any dropdowns */
.options-input .mdi-menu-down {
   display: none !important;
}

.unit-editable-switch {
   line-height: 22px;
}

td.text-start {
   padding-left: 0px !important;
   padding-right: 5px !important;
}
th.text-start {
   padding-left: 0px !important;
   padding-right: 0px !important;
}

.cb-description-editor {
   height: calc(100% - 48px /* adjust by toolbar size, the ql-content is relative*/);
   padding-bottom: 21px;

   & .ql-editor {
      min-height: 112px; /*24px + 22px * 4 rows */
   }
}
</style>
<style lang="scss">
.theme--light.v-select .v-select__selection--disabled {
   color: black !important;
}
/* DGL field table END */
</style>
