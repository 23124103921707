<template>
   <div
      ref="fileTypeFiled"
      @dragover.prevent="onDragOver($event)"
      @dragleave="onDragLeave($event)"
      @drop.prevent="onFileDrop($event)"
   >
      <template v-if="!isDragging">
         <v-combobox
            v-model="files"
            :label="label"
            :class="{ 'field-editable': readonly } + ' value-field-document-type-input mb-2'"
            :readonly="true"
            hide-details="auto"
            :loading="uploading != null"
            multiple
            small-chips
            append-icon=""
            return-object
            @mouseup.stop.prevent="!readonly && $refs.fileInput.$refs.input.click()"
         >
            <template #append>
               <span v-if="maxFileCount !== undefined" class="grey--text mt-2">
                  ({{ files.length }}/{{ maxFileCount }})
               </span>
            </template>
            <template #progress>
               <v-progress-linear
                  v-if="uploading != null"
                  class="v-progress-linear--absolute"
                  style="height: 24px"
                  color="primary lighten-2"
                  :value="uploading && uploading.allFilesProgress * 100"
                  :indeterminate="uploading && uploading.isFinalizing"
               >
                  <strong>{{ attachmentFieldHint }}</strong>
               </v-progress-linear>
            </template>
            <template #append-outer>
               <v-tooltip left>
                  <template #activator="{ on }">
                     <div v-on="on">
                        <v-file-input
                           v-if="!readonly"
                           ref="fileInput"
                           color="error"
                           label="File input"
                           hide-input
                           multiple
                           :class="['pa-0', 'mt-0']"
                           :value="fileInputValue"
                           prepend-icon="mdi-file-upload"
                           :accept="attachmentAcceptTypes"
                           :disabled="isAtMaxFileCount || readonly"
                           @change="uploadAttachments($event)"
                        ></v-file-input>
                     </div>
                  </template>
                  <span>{{ translateKey("attachments.uploadAttachmentLabel", translations) }}</span>
               </v-tooltip>
            </template>
            <template #selection="{ attrs, item }">
               <v-chip
                  v-bind="attrs"
                  small
                  :disabled="isAttachmentLoading"
                  @click="$emit('download-attachment', item.blobName)"
                  @mouseup.stop.prevent
               >
                  {{ item.fileName }}
                  <span class="grey--text ml-1 mr-5">{{ getAttachmentSize(item) }}</span>
                  <v-btn
                     v-if="removeBtnVisible"
                     class="delete-file-btn"
                     icon
                     :disabled="readonly"
                     @click.stop="$emit('delete-attachment', item.blobName)"
                  >
                     <v-icon class="v-chip__close">mdi-close-circle</v-icon>
                  </v-btn>
               </v-chip>
            </template>
         </v-combobox>
      </template>
      <template v-else>
         <div class="drop-area" :style="{ height: cbFieldDropAreaHeight }">
            <span v-if="dragError" class="grey--text">
               <v-icon class="red--text text--lighten-2">mdi-cancel</v-icon>
               {{ dragError }}
            </span>
            <span v-else class="grey--text">{{ translateKey("attachments.dropToUploadLabel", translations) }}</span>
         </div>
      </template>
   </div>
</template>

<script lang="ts">
import { AttachmentMetadata, TranslationPublicModel } from "@backend/api/pmToolApi";
import AttachmentUtils, { AttachmentUploadInfo } from "@utils/AttachmentUtils";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import { ValidationRule } from "@models/shared/ValidationRules";

@Component({
   components: {},
})
export default class AttachmentField extends ComponentBase {
   @VModel()
   files: AttachmentMetadata[];

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   @Prop({ required: true })
   label: string;

   @Prop({ required: true })
   uploading: AttachmentUploadInfo | null;

   @Prop({ default: false })
   downloading: boolean;

   @Prop({ default: false })
   deleting: boolean;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ default: true })
   removeBtnVisible: boolean;

   @Prop({ default: () => [] })
   rules: ValidationRule<any>[];

   @Prop({ default: undefined })
   maxFileCount: number | undefined;

   @Prop({ default: undefined })
   attachmentAcceptTypes: string[] | undefined;

   getAttachmentSize(file: AttachmentMetadata | undefined): string | undefined {
      if (!file) return undefined;

      return AttachmentUtils.sizeHumanReadable(file.size);
   }

   // ----------- Validation ------------------
   get isAtMaxFileCount(): boolean {
      return this.maxFileCount === undefined ? false : this.files.length >= this.maxFileCount;
   }

   // ----------- File drag n drop ------------------
   isDragging: boolean = false;
   dragError: string | null = null;
   cbFieldDropAreaHeightValue: number = 52;

   get cbFieldDropAreaHeight(): string {
      return this.cbFieldDropAreaHeightValue.toString() + "px";
   }

   get isAttachmentLoading(): boolean {
      return this.downloading || !!this.uploading || this.deleting;
   }

   get attachmentFieldHint(): string | null {
      if (this.uploading === null) {
         return null;
      }

      let hint = `${this.translateKey("attachments.uploadingHint", this.translations)} `;
      if (this.uploading.filesCount > 1) {
         hint += `(${this.uploading.doneFilesCount}/${this.uploading.filesCount}) `;
      }

      hint += `${this.translateKey("attachments.fileHint", this.translations)} ${(
         this.uploading.currentFileProgress * 100
      ).toFixed(1)}% `;

      if (this.uploading.isFinalizing) {
         hint += this.translateKey("attachments.savingToCloudHint", this.translations);
      } else {
         hint += `@${AttachmentUtils.speedHumanReadable(this.uploading.wmaSpeed)}`;
      }

      return hint;
   }

   changeFieldDropAreaHeight() {
      // Set drop-area height according selected cbFileFiled height
      const fileTypeFiled = this.$refs?.fileTypeFiled as Element | undefined;
      if (!this.isDragging && fileTypeFiled !== undefined) {
         const currentHeight = fileTypeFiled.clientHeight;

         if (currentHeight !== this.cbFieldDropAreaHeightValue) {
            this.cbFieldDropAreaHeightValue = currentHeight;
         }
      }
   }

   onDragOver(event: any) {
      if (this.readonly) {
         event.dataTransfer.dropEffect = "none";
         return;
      }

      this.changeFieldDropAreaHeight();

      event.dataTransfer.dropEffect = "copy";

      if (!this.isDragging) {
         this.isDragging = true;
      }
   }

   onDragLeave() {
      this.isDragging = false;
   }

   onFileDrop(event: any) {
      this.isDragging = false;
      if (event.dataTransfer.files?.length) {
         this.uploadAttachments([...event.dataTransfer.files]);
      }
   }

   fileInputValue: File[] = [];
   uploadAttachments(files: File[]) {
      if (files.length) {
         // do not emit upload on file delete
         this.$emit("upload-attachments", files);
         // clear v-file-input's value - otherwise the current file(s) could not be used again
         this.fileInputValue = [];
      }
   }
}
</script>
<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_colors";
.v-btn.delete-file-btn {
   position: absolute;
   width: 30px;
   height: 24px;
   right: 0px;
   background-color: map-get($grey, "lighten-2");
}
</style>
